import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiHost = process.env.REACT_APP_API_HOST;
const apiVersion = 'v1'; //TODO: Do a lookup for version per resource

const apiBaseUrl = `${apiHost}/api/${apiVersion}`;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    if (!options.noAuth) {
        const token = localStorage.getItem('token');
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, options);
};

const getApiUrl = (workspace, view, operation, params) => {
    // TODO: Improve this temp solution
    let apiUrl = view || workspace;
    switch (workspace) {
        case 'company':
            if (view === 'planning') {
            apiUrl = 'projectuserplanning';
            break;
            }
            if (view === 'projects') {
                if (operation !== 'getOne' && operation !== 'update')
                    apiUrl = 'companyprojects';
                break;
            }
            if (view === 'documents') {
                apiUrl = 'companydocuments';
                break;
            }
        break;
        case 'project':
            if (view === 'documents') {
                apiUrl = 'projectdocuments';
                break;
            }
            if (view === 'checklist') {
                apiUrl = 'projects';
                break;
            }
            if (view === 'timeplan') {
                apiUrl = 'projects';
                break;
            }
            if (view === 'events') {
                apiUrl = 'projectincidentreports';
                break;
            }
            if (view === 'personnelledger') {
                apiUrl = 'projects';
                break;
            }
            break;
        case 'companies':
            if (view === 'users' && params && params.companyId) {
                apiUrl = `companies/${params.companyId}/users`
            }
            break;
        case 'general':
            break;
        default:
            break;
    }
    return apiUrl;
};

const WeAvraAdminDataProvider = {

    //TODO: Convert to async functions?
    getList: (resource, params) => {
        let projectId = null;
        let filterByCompany = null;
        let activeOnly = null;
        let simple = null;
        let companyId = null;
        let completedOnly = null;
        let formsOnly = null;

        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'getList', params);

        if (workspace === 'start') {
            return () => ({ data: [], total: 0 });
        }

        if (view === 'companies' && params.filter && params.filter.projectId) {
            projectId = params.filter.projectId;
            delete params.filter.projectId;
        }

        if (view === 'projects' || workspace === 'project') {
            // TODO: Refactor...
            if (params.filter && params.filter.projectId && apiUrl !== 'projectincidentreports') {
                projectId = params.filter.projectId;
                delete params.filter.projectId;
            }
            if (params.filter.filterByCompany) {
                filterByCompany = params.filter.filterByCompany;
                companyId = params.filter.companyId;
                delete params.filter.filterByCompany;
                delete params.filter.companyId;
            }

            if (params.filter.activeOnly) {
                activeOnly = params.filter.activeOnly;
                delete params.filter.activeOnly;
            }

            if (params.filter.completedOnly) {
                completedOnly = params.filter.completedOnly;
                delete params.filter.completedOnly;
            }

            if (params.filter.formsOnly) {
                formsOnly = params.filter.formsOnly;
                delete params.filter.formsOnly;
            }
        }

        if (view === 'taskforms') {
            if (params.filter.simple) {
                simple = params.filter.simple;
                delete params.filter.simple;
            }
        }

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter)
        };

        if (projectId)
            query.projectId = projectId;
        if (filterByCompany)
            query.filterByCompany = filterByCompany;
        if (companyId)
            query.companyId = companyId;
        if (activeOnly)
            query.activeOnly = activeOnly;
        if (simple)
            query.simple = simple;
        if (completedOnly)
            query.completedOnly = completedOnly;
        if (formsOnly)
            query.formsOnly = formsOnly;

        let url = `${apiBaseUrl}/${apiUrl}/admin?${stringify(query)}`;

        if (params.companyId)
            url = `${apiBaseUrl}/${apiUrl}?${stringify(query)}`;
        
        if (params.getSearchResults && apiUrl === 'projects')
            url = `${apiBaseUrl}/${apiUrl}/admin/search?${stringify(query)}`;
        
        if (view === 'personnelledger') {
            url = `${apiBaseUrl}/${apiUrl}/admin/${projectId}/ledger?${stringify(query)}`;
        }

        if (params.getInvoiceData && params.year && params.month)
            url = `${apiBaseUrl}/${apiUrl}/admin/invoiceData/${params.year}/${params.month}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10)
        }));
    },

    getOne: (resource, params) => {
        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'getOne');

        let url = '';

        // Project special (member companies | ledgerInfo)
        if (apiUrl === 'projects' && (params.getMembers || params.getLedgerInfo || params.getPermissions)) {
            if (params.getMembers)
                url = `${apiBaseUrl}/${apiUrl}/admin/${params.id}/membersData`;
            else if (params.getLedgerInfo)
                url = `${apiBaseUrl}/${apiUrl}/admin/${params.id}/ledgerInfo`;
            else if (params.getPermissions) {
                url = `${apiBaseUrl}/${apiUrl}/admin/${params.id}/permissions`;
                if (params.getMembersPermissions)
                    url += '?getMembersPermissions=true';
            }
        }
        else 
            url = params.id 
                ? `${apiBaseUrl}/${apiUrl}/admin/${params.id}`
                : `${apiBaseUrl}/${apiUrl}/admin`;

        return httpClient(url).then(({ json }) => ({
            data: json
        }));
    },

    getMany: (resource, params) => {
        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'getMany');

        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }

        let url = '';

        if (apiUrl === 'projects' || apiUrl === 'companies') {
             // TODO: Ugly hack, improve...
            let urlAddOn = undefined;

            if (params.getMembers)
                urlAddOn = 'members';
            else if (params.getCheckedInUsers)
                urlAddOn = 'checkIns';
            else if (params.getContacts)
                urlAddOn = 'contacts';

            if (urlAddOn)
                url = `${apiBaseUrl}/${apiUrl}/admin/${urlAddOn}/${params.ids[0]}`;
        }

        if (url === '')
            url = `${apiBaseUrl}/${apiUrl}/admin?${stringify(query)}`;

        return httpClient(url).then(({ json }) => ({ data: json }))
    },

    getManyReference: (resource, params) => {
        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'getManyReference');
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id
            })
        }
        const url = `${apiBaseUrl}/${apiUrl}/admin?${stringify(query)}`

        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10)
        }))
    },

    update: (resource, params) => {
        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'update');

        if (params.data.files && apiUrl === 'projects') {
            const formData = new FormData()
            params.data.files.forEach(file => {
                formData.append('formFiles', file.rawFile)
            })

            return httpClient(`${apiBaseUrl}/${apiUrl}/admin/${params.id}/files`,
                {
                    method: 'POST',
                    body: formData
                }).then(({ json }) => ({
                    data: { ...params.data, id: json },
                }));

        } 

        let fullApiUrl = `${apiBaseUrl}/${apiUrl}/admin/${params.id}`;
        if (params.data.updateDocumentFolder) {
            fullApiUrl = `${apiBaseUrl}/${apiUrl}/admin/folder/${params.id}`;
            delete params.data.updateDocumentFolder;
        }
        if (params.data.updateInvoice) {
            fullApiUrl = `${apiBaseUrl}/${apiUrl}/admin/invoice/${params.id}`;
            delete params.data.updateInvoice;
        }

        if (params.data.updateProjectMembers) {
            fullApiUrl = `${apiBaseUrl}/${apiUrl}/admin/${params.id}/members`;
            delete params.data.updateProjectMembers;
        }

        if (params.data.setTimeplanActive) {
            fullApiUrl = `${apiBaseUrl}/${apiUrl}/admin/${params.id}/timeplanActive`;
            delete params.data.setTimeplanActive;
        }

        if (params.data.fileData) {
            //TODO: Handle both single and multiple files
            const formData = new FormData();
            formData.append('formFile', params.data.fileData.rawFile);
            delete params.data.fileData;

            for (let key in params.data) {
                formData.append(key, params.data[key]);
            }

            return httpClient(fullApiUrl, {
                method: 'PUT',
                body: formData
            }).then(({ json }) => ({
                data: { ...params.data, id: json }
            }))
        }

        return httpClient(fullApiUrl,
            {
                method: 'PUT',
                body: JSON.stringify(params.data)
            }).then(({ json }) => ({
                data: { ...params.data, id: json },
            }));
        
    },

    updateMany: (resource, params) => {
        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'updateMany');

        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }
        return httpClient(`${apiBaseUrl}/${apiUrl}/admin?${stringify(query)}`,
            {
                method: 'PUT',
                body: JSON.stringify(params.data)
            }).then(({ json }) => ({ data: json }))
    },

    create: (resource, params) => {
        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'create');
        
        let projectId = '';
        let fullRequestUrl = `${apiBaseUrl}/${apiUrl}/admin`;

        if (apiUrl === 'projectincidentreports' && params.data.projectId) {
            projectId = params.data.projectId;
        }

        if (params.data.fileData || params.data.useForm) {
            //TODO: Handle both single and multiple files
            const formData = new FormData();

            if (params.data.fileData) {
                formData.append('formFile', params.data.fileData.rawFile);
                delete params.data.fileData;
            }

            if (params.data.useForm)
                delete params.data.useForm;

            for (let key in params.data) {
                formData.append(key, params.data[key]);

                // TODO: Handle arrays...
            }

            if (projectId)
                fullRequestUrl += `/${projectId}`;

            return httpClient(fullRequestUrl, {
                method: 'POST',
                body: formData
            })
            .then(({ json }) => ({
                data: { ...params.data, id: json },
            }))
            .catch(e => {
                console.error(e);
            });
        } 

        if (params.data.createDocumentFolder) {
            fullRequestUrl = `${apiBaseUrl}/${apiUrl}/admin/folder`;
            delete params.data.createDocumentFolder;
        }

        if (params.data.createInvoice) {
            fullRequestUrl = `${apiBaseUrl}/${apiUrl}/admin/invoice`;
            delete params.data.createInvoice;
        }

        return httpClient(fullRequestUrl,
            {
                method: 'POST',
                body: JSON.stringify(params.data)
            })
            .then(({ json }) => ({
                data: { ...params.data, id: json },
            }))
            .catch(e => {
                console.error('Error in DataProvider', e);
                return Promise.reject(e);
            });
    },

    delete: (resource, params) => {
        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'delete');

        let fullRequestUrl = `${apiBaseUrl}/${apiUrl}/admin/${params.id}`;
        if (params.data && params.data.deleteDocumentFolder) {
            fullRequestUrl = `${apiBaseUrl}/${apiUrl}/admin/folder/${params.id}`;
        }
        return httpClient(fullRequestUrl, 
            {
                method: 'DELETE'
            }).then(({ json }) => ({ data: { id: json } }))
    },

    deleteMany: (resource, params) => {
        const [workspace, view] = resource.split('/');
        const apiUrl = getApiUrl(workspace, view, 'deleteMany');
        
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }
        return httpClient(`${apiBaseUrl}/${apiUrl}/admin?${stringify(query)}`,
            {
                method: 'DELETE',
                body: JSON.stringify(params.data)
            }).then(({ json }) => ({ data: json }))
    },

    requestPasswordReset: (userEmail) => {
        return httpClient(`${apiBaseUrl}/passwordreset/requestPasswordReset`,
            {
                method: 'POST',
                body: JSON.stringify({userEmail: userEmail}),
                noAuth: true,
            })
            .then(({ json }) => ({
                data: { json },
            }))
            .catch(e => {
                console.error('Error in requestPasswordReset', e);
                return Promise.reject(e);
            });
    },

    resetPassword: (params) => {
        console.log('Reset Password', params);
        return httpClient(`${apiBaseUrl}/passwordreset/resetPassword`,
            {
                method: 'POST',
                body: JSON.stringify(params.data),
                noAuth: true,
            })
            .then(({ json }) => ({
                data: { json },
            }))
            .catch(e => {
                console.error('Error in resetPassword', e);
                return Promise.reject(e);
            });
    },

};

export default WeAvraAdminDataProvider;