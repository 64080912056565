import * as React from 'react';
import { useSelector } from "react-redux";
import { Title, useShowController, SimpleShowLayout, TextField } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';

const UserDetails = props => {
    const controllerProps = useShowController({ id: props.userId, ...props });

    return (
        <SimpleShowLayout {...controllerProps}>
            <TextField source="firstName" label="Förnamn" />
            <TextField source="lastName" label="Efternamn" />
            <TextField source="email" />
            <TextField source="personalIdentityNumber" label="Personnummer" />
            <TextField source="foreignPersonalIdentityNumber" label="Utländskt personnummer" />
        </SimpleShowLayout>
    )
}

const UserDashboard = (props) => {
    const userId = useSelector(state => state.user.id);

    return (
        <Card variant="outlined">
            <Title title="Min sida" />
            <CardContent>
                {userId &&
                    <UserDetails userId={userId} resource="general/projectusers" basePath="user" />
                }
            </CardContent>
        </Card>
    )
}


export default UserDashboard;