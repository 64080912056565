const defaultUserState = {
    name: localStorage.getItem('user.name') || '',
    companyId: localStorage.getItem('user.company.id') || '',
    companyName: localStorage.getItem('user.company.name') || '',
    id: localStorage.getItem('user.id') || '',
};

const userReducer = (state = defaultUserState, action) => {
    if (action.type === 'SET_USER') {
        return action.payload;
    }
    return state;
};

export default userReducer;
