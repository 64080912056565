import React from "react";
import {
    SaveContextProvider,
    SimpleForm,
    TextInput,
    BooleanInput,
} from 'react-admin';

import { Typography } from '@material-ui/core';

import EditorToolBar from "./EditorToolBar";

const SubTaskOptionEditor = props => {
    const { record, saveContext, onDelete } = props;

    return (
        <>
            <Typography>Redigera val - {record.name}</Typography>
            <SaveContextProvider value={saveContext}>
                <SimpleForm 
                    name="subtask-option-edit"
                    resource="general/projectsubtaskoptions"
                    record={record}
                    save={saveContext.save}
                    saving={saveContext.saving}
                    toolbar={<EditorToolBar onDeleteClick={onDelete} />}
                    redirect={false}>
                    <TextInput source="name" label="Namn" />
                    <BooleanInput source="isConfirmation" label="Bekräftar" />
                </SimpleForm>
            </SaveContextProvider>
        </>
    );
}

export default SubTaskOptionEditor;