import React from 'react';
import {
    Button,
} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';

const SimpleDeleteButton = props => {
    //TODO: Styla annorlunda?
    return (
        <Button
        label="ra.action.delete" 
        variant="outlined" 
        color="primary" 
        onClick={props.onClick}
        > 
            <DeleteIcon fontSize="large" />
        </Button>
    );
}

export default SimpleDeleteButton;