import * as React from 'react';
import { useDispatch } from "react-redux";
import { 
    List, 
    Datagrid,
    TextField,
    SelectField,
    Filter,
    TextInput,
    Button,
    Link,
} from 'react-admin';

import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const ProjectFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn />
    </Filter>
)

const CreateProjectButton = () => (
    <Link to={"/company/projects/create"}>
        <Button variant="outlined" size="large" color="primary" label="weavra.actions.newProject"><AddIcon /></Button>
    </Link>
);

const CompanyProjectList = props => {
    //TODO: filter : active only?
    
    const dispatch = useDispatch();
    const handleRowClick = (id, resource, record) => {
        dispatch({ type: "SET_WORKSPACE", payload: { type: "project", id: id, name: record.project.name } });
    }

    return (
        <Grid container spacing={2}>
            <Grid item container xs={12} justify="flex-end">
                <CreateProjectButton />
            </Grid>
            <Grid item xs={12}>
                <List 
                    {...props} 
                    title="weavra.companyProjects.title" 
                    bulkActionButtons={false} 
                    exporter={false}
                    actions={false}
                    sort={{ field: 'project.orderNumber', order: 'DESC' }}
                    filters={<ProjectFilter />}
                    >
                    <Datagrid rowClick={handleRowClick}>
                        <TextField source="project.orderNumber" label="weavra.companyProjects.customersId" />
                        <TextField source="internalId" label="weavra.companyProjects.internalId" />
                        <TextField source="project.name" label="weavra.companyProjects.projectName" />
                        <TextField source="project.customerName" label="weavra.companyProjects.customer" sortBy="project.customer.name" />
                        <TextField source="projectLeadName" label="weavra.companyProjects.projectLead" sortBy="projectLead.firstname" />
                        <SelectField source="project.status" label="weavra.companyProjects.status" choices={[
                            { id: 0, name: 'weavra.projectStatus.notStarted' },
                            { id: 1, name: 'weavra.projectStatus.started' },
                            { id: 2, name: 'weavra.projectStatus.paused' },
                            { id: 3, name: 'weavra.projectStatus.finished' },
                        ]} />
                    </Datagrid>
                </List>
            </Grid>
        </Grid>
    );
}

export default CompanyProjectList;
