import * as React from 'react'
import { CssBaseline, Container } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import LoginForm from './LoginForm'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
}));

const LoginPage = (props) => {
    const classes = useStyles()

    return (
        <ThemeProvider theme={createMuiTheme(props.theme)}>
            <Container className={classes.root}>
                <LoginForm {...props} />
            </Container>
            <CssBaseline/>
        </ThemeProvider>
    )
}

export default LoginPage
