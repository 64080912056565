import React, { useState, Fragment } from 'react';
import { 
    Title,
    Loading,
    usePermissions,
    useGetList,
    useCreate,
    useUpdate,
    Button,
    useRefresh,
    useNotify,
} from 'react-admin';

import { Card, CardContent, Grid, makeStyles, Dialog, DialogTitle, DialogContent, Tabs, Tab, } from '@material-ui/core';
import SuperAdminCompanyList from './SuperAdminCompanyList';
import SuperAdminForms from './SuperAdminForms';
import SuperAdminUserAgreement from './SuperAdminUserAgreement';
import SuperAdminActiveProjects from './SuperAdminActiveProjects';
import SuperAdminInvoices from './SuperAdminInvoices';
import CompanyQuickCreate from './Companies/CompanyQuickCreate';
import CompanyQuickEdit from './Companies/CompanyQuickEdit';

// TODO: Kanske sätta workspace i redux state ifall den inte är admin redan? Hmm...
// TODO: Redigera företag
// TODO: Nånting för att hantera användare (skapa nya superadmins? toggla aktiv/låst? sätta roller? ändra lösen?)

const useStyles = makeStyles(() => ({
    root: {
    },
    tab: {
    }
}));

const AddCompanyButton = props => {
    // TODO: Move to SuperAdminCompanies.js
    return (
        <Button
            label="+ Nytt företag"
            variant="text"
            size="small"
            onClick={props.onClick}>
        </Button>
    );
}

const TabPanel = props => {
    const { children, value, index, ...rest } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...rest}
      >
        {value === index && (
          <div>
            {children}
          </div>
        )}
      </div>
    );
}

const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const SuperAdminContent = () => {
    const classes = useStyles();

    const [shownDialog, setShownDialog] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [ selectedCompany, setSelectedCompany ] = useState(null);

    var refresh = useRefresh();
    var notify = useNotify();

    // TODO: Move to SuperAdminCompanies.js?
    const [createCompany] = useCreate("superadmin/companies");
    const [editCompany] = useUpdate("superadmin/companies");

    // TODO: Move to SuperAdminCompanies.js?
    const { data, ids, loading } = useGetList(
        'superadmin/companies',
        { page: 1, perPage: 999 },
        { field: 'name', order: 'ASC' }
    );

    // TODO: Move to SuperAdminCompanies.js?
    const handleAddCompany = () => {
        setShownDialog('companyCreate');
    }

    const handleEditCompany = (company) => {
        setSelectedCompany(company);
        setShownDialog('companyEdit');
    }

    // TODO: Move to SuperAdminCompanies.js?
    const handleCreateCompanySubmit = async values => {
        setIsLoading(true);
        createCompany(
            { payload: { data: { ...values } } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }

    const handleEditCompanySubmit = async values => {
        setIsLoading(true);
        editCompany(
            { payload: { id: values.id, data: { ...values } } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }

    // TODO: Move to SuperAdminCompanies.js?
    const handleClose = () => {
        setShownDialog(null);
    }

    const handleTabChange = (event, tabIndex) => {
        setSelectedTab(tabIndex);
    }

    if (loading || isLoading)
        return <Loading />

    return (
        <Fragment>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="superadmin tabs"
                        textColor='primary'
                        indicatorColor='primary'
                    >
                        <Tab label="Företag" {...a11yProps(0)} className={classes.tab} />
                        <Tab label="Användare" {...a11yProps(1)} className={classes.tab}  />
                        <Tab label="Formulär" {...a11yProps(2)} className={classes.tab} />
                        <Tab label="Användaravtal" {...a11yProps(3)} className={classes.tab} />
                        <Tab label="Aktiva projekt" {...a11yProps(4)} className={classes.tab} />
                        <Tab label="Fakturering" {...a11yProps(5)} className={classes.tab} />
                    </Tabs>

                    <TabPanel value={selectedTab} index={0}>
                        <Card>
                            <Title title="Superadmin" />
                            <CardContent>
                                <AddCompanyButton onClick={handleAddCompany} />
                                <SuperAdminCompanyList companies={data} companyIds={ids} onEditCompanyClicked={handleEditCompany} />
                            </CardContent>
                        </Card>
                    </TabPanel>

                    <TabPanel value={selectedTab} index={1}>
                        <Card>
                            <CardContent>
                                <p>TODO: Användaradmin</p>
                            </CardContent>
                        </Card>
                    </TabPanel>

                    <TabPanel value={selectedTab} index={2}>
                        <SuperAdminForms />
                    </TabPanel>

                    <TabPanel value={selectedTab} index={3}>
                        <Card>
                            <CardContent>
                                <SuperAdminUserAgreement />
                            </CardContent>
                        </Card>
                    </TabPanel>

                    <TabPanel value={selectedTab} index={4}>
                        <Card>
                            <CardContent>
                                <SuperAdminActiveProjects />
                            </CardContent>
                        </Card>
                    </TabPanel>

                    <TabPanel value={selectedTab} index={5}>
                        <Card>
                            <CardContent>
                                <SuperAdminInvoices />
                            </CardContent>
                        </Card>
                    </TabPanel>
                </Grid>
            </Grid>

            <Dialog open={shownDialog === 'companyCreate'} onClose={handleClose} fullWidth>
                <DialogTitle>Nytt företag</DialogTitle>
                <DialogContent>
                    <CompanyQuickCreate onSave={handleCreateCompanySubmit} onCancel={handleClose} isLoading={isLoading} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'companyEdit'} onClose={handleClose} fullWidth>
                <DialogTitle>Redigera företag</DialogTitle>
                <DialogContent>
                    <CompanyQuickEdit onSave={handleEditCompanySubmit} onCancel={handleClose} isLoading={isLoading} company={selectedCompany} />
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

const UnauthorizedContent = () => {
    return (
        <Card>
            <Title title="Superadmin (Obehörig)" />
            <CardContent>Du har inte behörighet att visa denna sida.</CardContent>
        </Card>
    );
}

const SuperAdminDashboard = () => {
    const { loaded, permissions } = usePermissions();

    if (!loaded)
        return <Loading />

    return permissions && permissions.includes('SuperAdmin') 
        ? <SuperAdminContent /> 
        : <UnauthorizedContent />
    
};

export default SuperAdminDashboard;