import React, { useState } from 'react';
import { useNotify, useDataProvider } from 'react-admin';
import { Card, CardContent, Typography, Button, ButtonGroup, FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockedIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles(theme => ({
    member: {
        position: 'relative',
        flex: '1 0 auto',
    },
    memberEditable: {
        position: 'relative',
        flex: '1 0 auto',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    addMember: {
        flex: '1 0 auto'
    },
    deleteButton: {
        position: 'absolute',
        bottom: 10,
        right: 10,
        minWidth: 32
    },
    error: {
        margin: '1rem 0',
        color: theme.palette.error.main
    },
}));

const MemberEditor = props => {
    const { loading, editMode, users, company, onSave, onCancel, canEdit, projectPermissions } = props;

    const classes = useStyles();
    
    const [projectLead, setProjectLead] = useState(company && company.projectLead ? company.projectLead.id : '');
    const [projectRole, setProjectRole] = useState(company?.projectRole);

    const handleLeadChange = e => {
        setProjectLead(e.target.value);
    }

    const handleSave = () => {
        onSave(projectLead, projectRole);
    }

    const handleCancel = () => {
        setProjectLead(company && company.projectLead ? company.projectLead.id : '');
        onCancel();
    }

    const handleRoleChange = e => {
        setProjectRole(e.target.value);
    }

    const canSave = () => {
        let leadDirty = false;
        let roleDirty = false;

        if (company && company.projectLead)
            leadDirty = projectLead && projectLead !== company.projectLead.id;
        
        if (company && company.projectRole !== null && projectRole !== undefined)
            roleDirty = projectRole !== company.projectRole;
        
        return leadDirty || roleDirty;
    }

    if (loading)
        return <div>Hämtar användare...</div>
    
    if (editMode)
        return (
            <div>
                {users.length > 0 &&
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-lead-label" variant="filled">Välj projektansvarig</InputLabel>
                            <Select id="select-lead"  labelId="select-lead-label" value={projectLead} variant="filled" onChange={handleLeadChange}>
                                {users.map(user => <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>)}
                            </Select>
                    </FormControl>
                }
                {users.length === 0 && 
                    <div className={classes.error}>Admin saknas för detta företag</div>
                }
                {canEdit &&
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="select-role-label" variant="filled">Välj projektroll</InputLabel>
                        <Select id="select-role"  labelId="select-role-label" value={projectRole} variant="filled" onChange={handleRoleChange}>
                            {projectPermissions.canAddAdminMembers && 
                                <MenuItem key='admin' value={0}>Administratör</MenuItem>
                            }
                            {(projectPermissions.canAddAdminMembers || projectPermissions.canAddParticipantMembers) && 
                                <MenuItem key='participant' value={1}>Entreprenör</MenuItem>
                            }
                            {(projectPermissions.canAddAdminMembers || projectPermissions.canAddParticipantMembers) && 
                                <MenuItem key='guest' value={2}>Gäst (readonly)</MenuItem>
                            }
                        </Select>
                    </FormControl>
                }

                <ButtonGroup>
                    <Button variant="text" size="small" disabled={!canSave()} onClick={() => handleSave()}>Spara</Button>
                    <Button variant="text" size="small" onClick={() => handleCancel()}>Avbryt</Button>
                </ButtonGroup>
            </div>
        )
    
    return (
        <div>
            {canEdit 
                ? <EditIcon fontSize='inherit' />
                : <LockedIcon fontSize='inherit' />
            }
        </div>
    );
}


const MemberCompanyCard = props => {
    const { company, projectData, onDeleteClicked, onMemberUpdate, projectPermissions } = props;

    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();   

    const [editMode, setEditMode] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    const canDelete = projectPermissions.deletableMembers.includes(company.id);
    const canEdit = projectPermissions.editableMembers.includes(company.id);

    const handleCardClick = () => {
        if (loading || !canEdit || editMode) return;

        if (!editMode) {
            if (users.length === 0) {
                setLoading(true);
                dataProvider.getList('companies/users', { companyId: company.id, pagination: { page: 1, perPage: 999 }, sort: { field: 'firstName', order: 'ASC' }, filter: { role:0, active:true } }) // TODO: Hämta enklare lista (behöver bara namn och id?)
                .then(({ data }) => {
                    setUsers(data);
                    setLoading(false);
                    setEditMode(true);
                }).catch(error => {
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    setLoading(false);
                    setEditMode(true);
                });
            } else {
                setEditMode(true);
            }
        }
    }

    const handleSaveChanges = (projectLead, projectRole) => {
        setEditMode(false);
        onMemberUpdate(company.id, projectLead, projectRole);
    }

    const handleCancelChanges = () => {
        setEditMode(false);
    }

    const projectRoleName = (role) => {
        switch (role) {
            case 0:
                return 'Administratör';
            case 1:
                return 'Entreprenör';
            case 2:
                return 'Gäst';
            default:
                return '???';
        }
    }

    return (
        <Card raised className={canEdit ? classes.memberEditable : classes.member} onClick={handleCardClick}>
            <CardContent>
                <Typography>{company.name}{company.id === projectData.ownerId ? ' - projektägare' : ''}</Typography>
                <Typography variant='body2'>{projectRoleName(company.projectRole)}</Typography>
                {company.projectLead &&
                    <Typography variant='subtitle2'>Ansvarig: {company.projectLead.firstName} {company.projectLead.lastName}</Typography>
                }
                {company.id !== projectData.ownerId && canDelete && editMode &&
                    <Button className={classes.deleteButton} title='Ta bort projektmedlem' data-company={company.id} onClick={() => onDeleteClicked(company.id)}>
                        <DeleteIcon fontSize="small" />
                    </Button>
                }
                <MemberEditor loading={loading} editMode={editMode} users={users} company={company} onSave={handleSaveChanges} onCancel={handleCancelChanges} canEdit={canEdit} projectPermissions={projectPermissions} />
            </CardContent>
        </Card>
    )
}

export default MemberCompanyCard;