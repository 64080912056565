import React, { useCallback, useEffect } from 'react';

import { Dialog, DialogContent, Button } from '@material-ui/core';
import { Page, Text, View, Document, StyleSheet, Font, PDFViewer, BlobProvider } from '@react-pdf/renderer';

import MikroMedium from '../../assets/fonts/Mikro-Medium.otf';
import MikroBold from '../../assets/fonts/Mikro-Bold.otf';
import MikroLight from '../../assets/fonts/Mikro-Light.otf';

// TODO: Separera kod för dokumentet och dialogrutan
Font.register({
    family: 'Mikro',
    format: 'truetype',
    fonts: [
        { src: MikroMedium, fontWeight: 400 },
        { src: MikroLight, fontWeight: 300 },
        { src: MikroBold, fontWeight: 700 },
    ],
})

const styles = StyleSheet.create({
    // TODO: Clean up
    page: {
        paddingTop: 24,
        paddingBottom: 60,
        paddingLeft: 36,
        paddingRight: 24,
    },

    pageHeader: {
        marginLeft: 18,
        marginRight: 24,
        marginBottom: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    pageHeaderText: {
        fontFamily: 'Mikro',
        fontSize: 9,
        fontWeight: 'light',
        marginBottom: 12,
    },

    header: {
        marginLeft: 18,
        marginRight: 24,
        marginBottom: 18,
    },
    headerLabel: {
        fontFamily: 'Mikro',
        fontSize: 9,
        fontWeight: 'light',
        marginBottom: 6,
    },
    headerText: {
        fontFamily: 'Mikro',
        fontSize: 10,
        fontWeight: 'light',
        marginBottom: 6,
    },
    headerTitle: {
        fontFamily: 'Mikro',
        fontSize: 11,
        fontWeight: 'bold',
        marginBottom: 6,
    },

    body: {
        marginLeft: 18,
        marginRight: 18,
    },
    bodyText: {
        fontFamily: 'Mikro',
        fontSize: 9,
        fontWeight: 'light',
        marginBottom: 6,
    },
    bodyTextSmall: {
        fontFamily: 'Mikro',
        fontSize: 8,
        fontWeight: 'light',
        marginBottom: 6,
    },
    taskTitle: {
        fontFamily: 'Mikro',
        fontSize: 10,
        fontWeight: 'bold',
        marginBottom: 9,
    },
    subTaskTitle: {
        fontFamily: 'Mikro',
        fontSize: 9,
        fontWeight: 'bold',
        marginBottom: 6,
    },

    footer: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    footerText: {
        fontFamily: 'Mikro',
        fontSize: 8,
        fontWeight: 'light'
    },
    footerPageCount: {
        fontFamily: 'Mikro',
        fontSize: 9,
        fontWeight: 'light',
        position: 'absolute',
        bottom: 12,
        right: 54,
    },

    table: {
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
    },
    tableColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        flex: 1,
    },
    tableColumnDouble: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        flex: 2,
    },
    alignRight: {
        textAlign: 'right',
    },
    halfColumn: {
        flex: 0.5,
    },
    doubleColumn: {
        flex: 2,
    },
    tripleColumn: {
        flex: 3,
    },
    

    recordRowContainer: {
        marginBottom: 9,
    },

    singleItemContainer: {
        width: '96%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 18,
    },
    singleItemTopLevel: {

    },
    singleItemTaskLevel: {
        marginLeft: 0,
        marginBottom: 18,
        
    },
    singleItemSubTaskLevel: {
        marginLeft: 6,
        borderBottom: '1px solid black',
        paddingTop: 6,
    },
});

const ReportHeader = props => {
    const { projectData, headerTitle, taskName, ownerName } = props;

    // TODO: Tryck ihop kolumnerna... alt. lägg till tredje tom kolumn...

    return (
        <View style={[styles.table, styles.header]}>
            <View style={styles.tableRow}>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerTitle}>
                        {headerTitle}
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerLabel}>
                        ProjektID
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerText}>
                        {taskName}
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerText}>
                        {projectData.orderNumber}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerLabel}>
                        Projektnamn
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerLabel}>
                        Utförare
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerText}>
                        {projectData.name}
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerText}>
                        {ownerName}
                    </Text>
                </View>
            </View>
        </View>
    );
}

const SummaryReportTableRow = props => {
    const { record } = props;

    const formattedDate = (date) => {
        return new Date(date).toLocaleDateString("sv-SE");
    }

    return (
        <View style={styles.recordRowContainer}>
            <View style={styles.tableRow}>
                <View style={styles.tableColumn}>
                    <Text style={styles.bodyTitle}>
                        {record.name}
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.bodyText}>
                        {formattedDate(record.completed)}
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.bodyText}>
                        {record.completedBy}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableColumnDouble}>
                    <Text style={styles.bodyText}>
                        {record.description}
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.bodyText}>
                        {record.ownerName}
                    </Text>
                </View>
            </View>
        </View>
    );
}

const SummaryReportTable = props => {
    const { records } = props;

    return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                <View style={styles.tableColumn}>
                    <Text style={styles.bodyLabel}>
                        Uppgift
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.bodyLabel}>
                        Datum
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.bodyLabel}>
                        Utförare
                    </Text>
                </View>
            </View>
            {records && records.map(record => (
                <SummaryReportTableRow key={record.id} record={record} />
            ))}
        </View>
    );
}

const SingleItemReportBody = props => {
    const { record } = props;

    const maxFreeTextLength = 54;

    const subTaskCompletedDate = (subTask) => (
        new Date(subTask.completed).toLocaleDateString('sv-SE')
    );

    const subTaskValueLine1 = (subTask) => {
        switch (subTask.subTaskVariant) {
            case 0: //OK
                return 'OK';
            case 1: //Freetext
                return subTask.value && subTask.value.length <= maxFreeTextLength ? `"${subTask.value}"` : '';
            case 2: //Select
            case 3: //Select + FreeText
                return subTask.options.find(opt => opt.selected).name; // TODO: Error handling?
            default:
                return '';
        }
    }

    const subTaskValueLine2 = (subTask) => {
        if (subTask.subTaskVariant === 3 && subTask.value && subTask.value.length <= maxFreeTextLength)
            return `"${subTask.value}"`;
        else
            return '';
    }

    return (
        <View style={styles.singleItemContainer}>
        {
            record.tasks.sort((a, b) => a.sortIndex - b.sortIndex).map(task => (
                <View key={task.id} style={styles.singleItemTaskLevel}>
                    <Text style={styles.taskTitle}>{task.name}</Text>
                    {
                        task.subTasks.sort((a, b) => a.sortIndex - b.sortIndex).map(subTask => (
                            <View key={subTask.id} style={styles.singleItemSubTaskLevel} wrap={false}>
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableColumn, styles.tripleColumn]}>
                                        <Text style={styles.subTaskTitle}>{subTask.name}</Text>
                                    </View>
                                    <View style={[styles.tableColumn, styles.doubleColumn]}>
                                        <Text style={styles.bodyText}>
                                            {subTaskValueLine1(subTask)}
                                        </Text>
                                    </View>
                                    <View style={[styles.tableColumn, styles.alignRight]}>
                                        <Text style={styles.bodyTextSmall}>
                                            {subTaskCompletedDate(subTask)}
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow}>
                                    <View style={[styles.tableColumn, styles.tripleColumn]}>
                                        <Text style={styles.bodyText}>{subTask.description}</Text>
                                    </View>
                                    <View style={[styles.tableColumn, styles.doubleColumn]}>
                                        <Text style={styles.bodyText}>
                                            {subTaskValueLine2(subTask)}
                                        </Text>
                                    </View>
                                    <View style={[styles.tableColumn, styles.alignRight]}>
                                        <Text style={styles.bodyTextSmall}>
                                            {subTask.completedBy}
                                        </Text>
                                    </View>
                                </View>
                                {(subTask.subTaskVariant === 1 || subTask.subTaskVariant === 3) && subTask.value && subTask.value.length > maxFreeTextLength && 
                                    <View style={styles.tableRow}>
                                        <View style={[styles.tableColumn, styles.tripleColumn]}></View>
                                    <View style={[styles.tableColumn, styles.doubleColumn]}>
                                        <Text style={styles.bodyText}>
                                            {`"${subTask.value}"`}
                                        </Text>
                                    </View>
                                    <View style={styles.tableColumn}></View>
                                </View>
                                }
                            </View>
                        ))
                    }
                </View>      
            ))
        }
        </View>
    )
}

const ReportBody = props => {
    const { records } = props;

    const isSummary = records.length > 1;

    return (
        <View>
        {
            isSummary
                ? <SummaryReportTable records={records} />
                : <SingleItemReportBody record={records[0]} />
        }
        </View>
    );
}

const ReportDocument = props => {
    const { records, projectData, projectOwnerName, taskName } = props;

    // TODO: title, keywords etc... metadata, vad önskas?
    const documentProps = {
        title: `Rapport - ${projectData.name}`,
        author: 'Weavra Admin',
        subject: 'Rapport',
        keywords: '',
        creator: 'Weavra',
        producer: 'Weavra',
    };

    const headerTitle = records.length > 1 ? 'Sammanställning' : 'Protokoll';

    const pageHeaderCompletionDate = new Date(records[0].completed).toLocaleDateString('sv-SE'); // TODO: How to handle multiple records/moments?
    const pageHeaderFormName = records[0].taskFormName; // TODO: Multiple records/moments?
    const ownerName = records[0].ownerName; // TODO: Multiple records/moments?

    return (
        <Document {...documentProps}>
            <Page size="A4" style={styles.page}>
                
                <View style={styles.pageHeader} fixed>
                    <Text style={styles.pageHeaderText}>
                        {pageHeaderFormName}
                    </Text>
                    <Text style={styles.pageHeaderText}>
                        {projectData.name}
                    </Text>
                    <Text style={styles.pageHeaderText}>
                        {pageHeaderCompletionDate}
                    </Text>
                </View>

                <ReportHeader
                    projectData={projectData}
                    projectOwnerName={projectOwnerName}
                    headerTitle={headerTitle}
                    taskName={taskName}
                    ownerName={ownerName}
                />

                <ReportBody records={records} />

                <View style={styles.footer} fixed>
                    <Text style={styles.footerText}>
                        Skapad med Weavra
                    </Text>
                    <Text style={styles.footerPageCount} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} | ${totalPages}`
                    )} />
                </View>

            </Page>
        </Document>
    );
}

const ReportPdfDialog = props => {
    const { dialogOpen, handleClose, records, projectData, projectOwnerName } = props;

    const fileName = `Rapport - ${projectData.name}.pdf`;
    const taskName =  records && records.length === 1 ? records[0].name : '';

    const loadFonts = useCallback(async () => {
        await Promise.all([
            Font.load({ fontFamily: 'Mikro', fontWeight: 300 }),
            Font.load({ fontFamily: 'Mikro', fontWeight: 400 }),
            Font.load({ fontFamily: 'Mikro', fontWeight: 700 }),
        ])
    }, []);

    useEffect(() => {
        loadFonts();
    }, [loadFonts]);

    return (
        <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
            <DialogContent>
                <div
                    style={{
                        minHeight: '630px',
                    }}>
                    <PDFViewer
                        showToolbar={false}
                        style={{
                            minHeight: '630px',
                            width: '100%',
                            marginBottom: '12px',
                        }}
                    >
                        <ReportDocument records={records} taskName={taskName} projectData={projectData} projectOwnerName={projectOwnerName} />
                    </PDFViewer>
                </div>
                <BlobProvider document={<ReportDocument records={records} taskName={taskName} projectData={projectData} projectOwnerName={projectOwnerName} />}>
                    {({ blob, url, loading, error }) => (
                        loading
                            ? <span>Laddar dokument...</span>
                            : <Button
                                variant='outlined'
                                href={url}
                                download={fileName}
                            >
                                Ladda ner
                            </Button>
                    )}
                </BlobProvider>
            </DialogContent>
        </Dialog>
    );
}

export default ReportPdfDialog;
