import React from 'react';

const UnauthorizedContent = props => {
    const { message } = props;
    const defaultMessage = 'Du saknar åtkomst till denna sida';
    return (
        <div>{message ?? defaultMessage}</div>
    )
}

export default UnauthorizedContent;