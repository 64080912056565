import React, { Fragment, useState } from 'react';
import { 
    List, 
    Button,
    SimpleForm,
    Toolbar,
    SaveButton,
    TextField,
    FileField,
    TextInput,
    SelectInput,
    useCreate,
    useGetList,
    useRefresh,
    useNotify,
    useTranslate,
    useUpdate,
    Loading,
    FileInput,
    DateInput,
    regex,
} from 'react-admin';

import { Grid, Dialog, DialogTitle, DialogContent, Typography, makeStyles } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import CancelButton from '../../shared/buttons/CancelButton';
import CompanyUserTree from './CompanyUserTree';
import HelpButton from '../../shared/buttons/HelpButton';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    topButton: {
        marginRight: theme.spacing(3),
    }
}));

const CreateUserButton = ({onClick}) => {
    const classes = useStyles();
    return (
        <Button 
        label="weavra.actions.newUser" 
        variant="contained" 
        color="primary" 
        size="large"
        onClick={onClick}
        className={classes.topButton}>
            <PersonAddIcon fontSize="large" />
        </Button>
    );
}

const CreateGroupButton = ({onClick}) => {
    const classes = useStyles();
    return (
        <Button 
        label="weavra.actions.newUserGroup" 
        variant="contained" 
        color="primary" 
        size="large"
        onClick={onClick}
        className={classes.topButton}>
            <GroupAddIcon fontSize="large"/>
        </Button>
    );
}

const QuickDialogActions = ({ onCancel, isLoading, ...props }) => {
    const classes = useStyles();
     return (
        <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
            <SaveButton submitOnEnter={true} disabled={isLoading} />
            <CancelButton onClick={onCancel} disabled={isLoading} />
        </Toolbar>
     )
}

const GroupQuickCreate = ({ initialValues, onSave, onCancel, isLoading }) => {
    return (
        <SimpleForm 
            name="group-quick-create"
            resource="company/companyusergroups"
            initialValues={initialValues}
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="name" label="weavra.companyUsers.name"/>
        </SimpleForm>
    );
}

const GroupQuickEdit = ({record, onSave, onCancel, isLoading}) => {
    return (
        <SimpleForm
            name="group-quick-edit"
            record={record}
            resource="company/companyusergroups"
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="name" label="weavra.companyUsers.name"/>
        </SimpleForm>
    );
}

const validatePassword = regex(/^(?=.*?[A-ZÅÄÖ])(?=.*?[a-zåäö])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/, 'Lösenordet måste vara minst 6 tecken långt och innehålla både stora och små bokstäver, minst en siffra och minst ett specialtecken (ex. !, -, #, *)');
const validatePersonalIdentityNumber = regex(/^$|^(19|20)[0-9]{6}[- ]?[0-9]{4}$/, 'Personnumret måste innehålla 12 siffror.');

const UserQuickCreate = ({ initialUserValues, onSave, onCancel, isLoading, groups }) => {
    return (
        <SimpleForm
            name="user-quick-create"
            resource="company/projectusers"
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="email" label="weavra.companyUsers.emailUsername" />
            
            <Grid container fullWidth>
                <Grid item xs={6}>
                    <TextInput source="password" label="weavra.companyUsers.password" validate={validatePassword} fullWidth />
                </Grid>
                <Grid item xs={6}>
                   <HelpButton textKey={'weavra.help.userPassword'} /> 
                </Grid>
            </Grid>
            <TextInput source="firstName" label="weavra.companyUsers.firstName" />
            <TextInput source="lastName" label="weavra.companyUsers.lastName" />
            <Grid container fullWidth>
                <Grid item xs={6}>
                    <TextInput source="personalIdentityNumber" label="weavra.companyUsers.personalIdentityNumber" placeholder='yyyymmddxxxx' validate={validatePersonalIdentityNumber} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <HelpButton textKey={'weavra.help.personalIdentityNumber'} />
                </Grid>
            </Grid>
            <TextInput source="foreignPersonalIdentityNumber" label="weavra.companyUsers.foreignPersonalIdentityNumber" />
            <TextInput source="telephone" label="weavra.companyUsers.telephone" />
            {/* <TextInput source="id06" /> */}
            <Grid container fullWidth>
                <Grid item xs={6}>
                    <SelectInput source="role" label="weavra.companyUsers.role" optionText="name" fullWidth choices={[
                        { id: 0, name: 'weavra.roles.administrator' },
                        { id: 1, name: 'weavra.roles.user' }
                    ]} />
                </Grid>
                <Grid item xs={6}>
                    <HelpButton textKey={'weavra.help.userRoles'} />
                </Grid>
            </Grid>
            <SelectInput 
            source="companyUserGroupId"
            label="weavra.companyUsers.group"  
            optionText="name" 
            allowEmpty 
            choices={Object.keys(groups).map(key => groups[key])} />
        </SimpleForm>
    );
}

const UserQuickEdit = ({ record, onSave, onCancel, onUploadFile, isLoading, groups }) => {

    return (
        <SimpleForm
            name="user-quick-create"
            record={record}
            resource="company/projectusers"
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="email" label="weavra.companyUsers.emailUsername" />
            <TextInput source="firstName" label="weavra.companyUsers.firstName" />
            <TextInput source="lastName" label="weavra.companyUsers.lastName" />
            <Grid container fullWidth>
                <Grid item xs={6}>
                    <TextInput source="personalIdentityNumber" label="weavra.companyUsers.personalIdentityNumber" placeholder='yyyymmddxxxx' validate={validatePersonalIdentityNumber} fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <HelpButton textKey={'weavra.help.personalIdentityNumber'} />
                </Grid>
            </Grid>
            <TextInput source="foreignPersonalIdentityNumber" label="weavra.companyUsers.foreignPersonalIdentityNumber" />
            <TextInput source="telephone" label="weavra.companyUsers.telephone" />
            {/* <TextInput source="id06" /> */}
            <Grid container fullWidth>
                <Grid item xs={6}>
                    <SelectInput source="role" label="weavra.companyUsers.role" optionText="name" fullWidth choices={[
                        { id: 0, name: 'weavra.roles.administrator' },
                        { id: 1, name: 'weavra.roles.user' }
                    ]} />
                </Grid>
                <Grid item xs={6}>
                    <HelpButton textKey={'weavra.help.userRoles'} />
                </Grid>
            </Grid>
            <SelectInput 
            source="companyUserGroupId"
            label="weavra.companyUsers.group"  
            optionText="name" 
            allowEmpty 
            choices={Object.keys(groups).map(key => groups[key])} />
        </SimpleForm>

    );
    //TODO: Add/Edit files in user dialog?
}

const UserFileQuickCreate = ({ userId, onSave, onCancel, isLoading }) => {
    const initialValues = { userId: userId, validFrom: new Date().toISOString().slice(0,10) };
    return (
        <SimpleForm
            name="userFile-quick-create"
            initialValues={initialValues}
            resource="company/projectuserfiles"
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="description" label="weavra.companyUsers.fileDescription" />
            <DateInput source="validFrom" label="weavra.companyUsers.fileValidFrom" />
            <DateInput source="validUntil" label="weavra.companyUsers.fileValidUntil" />
            <FileInput source="fileData" label="weavra.companyUsers.fileData" multiple={false} accept="image/*,.pdf,.doc,.docx">
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    );
}

const UserFileQuickEdit = ({ record, onSave, onCancel, isLoading, enableDelete }) => {
    return (
        <SimpleForm
            name="userFile-quick-edit"
            record={record}
            resource="company/projectuserfiles"
            save={onSave}
            toolBar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} enableDelete={enableDelete} />}
        >
            <TextField source="name" />
            <FileField source="url" label="Fil" title="Öppna fil" target="_blank" />
            <TextInput source="description" label="weavra.companyUsers.fileDescription" />
            <DateInput source="validFrom" label="weavra.companyUsers.fileValidFrom" />
            <DateInput source="validUntil" label="weavra.companyUsers.fileValidUntil" />
        </SimpleForm>
    )
}

const CompanyUserList = props => {
    const [shownDialog, setShownDialog] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserFile, setSelectedUserFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();

    const [createGroup] = useCreate("company/companyusergroups");
    const [updateGroup] = useUpdate("company/companyusergroups");
    const [createUser] = useCreate("company/projectusers");
    const [updateUser] = useUpdate("company/projectusers");
    const [createUserFile] = useCreate("company/projectuserfiles");
    const [updateUserFile] = useUpdate("company/projectuserfiles");

    //TODO: Use ListBase or skip List and go all custom?

    const handleClose = () => {
        setShownDialog(null);
    }

    const handleNewGroupClicked = () => {
        setShownDialog('groupCreate');
    }

    const handleNewUserClicked = () => {
        setShownDialog('userCreate');
    }

    const handleGroupEdit = (group) => {
        if (group && group.id) {
            setSelectedGroup(group);
            setShownDialog('groupEdit');
        }
    }

    const handleUserEdit = (user) => {
        if (user && user.id) {
            setSelectedUser(user);
            setShownDialog('userEdit');
        }
    }

    const handleUserFileEdit = (file) => {
        if (!file) return;

        setSelectedUserFile(file);
        setShownDialog('userFileEdit');
    }

    const handleAddUserFile = (userId) => {
        if (!userId) return;

        setSelectedUserId(userId);
        setShownDialog('userFileCreate');
    }

    const handleCreateGroupSubmit = async values => {
        setIsLoading(true);
        createGroup(
            { payload: { data: values } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(error.message, 'error')
            }
        );

        setIsLoading(false);
    }

    const handleEditGroupSubmit = async values => {
        setIsLoading(true);
        updateGroup(
            { payload: { id: values.id, data: values } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(error.message, 'error')
            }
        );

        setIsLoading(false);
    }

    const handleCreateUserSubmit = async values => {
        setIsLoading(true);
        createUser(
            { payload: {data: values} },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(error.message, 'error') // TODO: Fix error message text
            }
        );

        setIsLoading(false);
    }

    const handleEditUserSubmit = async values => {
        setIsLoading(true);
        updateUser(
            { payload: { id: values.id, data: values } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(error.message, 'error') // TODO: Fix error message text
            }
        );

        setIsLoading(false);
    }

    const handleCreateUserFile = async values => {
        setIsLoading(true);
        createUserFile(
            { payload: {data: values} },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(error.message, 'error')
            }
        );

        setIsLoading(false);
    }

    const handleEditUserFileSubmit = async values => {
        setIsLoading(true);
        updateUserFile(
            { payload: { id: values.id, data: values } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(error.message, 'error')
            }
        );

        setIsLoading(false);
    }

    //Use useGetList for groups, to get once, and populate a SelectInput in user create/edit
    const { data:groupsData, loading:groupsLoading, error:groupsError } = useGetList(
        'company/companyusergroups',
        { page: 1, perPage: 999 },
        { field: 'name', order: 'ASC' }
    );

    if (groupsLoading) { return <Loading />; }
    if (groupsError) { return <p>FEL!</p>; }
    
    //TODO: Styling...
        // Buttons... same size and with space between

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography>{translate('weavra.help.createAccounts')}</Typography>
                    <CreateUserButton onClick={handleNewUserClicked}/>
                    <CreateGroupButton onClick={handleNewGroupClicked} />
                </Grid>
                <Grid item xs={12}>
                    <List
                        {...props}
                        title="weavra.companyUsers.title"
                        bulkActionButtons={false} 
                        exporter={false}
                        pagination={false}
                        actions={false}
                        perPage={9999}
                        filter={{ active: true }}
                        component="div">
                        <CompanyUserTree onGroupEdit={handleGroupEdit} onUserEdit={handleUserEdit} onFileEdit={handleUserFileEdit} onAddUserFile={handleAddUserFile} groups={groupsData} />
                    </List>
                </Grid>
            </Grid>

            <Dialog open={shownDialog === 'groupCreate'} onClose={handleClose} fullWidth>
                <DialogTitle>{translate('weavra.companyUsers.newGroup')}</DialogTitle>
                <DialogContent>
                    <GroupQuickCreate onSave={handleCreateGroupSubmit} onCancel={handleClose} isLoading={isLoading} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'groupEdit'} onClose={handleClose} fullWidth>
                <DialogTitle>{translate('weavra.companyUsers.editGroup')}</DialogTitle>
                <DialogContent>
                    <GroupQuickEdit record={selectedGroup} onSave={handleEditGroupSubmit} onCancel={handleClose} isLoading={isLoading} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'userCreate'} onClose={handleClose} fullWidth>
                <DialogTitle>{translate('weavra.companyUsers.newUser')}</DialogTitle>
                <DialogContent>
                   <UserQuickCreate onSave={handleCreateUserSubmit} onCancel={handleClose} isLoading={isLoading} groups={groupsData} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'userEdit'} onClose={handleClose} fullWidth>
                <DialogTitle>{translate('weavra.companyUsers.editUser')}</DialogTitle>
                <DialogContent>
                   <UserQuickEdit record={selectedUser} onSave={handleEditUserSubmit} onCancel={handleClose} isLoading={isLoading} groups={groupsData} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'userFileCreate'} onClose={handleClose} fullWidth>
                <DialogTitle>{translate('weavra.companyUsers.newUserFile')}</DialogTitle>
                <DialogContent>
                    <UserFileQuickCreate userId={selectedUserId} onSave={handleCreateUserFile} onCancel={handleClose} isLoading={isLoading} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'userFileEdit'} onClose={handleClose} fullWidth>
                <DialogTitle>{translate('weavra.companyUsers.editUserFile')}</DialogTitle>
                <DialogContent>
                    <UserFileQuickEdit record={selectedUserFile} onSave={handleEditUserFileSubmit} onCancel={handleClose} isLoading={isLoading} />
                </DialogContent>
            </Dialog>

        </Fragment>
    );
}

export default CompanyUserList;