import React from 'react';

import {
    DateInput,
    NumberInput,
    SelectArrayInput,
    SaveContextProvider,
    SimpleForm,
    TextInput,
    BooleanInput,
    TextField,
    DateField,
    SelectInput,
    required,
} from 'react-admin';

import DateTimeOptions from '../../shared/DateTimeOptions';
import QuickDialogActions from '../../shared/QuickDialogActions';

const TaskEditor = props => {
    // A form for creating and editing Tasks
    const { task, project, saveContext, onSave, onDelete, onCancel, isSaving, taskForms, disabled, canDelete, canFinish, canEditDate, canEditDependencies, canEditOwner, companies, relatedTasksType } = props;

    if (!task || !project)
        return (<div></div>);

    // TODO: "Lägg till formulär"-knapp   

    const InFormTaskList = () => {
        const orderedTasks = task.tasks.sort((a, b) => a.sortIndex - b.sortIndex);
        return (
            <ul>
                {orderedTasks.map(childTask =>
                    <li key={childTask.id} style={{ listStyle: 'none' }}>
                        <input type="checkbox" checked={childTask.completed} disabled={true} />
                        <label>
                            {childTask.name}&nbsp;{` - (${childTask.subTasks.filter(st => st.completed).length}/${childTask.subTasks.length})`}
                        </label>
                    </li>
                )}
            </ul>
        );
    }

    const CreateOnlyInputs = () => {
        if (task.id) return null;

        const relatedTasks = project.projectMoments.filter(t => t.id !== task.id && t.momentType === relatedTasksType);

        return (
            <div>
                {canEditDate &&
                    <>
                        <DateInput source="startTime" label="Startdatum" disabled={disabled} />
                        <NumberInput source="length" label="Längd (dagar)" disabled={disabled} />
                    </>
                }

                {canEditDependencies &&
                    <SelectArrayInput
                        source="dependencies"
                        label="Beroende av"
                        allowEmpty
                        choices={relatedTasks}
                        optionText="name"
                        disabled={disabled}
                    />
                }

            </div>
        );
    }

    const optionRenderer = choice => `${choice.name} (${choice.taskCount} uppgifter)`;

    return (
        <div>
            { task && task.id
                ? <p>Redigera uppgift</p>
                : <p>Skapa uppgift</p>
            }
            <SaveContextProvider value={saveContext}>
                <SimpleForm
                    name="task-editor-form"
                    resource="general/projectmoments"
                    redirect={false}
                    save={onSave}
                    saving={isSaving}
                    record={task}
                    toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isSaving} disabled={disabled && !canFinish} enableDelete={task && task.id && canDelete} record={task} onDelete={onDelete} />}
                >
                    <TextInput source="name" label="Uppgift" validate={required()} disabled={disabled} />
                    <TextInput multiline source="description" label="Beskrivning" disabled={disabled} />

                    <CreateOnlyInputs />

                    { canEditOwner && companies && companies.length > 0 &&
                        <SelectInput 
                            source="ownerId"
                            label="Utförare"
                            choices={companies}
                            optionText="name"
                            optionValue="id"
                            disabled={disabled}
                        />
                    }

                    { task.id && !task.taskFormId &&
                        <BooleanInput source="isCompleted" label="Slutförd" options={{ color: "secondary" }} disabled={disabled && !canFinish} />
                    }

                    {task.isCompleted && 
                        <DateField source="completed" label="Slutfört, datum" showTime options={DateTimeOptions} locales="sv-SE" />
                    }
                    {task.isCompleted && 
                        <TextField source="completedBy" label="Slutförare" />
                    }

                    { taskForms && taskForms.length > 0 &&
                        <SelectInput 
                            source="taskFormId"
                            label="Formulär"
                            allowEmpty
                            emptyText="- Inget formulär -"
                            choices={taskForms}
                            optionText={optionRenderer}
                            disabled={disabled}
                        />
                    }

                    {
                        // TODO: Knapp "Lägg till formulär" om taskFormId saknas
                        // TODO: Visa väljaren efter knapp tryckts > välj formulär eller avbryt
                        // TODO: Om taskFormId redan finns: visa knapparna "Ta bort formulär" eller (om uppgiften inte är påbörjad) "Byt formulär"
                    }

                    { task.taskFormId &&
                        <InFormTaskList />
                    }

                </SimpleForm>
            </SaveContextProvider>
        </div>
    );
}

export default TaskEditor;