import React, {useMemo} from "react";

import { useDrag, useDrop } from 'react-dnd';

import { makeStyles, Grid, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    taskCard: {
        width: '100%',
        marginBottom: theme.spacing(0.5),
        border: `1px solid ${theme.palette.background.paper}`,
        padding: theme.spacing(0.5),
        cursor: 'pointer',
        alignItems: 'center',

        "&:hover": {
            transition: 'background 0.5s',
            background: 'rgba(255,255,255,0.08)',
        },
    },
    taskDone: {

    },
    taskNotDone: {

    },
}));

const TaskCard = props => {
    // A draggable card for each moment/task
    const { task, sourceList, onTaskClicked, findCard, moveCard, id, permittedToDrag } = props;

    const classes = useStyles();

    const totalTasks = task.id !== null ? task.tasks.length : 0;
    const completedTasks = task.id !== null ? task.tasks.filter(t => t.completed).length : 0;
    const allDone = completedTasks === totalTasks && totalTasks !== 0;

    const canDrag = useMemo(() => (
        task && task.id && permittedToDrag && !allDone
    ), [allDone, permittedToDrag, task]);

    const originalIndex = findCard(task.id).index;

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'TASKCARD',
        item: { id, sourceList, task, originalIndex },
        canDrag: () => canDrag,
        collect: monitor => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const { id: droppedId, originalIndex } = item;
            if (!monitor.didDrop()) {
                moveCard(droppedId, originalIndex);
            }
        },
    }), [canDrag, task, moveCard, id]);

    const [, drop] = useDrop(() => ({
        accept: 'TASKCARD',
        hover({ id: draggedId }) {
            if (draggedId !== id) {
                const { index: overIndex } = findCard(id); // find the card we're hovering over
                moveCard(draggedId, overIndex);
            }
        },
    }), [findCard, moveCard])

    const handleClick = () => {
        onTaskClicked(task);
    }
    
    const TaskCardIcon = () => {
        if (task.taskFormId)
            return <FormatListNumberedIcon />
        
        return allDone ? <CheckCircleOutlineIcon /> : <RemoveCircleOutlineIcon />
    }
    
    return (
        <Grid
            container
            className={classnames(classes.taskCard, allDone ? classes.taskDone : classes.taskNotDone)}
            ref={(node) => drag(drop(node))}
            style={{
                opacity: isDragging ? 0.72 : 1
            }}
        >
            <Grid item xs={10} onClick={() => handleClick()}>
                <Typography>
                    <TaskCardIcon />
                    {task.name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                {totalTasks > 0 && `${completedTasks}/${totalTasks}`}
            </Grid>
            {task.taskFormId && totalTasks > 0 &&
                <Grid item xs={12}>
                    <progress max={totalTasks} value={completedTasks}>{`${completedTasks}/${totalTasks}`}</progress>
                </Grid>
            }
        </Grid>
    );
}

export default TaskCard;