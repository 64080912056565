import React, { useState } from "react";
import {
    useInput,
    Button,
} from 'react-admin';
import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';

import Map from "../Map";

const GMapDialogButtonInput = props => {
    const { city, street, zip, onAddressChange, canEdit } = props;

    const {input} = useInput(props);

    const defaultCoordinates = { lat: 60.128161, lng: 18.643501 };

    const [dialogOpen, setDialogOpen] = useState(false);
    const [markerPos, setMarkerPos] = useState();
    const [address, setAddress] = useState();
    const [dialogCaption, setDialogCaption] = useState();

    const addressHasChanged = address && (address.city !== city || address.street !== street || address.zip !== zip);

    const googleKey = process.env.REACT_APP_GOOGLEKEY;

    const getCoords = (googleRes) => {
        const lat = googleRes.results[0].geometry.location.lat;
        const lng = googleRes.results[0].geometry.location.lng;
    
        return { lat, lng };
    }

    const getAddressComponent = (typeName, record) => record.address_components.find(x => x.types.includes(typeName))?.long_name || null;

    const formattedAddress = (city, street, zip) => `${street}, ${zip}, ${city}`;

    const formattedCoords = (coords) => `(${coords.lat ?? '?'}, ${coords.lng ?? '?'})`;

    // TODO: Move google fetch functions to helper/service?
    const fetchCoordinates = async addressString => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=+${encodeURI(addressString)}&key=${googleKey}`;

        await fetch(url)
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    console.log('Google found location for address, setting markerPos', res);
                    setMarkerPos(getCoords(res));
                } else if (res.status === 'ZERO_RESULTS') {
                    if (!markerPos)
                        setMarkerPos(defaultCoordinates);
                    
                    console.log('Google found no location...', addressString);
                }
            })
            .catch(console.error);
    }

    const fetchAddress = async coordinates => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&result_type=street_address&key=${googleKey}`;

        await fetch(url)
            .then(res => res.json())
            .then(res => {
                if (res.status === 'OK') {
                    const streetName = getAddressComponent('route', res.results[0]);
                    const streetNumber = getAddressComponent('street_number', res.results[0]);

                    const foundAddress = {
                        city: getAddressComponent('postal_town', res.results[0]),
                        street: streetName ? `${streetName} ${streetNumber}` : null,
                        zip: getAddressComponent('postal_code', res.results[0])
                    };
                    console.log('Found address: ', foundAddress);
                    setAddress(foundAddress);
                    setDialogCaption(formattedAddress(foundAddress.city, foundAddress.street, foundAddress.zip));
                } else if (res.status === 'ZERO_RESULTS') {
                    // TODO: setAddress(null)?
                    console.log('Google found no address...', res);
                    setDialogCaption(formattedCoords(coordinates));
                }
            })
            .catch(console.error);
    }

    const handleOpen = async _ => {
        let currentCoords = {};
        if (input.value) {
            //console.log('Opening map dialog with coordinates set', input.value);
            setMarkerPos(input.value);
            currentCoords = input.value;
        }
        else if (city && street && zip) {
            if (markerPos && !addressHasChanged) {
                //console.log('No change, just opening map...');
            } else {
                //console.log('Opening map dialog with address set', address);
                await fetchCoordinates(formattedAddress(city, street, zip));
                setAddress({ city, street, zip });
            }
        }
        else {
            if (navigator.geolocation) {
                let browserPos = {};
                navigator.geolocation.getCurrentPosition((pos) => {
                    browserPos = { lat: pos.coords.latitude, lng: pos.coords.longitude };
                    setMarkerPos(browserPos);
                    currentCoords = browserPos;
                    //console.log('Opening map dialog with browser coordinates set', browserPos);
                });     
                
            } else {
                //console.log('Opening map dialog with default coordinates set', defaultCoordinates);
                setMarkerPos(defaultCoordinates);
                currentCoords = defaultCoordinates;
            }
        }

        // TODO: Handle zoom level...

        //console.log('Setting dialog caption, ', { city, street, zip }, currentCoords);
        if (city && street && zip)
            setDialogCaption(formattedAddress(city, street, zip));
        else
            setDialogCaption(currentCoords ? formattedCoords(currentCoords) : '');
        
        setDialogOpen(true);
    }

    const handleClose = (shouldSave = false) => {
        if (shouldSave) {
            onAddressChange(address);
        }

        input.onChange(markerPos); // TODO: Do not update unless shouldSave?
        setDialogOpen(false);
    }

    const mapProps = {
        center: markerPos,
        defaultZoom: 15,
        containerStyle: { height: '600px', width: '100%' },
        apiKey: googleKey,
        markers: markerPos,
        onMapClick: (e) => {
            if (!canEdit) return;
            const coords = { lat: e.latLng.lat(), lng: e.latLng.lng() };
            setMarkerPos(coords);
            fetchAddress(coords);
        },
    }

    return (
        <div>
            <Button label="Karta" size="large" onClick={() => handleOpen()}><RoomOutlinedIcon /></Button>

            <Dialog open={dialogOpen} onClose={() => handleClose(false)} maxWidth="lg" fullWidth>
                <DialogTitle>
                    <div>{dialogCaption}</div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <Map {...mapProps} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        { canEdit && 
                            <Button label="Spara" onClick={() => handleClose(true)}></Button>
                        }
                        <Button label="Avbryt" onClick={() => handleClose()}></Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default GMapDialogButtonInput;