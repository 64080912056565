import React, { useState } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import { parse } from 'query-string';
import { CssBaseline, Container, Card, TextField, Button } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    logo: {
        maxWidth: '400px',
        marginTop: '-120px',
    },
    card: {
        textAlign: 'center',
        padding: '16px', // TODO: theme.spacing...
    },
    form: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    input: {
        width: 300,
        marginBottom: '8px', // TODO: theme.spacing...
    },
}));

const ResetPasswordForm = (props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmError, setConfirmError] = useState('');
    const [requestSubmitted, setRequestSubmitted] = useState('');

    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const submit = e => {
        e.preventDefault();
        setPasswordError('');
        setConfirmError('');

        if (!/^(?=.*?[A-ZÅÄÖ])(?=.*?[a-zåäö])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/.test(password)) {
            setPasswordError('Lösenordet måste vara minst 6 tecken långt och innehålla både stora och små bokstäver, minst en siffra och minst ett specialtecken (ex. !, -, #, *)');
            return;
        }

        if (confirmPassword !== password) {
            setConfirmError('Lösenorden matchar inte.');
            return;
        }

        const { token, email } = parse(props.location.search);
        console.log('Token and email is', token, email);
            
        dataProvider.resetPassword({ data: { password, confirmPassword, token, email } })
            .then(() => {
                console.log('Success!');
                setRequestSubmitted('SENT');
            })
            .catch(error => {
                console.log('Error!', error);
                setRequestSubmitted('ERROR');
            });
    }

    return (
        <>
            <img src={process.env.PUBLIC_URL + '/images/weavra-horizontal.png'} alt="" className={classes.logo} />
            <Card className={classes.card}>
                <h1 className={classes.title}>{translate('weavra.login.resetPassword')}</h1>

                {!requestSubmitted &&
                    // TODO: Help text?
                    <form onSubmit={submit} className={classes.form}>
                        <TextField
                            placeholder={translate('weavra.login.password')}
                            name="password"
                            type="password"
                            variant="outlined"
                            value={password}
                            className={classes.input}
                            onChange={e => setPassword(e.target.value)}
                            required
                            error={!!passwordError}
                            helperText={passwordError && passwordError}
                        />
                        <TextField
                            placeholder={translate('weavra.login.confirmPassword')}
                            name="confirmPassword"
                            type="password"
                            variant="outlined"
                            value={confirmPassword}
                            className={classes.input}
                            onChange={e => setConfirmPassword(e.target.value)}
                            required
                            error={!!confirmError}
                            helperText={confirmError && confirmError}
                        />
                        <Button type="submit" size="large" variant="contained">{translate('weavra.login.sendReset')}</Button>
                    </form>
                }

                {requestSubmitted && requestSubmitted === 'SENT' &&
                    // TODO: Localization    
                    <p className={classes.input}>
                        Ditt lösenord är nu återställt!
                    </p>
                }

                {requestSubmitted && requestSubmitted === 'ERROR' &&
                    // TODO: Localization    
                    <p className={classes.input}>
                        Något gick fel. Försök igen eller kontakta administratör.
                    </p>
                }
            </Card>
        </>
    );
}

const ResetPasswordPage = (props) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={createMuiTheme(props.theme)}>
            <Container className={classes.root}>
                <ResetPasswordForm {...props} />
            </Container>
            <CssBaseline/>
        </ThemeProvider>
    );
}

export default ResetPasswordPage;