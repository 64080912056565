import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';

import { 
    Notification, 
} from 'react-admin';

import EmptyAppBar from './EmptyAppBar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        position: 'relative',
        minWidth: 'fit-content',
        width: '100%',
        color: theme.palette.getContrastText(
            theme.palette.background.default
        ),
    },
    appFrame: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        //overflowX: 'auto',
    },
    contentWithSidebar: {
        display: 'flex',
        flexGrow: 1,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: 0,
        padding: 0,
    },
}));

const EmptyLayout = ({ children, title, theme, className }) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <div className={classnames('layout', classes.root, className)}>
                <div className={classes.appFrame}>
                    <EmptyAppBar title={title} />
                    <main className={classes.contentWithSidebar}>
                        <div className={classes.content}>
                            {children}
                        </div>
                    </main>
                    <Notification />
                </div>
            </div>
            <CssBaseline/>
        </ThemeProvider>
    );
};

EmptyLayout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    dashboard: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    title: PropTypes.string.isRequired,
};

export default EmptyLayout;
