import React from "react";
import {
    SaveContextProvider,
    SimpleForm,
    TextInput,
    Button,
    NumberInput,
    DateTimeInput,
} from 'react-admin';

import { Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import EditorToolBar from "./EditorToolBar";

const TaskEditor = props => {
    const { record, saveContext, onDelete, onAddNewSubTask } = props;
    // TODO: Styling?

    const InFormSubTaskList = () => {
        return (
            <>
                <label>Underuppgifter</label>
                <ul>
                    {record.subTasks.map(subTask =>
                        <li key={subTask.id}><Typography>{subTask.name}</Typography></li>
                    )}
                </ul>
                <Button
                    label="Lägg till underuppgift"
                    variant="text"
                    size="small"
                    onClick={() => onAddNewSubTask(record)}>
                    <AddCircleIcon />
                </Button>
            </>
        );
    }
    return (
        <>
            <Typography>Redigera uppgift - {record.name}</Typography>
            <SaveContextProvider value={saveContext}>
                <SimpleForm
                    name="task-edit"
                    resource="general/projecttasks"
                    record={record}
                    save={saveContext.save}
                    saving={saveContext.saving}
                    toolbar={<EditorToolBar onDeleteClick={onDelete} />}
                    redirect={false}>
                    <TextInput source="name" label="Namn" />
                    <TextInput multiline source="description" label="Beskrivning" />
                    <NumberInput source="sortIndex" label="Sortering" />
                    <DateTimeInput source="startTime" label="Start" />
                    <DateTimeInput source="endTime" label="Stop" />
                    <InFormSubTaskList />
                </SimpleForm>
            </SaveContextProvider>
        </>
    );
}

export default TaskEditor;