import React from 'react';

import { 
    useListContext,
    useTranslate,
} from 'react-admin';

import { makeStyles, Typography, Grid } from '@material-ui/core';

import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
//import DescriptionIcon from '@material-ui/icons/Description';


const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '50vw',
    },
    roleContainer: {
        marginBottom: theme.spacing(2),
    },
    clickableText: {
        cursor: 'pointer',
        "&:hover": {
            transition: 'background 0.5s',
            background: theme.palette.background.default,
          },
    },
    box: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: '4px',
    },
    groupContainer: {
        marginLeft: theme.spacing(3),
        marginTop: theme.spacing(1),
    },
    userContainer: {
        marginLeft: theme.spacing(6),
        marginTop: theme.spacing(1),
    },
    fileContainer: {
        marginLeft: theme.spacing(9),
        marginTop: theme.spacing(1),
    },
}));

// const UserFileRow = ({file, onFileEdit}) => {
//     const classes = useStyles();
//     const fileValidFrom = new Date(file.validFrom).toISOString().slice(0,10);
//     const fileValidUntil = new Date(file.validUntil).toISOString().slice(0,10);
//     return (
//         <div className={`${classes.fileContainer} ${classes.box}`}>
//             <Typography 
//                 variant='subtitle2' 
//                 className={classes.clickableText} 
//                 onClick={() => onFileEdit(file)}
//             >
//                 <DescriptionIcon /> {`${file.description} (${fileValidFrom} - ${fileValidUntil})`}
//             </Typography>
//         </div>
//     )
// }

const UserRow = ({user, onUserEdit, onFileEdit, onAddUserFile}) => {
    const classes = useStyles();
    return (
        <div className={classes.userContainer}>            
            <Grid container className={classes.box}>
                <Grid item xs={12}>
                    <Typography 
                        variant='subtitle2' 
                        className={classes.clickableText} 
                        onClick={() => onUserEdit(user)}
                    >
                        <PersonIcon /> {`${user.firstName} ${user.lastName} (${user.userName})`}
                    </Typography>
                </Grid>
                {/* <Grid item container justify="flex-end" xs={3}>
                    <Typography variant='button' className={classes.clickableText} onClick={() => onAddUserFile(user.id)}>+ Ny fil</Typography>
                </Grid> */}
            </Grid>
            {/* {user.files.map(file => 
                <UserFileRow file={file} key={file.id} onFileEdit={onFileEdit} />
            )} */}
        </div>
    );
}

const GroupRow = ({group, onGroupEdit, onUserEdit, onFileEdit, onAddUserFile}) => {
    const classes = useStyles();
    //TODO: Collapsible?
    return (
        <div className={classes.groupContainer}>
            <div className={classes.box}>
                <Typography 
                    variant='subtitle1' 
                    className={group.id && classes.clickableText} 
                    onClick={() => onGroupEdit(group)}
                >
                    <PeopleIcon /> {group.name}
                </Typography>
            </div>
            {group.users.map(user => 
                <UserRow user={user} key={user.id} onUserEdit={onUserEdit} onFileEdit={onFileEdit} onAddUserFile={onAddUserFile} />
            )}
        </div>
    );
}

const CompanyUserTree = ({onGroupEdit, onUserEdit, onFileEdit, onAddUserFile, groups}) => {
    const { ids, data } = useListContext();
    const translate = useTranslate();
    const classes = useStyles();
    //TODO: props... just send destructured props to children??
    //TODO: Rendera alla grupper (även utan users?)
    //TODO: Ta bort grupper

    //Filter users into roles
    const admins = [];
    const basicUsers = [];

    ids.map(id => {
        const user = data[id];
        if (user.role === 0)
            return admins.push(user);
        else
            return basicUsers.push(user);
    });
    
    if (admins && admins.length > 1)
        admins.sort((a, b) => a.firstName.localeCompare(b.firstName));
    if (basicUsers && basicUsers.length > 1)
        basicUsers.sort((a, b) => a.firstName.localeCompare(b.firstName));

    const adminGroups = [];
    let noGroupUsersTemp = [];
    for (let i = 0; i < admins.length; i++) {
        const usr = admins[i];
        if (!usr.companyUserGroupId) {
            noGroupUsersTemp.push(usr);
            continue;
        }
        const group = adminGroups.find(g => g.id === usr.companyUserGroupId);
        if (!group) {
            if (!groups || !groups[usr.companyUserGroupId]) {
                
                return <p>Fel!</p>;
            }
            const {id, name, companyId, active, created, createdBy} = groups[usr.companyUserGroupId];
            const newGroup = { id, name: name || 'Unknown group', companyId, active, created, createdBy, users: [] };
            newGroup.users.push(usr);
            adminGroups.push(newGroup);
        }
        else
            group.users.push(usr);
    }
    adminGroups.sort((a, b) => a.name.localeCompare(b.name));

    if (noGroupUsersTemp.length > 0)
        adminGroups.push({ id: null, name: translate('weavra.companyUsers.usersWithoutGroup'), users: [...noGroupUsersTemp]});

    const userGroups = [];
    noGroupUsersTemp = [];
    for (let i = 0; i < basicUsers.length; i++) {
        const usr = basicUsers[i];
        if (!usr.companyUserGroupId) {
            noGroupUsersTemp.push(usr);
            continue;
        }
        const group = userGroups.find(g => g.id === usr.companyUserGroupId);
        if (!group) {
            if (!groups || !groups[usr.companyUserGroupId]) {
                
                return <p>Fel!</p>;
            }
            const {id, name, companyId, active, created, createdBy} = groups[usr.companyUserGroupId];
            const newGroup = { id, name: name || 'Unknown group', companyId, active, created, createdBy, users: [] };
            newGroup.users.push(usr);
            userGroups.push(newGroup);
        }
        else {
            group.users.push(usr);
        }
    }
    userGroups.sort((a, b) => a.name.localeCompare(b.name));

    if (noGroupUsersTemp.length > 0)
        userGroups.push({ id: null, name: translate('weavra.companyUsers.usersWithoutGroup'), users: [...noGroupUsersTemp]});
    //

    return (
        <div className={classes.root}>
            <div className={classes.roleContainer}>
                <div>
                    <Typography variant='h6'>{translate('weavra.roles.administrators')}</Typography>
                </div>
                {adminGroups.map(grp => 
                    <GroupRow group={grp} key={grp.id} onGroupEdit={onGroupEdit} onUserEdit={onUserEdit} onFileEdit={onFileEdit} onAddUserFile={onAddUserFile} />
                )}
            </div>
            <div className={classes.roleContainer}>
                <div>
                    <Typography variant='h6'>{translate('weavra.roles.users') + ' (mobilapp)'}</Typography>
                </div>
                {userGroups.map(grp =>
                    <GroupRow group={grp} key={grp.id} onGroupEdit={onGroupEdit} onUserEdit={onUserEdit} onFileEdit={onFileEdit} onAddUserFile={onAddUserFile} />
                )}
            </div>
        </div>
    );
}

export default CompanyUserTree;