import React, { Fragment, cloneElement } from 'react';
import { useSelector } from "react-redux";
import { 
    usePermissions,
    useQuery,
    useListContext,
    Loading,
    Error,
    List,
    Datagrid,
    TextField,
    FunctionField,
    DateField,
    Filter,
    DateInput,
    TopToolbar,
    sanitizeListRestProps,
} from 'react-admin';

import { 
    Grid,
} from '@material-ui/core';

import UnauthorizedContent from '../shared/UnauthorizedContent';
import LedgerExporterPDF from './LedgerExporterPDF';
import LedgerExportButton from './LedgerExportButton';

const ProjectInfoHeader = props => {
    const { ledgerInfo } = props;
    
    const { ledgerActive, projectTaxationId, address } = ledgerInfo;

    return (
        <Grid container spacing={2}>
            <Grid container item xs={6}>
                <Grid item xs={4}>
                    <span>Status</span>
                </Grid>
                <Grid item xs={4}>
                    <span>SkatteID</span>
                </Grid>
                <Grid item xs={4}>
                    <span>Adress</span>
                </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid container item xs={6}>
                <Grid item xs={4}>
                    <span>{ledgerActive ? 'Aktiv' : 'Ej aktiv'}</span>
                </Grid>
                <Grid item xs={4}>
                    <span>{projectTaxationId}</span>
                </Grid>
                <Grid item xs={4}>
                    <span>{address}</span>
                </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid container item xs={12}>
                <Grid item>
                    <p>* avser utländskt personnummer</p>
                </Grid>
            </Grid>
        </Grid>
    )
}

const CheckInListFilter = props => (
    <Filter {...props}>
        <DateInput label="Från" source="checkInTime_fromDate"  />
        <DateInput label="Till" source="checkInTime_toDate" />
    </Filter>
)

const CheckInListActions = props => {
    const { className, exporter, filters, maxResults, ledgerInfo, ...rest } = props;

    const {
        currentSort,
        resource,
        displayedFilters,
        filter,
        filterValues,
        showFilter,
        total,
    } = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <LedgerExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                currentSort={currentSort}
                filter={filter}
                filterValues={filterValues}
                ledgerInfo={ledgerInfo}
            />
        </TopToolbar>
    );
}

const TimeOptions = {
    hour: '2-digit',
    minute: '2-digit',
};

const EmptyLedger = () => {
    return (
        <div>
        </div>
    )
}

const CheckInList = props => {
    const { projectId, ledgerInfo, ...rest } = props;

    if (!projectId)
        return null;

    const personalIdentityNumberString = (record) => {
        if (record.personalIdentityNumber)
            return record.personalIdentityNumber.slice(0, -4) + 'XXXX';
        else if (record.foreignPersonalIdentityNumber)
            return record.foreignPersonalIdentityNumber + '*';
        else
            return null;
    }

    return (
        <List
            {...rest} 
            title='weavra.navigation.ledger'
            resource='project/personnelledger'
            bulkActionButtons={false} 
            exporter={LedgerExporterPDF}
            actions={<CheckInListActions ledgerInfo={ledgerInfo} />}
            sort={{ field: 'checkInTime', order: 'DESC' }}
            filter={{ projectId: projectId }}
            filters={<CheckInListFilter />}
            empty={<EmptyLedger />}
        >
            <Datagrid>
                <TextField source="name" label="weavra.ledger.name" sortBy="ProjectUser.FirstName" />
                <FunctionField label="weavra.ledger.personalIdentityNumber" sortBy="ProjectUser.PersonalIdentityNumber" render={record => personalIdentityNumberString(record)} />
                <TextField source="companyName" label="weavra.ledger.company" sortBy="ProjectUser.Company.Name" />
                <TextField source="legalRegistrationNumber" label="weavra.ledger.legalRegistrationNumber" sortBy="ProjectUser.Company.LegalRegistrationNumber" />
                <DateField source="date" label="weavra.ledger.date" sortBy="checkInTime" locales="sv-SE" />
                <DateField source="checkInTime" label="weavra.ledger.checkInTime" showTime options={TimeOptions} locales="sv-SE" />
                <DateField source="checkOutTime" label="weavra.ledger.checkOutTime" showTime options={TimeOptions} locales="sv-SE" />
            </Datagrid>
        </List>
    )
}

const LedgerContent = props => {
    const { ...rest } = props;

    // Ladda projekt-Id från redux-state
    const projectId = useSelector(state => state.workspace.id);
    const userCompanyId = localStorage.getItem('user.company.id');

    const { data:ledgerInfo, loaded:ledgerInfoLoaded, error } = useQuery({
        type: 'getOne',
        resource: 'project/personnelledger',
        payload: { id: projectId, getLedgerInfo: true }
    });

    if (!projectId)
        return <Error />;
    
    if (!ledgerInfoLoaded)
        return <Loading />
    
    if (error)
        return <Error />
    
    if (ledgerInfo.projectOwnerId !== userCompanyId)
        return <UnauthorizedContent message={'Endast projektägare har åtkomst till personalliggaren.'} /> // TODO: Maybe do permission check backend instead...
    
    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ProjectInfoHeader ledgerInfo={ledgerInfo} />
                </Grid>
                <Grid item xs={12}>
                    <CheckInList {...rest} projectId={projectId} ledgerInfo={ledgerInfo} />
                </Grid>
            </Grid>
        </Fragment>
    )
}

const ProjectPersonnelLedger = props => {
    const { loaded, permissions } = usePermissions();

    if (!loaded)
        return <Loading />
    
    return permissions && permissions.includes('Admin') 
        ? <LedgerContent {...props} /> 
        : <UnauthorizedContent />
}

export default ProjectPersonnelLedger;