import React from "react";
import {
    SimpleForm,
    TextInput,
    BooleanInput,
    SelectInput,
    FormDataConsumer,
} from 'react-admin';

import { Typography } from '@material-ui/core';

import EditorToolBar from "./EditorToolBar";

const SubTaskApproveBehaviourEditor = props => {
    const { record, moments, onSave, onDelete } = props;

    return (
        <>
            <Typography>Redigera regler</Typography>
            <SimpleForm
                name="subtask-behaviour-edit"
                resource="general/projectsubtaskapprovebehaviours"
                record={record}
                save={onSave}
                toolbar={<EditorToolBar onDeleteClick={onDelete}  />}
                redirect={false}>
                <SelectInput label="Moment att rulla tillbaka till" source="rollbackMomentId" optionText="name" choices={moments} />
                <BooleanInput label="Med notifiering?" source="notify.active" />
                <FormDataConsumer>
                    {({ formData, ...rest }) => (formData.notify && formData.notify.active) &&
                        <React.Fragment>
                            <TextInput label="Text vid OK" source="notify.completedOkText" />
                            <br />
                            <TextInput label="Text vid Ej OK" source="notify.notOkText" />
                        </React.Fragment>
                    }
                </FormDataConsumer>
            </SimpleForm>
        </>
    );
}

export default SubTaskApproveBehaviourEditor;