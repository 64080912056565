import React from 'react';
import {
    Toolbar,
    SaveButton,
    Button,
} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';

const SimpleDeleteButton = props => {
    //TODO: Styla annorlunda
    //TODO: Separera ut och stoppa i /shared
    return (
        <Button
        label="ra.action.delete" 
        variant="outlined" 
        color="primary" 
        onClick={props.onClick}
        > 
            <DeleteIcon fontSize="large" />
        </Button>
    );
}

const EditorToolBar = props => {
    // TODO: styling... se toolbar i ex dokumentvyn för dialogen
    const { onDeleteClick, record, ...rest } = props;
    return (
        <Toolbar {...rest}>
            <SaveButton disabled={props.pristine} />
            { onDeleteClick &&
                <SimpleDeleteButton onClick={() => onDeleteClick(record)} />
            }
        </Toolbar>
    );
}

export default EditorToolBar;