import React from 'react';
//import 'gantt-task-react/dist/index.css';
import { ViewMode } from 'gantt-task-react';

const ViewSwitcher = props => {
    const { onViewModeChange, onViewListChange, isChecked } = props;

    return (
        <div className="ViewContainer">
            <button className="Button" onClick={() => onViewModeChange(ViewMode.Day)}>Dag</button>
            <button className="Button" onClick={() => onViewModeChange(ViewMode.Week)}>Vecka</button>
            <button className="Button" onClick={() => onViewModeChange(ViewMode.Month)}>Månad</button>

            <div className="Switch">
                <label className="Switch_Toggle">
                    <input type="checkbox" defaultChecked={isChecked} onClick={() => onViewListChange(!isChecked)} />
                    <span className="Slider" />
                </label>
                Visa lista
            </div>
        </div>
    );

}

export default ViewSwitcher;