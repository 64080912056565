import React, { useState } from 'react';
import {
    useListContext,
    List,
    Datagrid,
    TextField,
    DateField,
    Button,
} from 'react-admin';

import ListAltIcon from '@material-ui/icons/ListAlt';

//import ReportViewDialog from './ReportViewDialog';
import ReportPdfDialog from './ReportPdfDialog';


const ListBulkActionButtons = props => {
    const { onShowSummaryClick, selectedIds, ...rest } = props;
    return (
        <>
            <Button
                {...rest}
                onClick={() => { onShowSummaryClick(selectedIds) }}
                title="Visa rapport"
            >
                <ListAltIcon />
            </Button>
        </>
    );
}

const NoReports = () => {

    return (
        <div style={{ margin: '12px' }}>
            Inga rapporter tillgängliga
        </div>
    )
}

const ReportsTab = props => {
    const { projectId, projectData, projectOwnerName, ...rest } = props;

    const [records, setRecords] = useState(null);
    const [reportRecords, setReportRecords] = useState(null);
    const [showReportDialog, setShowReportDialog] = useState(false);

    const EmptyAside = () => {
        // TODO: Fix timing error : 'Cannot update a component (`ReportsTab`) while rendering a different component (`EmptyAside`)'
        const { data } = useListContext();
        if (data)
            setRecords(data);
        
        return null;
    }

    const handleShowSummary = (selectedIds) => {
        if (!records || records.length === 0) return;

        const selectedRecords = selectedIds.map(key => records[key]);

        showReport(selectedRecords);
    }

    const handleRowClick = (id, resource, record) => {
        showReport([record]);
    }

    const showReport = (records) => {
        setReportRecords(records);
        setShowReportDialog(true);
    }

    const handleDialogClose = () => {
        setShowReportDialog(false);
    }

    return (
        <div>
            <List
                {...rest}
                resource="project/projectmoments"
                title=" "
                filter={{ projectId: projectId, completedOnly: true, formsOnly: true, momentType: 0 }}
                bulkActionButtons={<ListBulkActionButtons onShowSummaryClick={handleShowSummary} />}
                sort={{ field: 'sortIndex', order: 'ASC' }}
                actions={false}
                empty={<NoReports />}
                aside={<EmptyAside />}
            >
                <Datagrid rowClick={handleRowClick}>
                    <TextField source="name" label="Uppgift" />
                    <DateField source="completed" label="Slutförd" />
                    <TextField label="Utförare" source="ownerName" sortBy="ownerId" />
                    <TextField source="completedBy" label="Slutförare" />
                </Datagrid>
            </List>

            <ReportPdfDialog
                dialogOpen={showReportDialog}
                handleClose={handleDialogClose}
                records={reportRecords}
                projectData={projectData}
                projectOwnerName={projectOwnerName}
            />
        </div>
    );
}

export default ReportsTab;