import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDataProvider, useTranslate } from "react-admin";

import Autocomplete from "@material-ui/lab/Autocomplete";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import Grid from "@material-ui/core/Grid";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// import parse from 'autosuggest-highlight/parse';
import throttle from "lodash/throttle";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  hover: {
    transition: "background 0.5s",
    background: "transparent",

    "&:hover": {
      transition: "background 0.5s",
      background: theme.palette.background.default,
    },
  },
  input: {
    width: 480,
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: "0 8px",
    },
  },
  listItem: {
    borderBottom: '1px solid #ddd',
    paddingBottom: '4px',
  },
}));

const ProjectAutocompleteSelect = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const dataProvider = useDataProvider();
  const dispatch = useDispatch();
  const translate = useTranslate();
  const companyId = useSelector(state => state.user.companyId);
  const workspace = useSelector(state => state.workspace.type);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        dataProvider
          .getList("general/projects", {
            pagination: { page: 1, perPage: 9 },
            sort: { field: "name", order: "DESC" },
            filter: { filterByCompany: true, companyId: companyId, q: request },
            getSearchResults: true,
          })
          .then(({ data }) => {
            callback(data);
          })
          .catch((error) => {
            console.error("Dataprovider error", error);
            //TODO: handle error
          });
      }, 300),
    [dataProvider, companyId]
  );

  React.useEffect(() => {
    if (workspace !== 'project') {
      setValue(null);
    }
  }, [workspace]);

  React.useEffect(() => {
    let active = true;

    fetch(inputValue, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleProjectSelect = (id = null, name = null) => {
    dispatch({ type: "SET_WORKSPACE", payload: { type: "project", id: id, name: name }});
  };

  return (
    <Autocomplete
      {...props}
      id="project-autocomplete-select"
      className={classes.input}
      getOptionLabel={(option) => {
        return typeof option === "string" ? option : option.name;
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      filterSelectedOptions
      value={value}
      noOptionsText={translate("weavra.autocomplete.noOptions")}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) handleProjectSelect(newValue.id, newValue.name);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="medium"
          placeholder={translate("weavra.autocomplete.pickProject")}
          variant="outlined"
          fullWidth
          className={classes.hover}
        />
      )}
      renderOption={(option) => {
        //TODO: handle the formatting...
        //TODO: kan vi fixa nån magisk highlight av söktexten?
        //https://www.npmjs.com/package/autosuggest-highlight <- match, then parse, then map?

        // const matches = option.strutured_formatting.main_text_matched_substrings;
        // const parts = parse(
        //     option.structured_formatting.main_text,
        //     matches.map((match) => [match.offset, match.offset + match.length]),
        // );

        return (
          <Grid container alignItems="center" className={classes.listItem}>
            <Grid item>
              <AccountTreeIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {
                /*parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highligth ? 700 : 400 }}>
                                  {part.text}
                              </span>
                          ))*/
                option.name
              }
              <Typography variant="body2" color="textSecondary">
                {option.orderNumber}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default ProjectAutocompleteSelect;
