import React, { useState } from "react";
import classnames from 'classnames';

import { makeStyles, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles((theme) => ({
    momentsTreeNodeRoot: {
        marginTop: theme.spacing(1),
    },
    momentsTreeNode: {
        cursor: 'pointer',
        display: 'inline-flex',
        
        "&:hover": {
            transition: 'background 0.5s',
            background: 'rgba(255,255,255,0.08)',
        },
    },
    selectedNode: {
        textDecoration: 'underline',
        fontWeight: 'bolder',
    },
    bottomBox: {
        background: theme.palette.background.paper,
        borderRadius: '4px',
        padding: theme.spacing(2),
        minHeight: '180px',
    },
}))

const FormsTreeNode = props => {
    const { children, node, level, isOpen, isSelected, onSelect, labelPrefix } = props;
    const classes = useStyles();
    

    const [ openState, setOpenState ] = useState(isOpen);

    // TODO: flytta upp openState?
    // TODO: Klick på ikon ska kanske inte aktivera noden i editor... flytta om lite?

    const handleToggle = () => {
        setOpenState(!openState);
    }

    const getIcon = () => {

        if (!children || children.length === 0)
            return null;
        if (level === 2) {
            // TODO: refactor...
            if (children[0].length === 0 && !children[1])
                return null;
        } 

        return openState 
            ? <KeyboardArrowDownIcon onClick={() => handleToggle()} />
            : <ChevronRightIcon onClick={() => handleToggle()} />
    }

    const getLabel = () => {
        let label = '';

        if (labelPrefix)
            label += labelPrefix + ' ';

        if (node?.name)
            label += node.name;

        return label;
    }

    return (
        <div className={classes.momentsTreeNodeRoot} style={{ paddingLeft: (level * 12) }}>
            <Typography className={classnames(classes.momentsTreeNode, isSelected ? classes.selectedNode : '')} onClick={() => onSelect(node)}>
                {getIcon()}
                {getLabel()}
            </Typography>
            
            {openState &&
                children
            }
        </div>
    );
}


const FormTree = props => {
    const { currentForm, selectedItem, onSelectEditItem } = props;

    const classes = useStyles();

    const [ selectedNode, setSelectedNode ] = useState(selectedItem);

    if (!currentForm)
        return <div className={classes.bottomBox}>Inget moment valt...</div>
    
    const handleSelectNode = (node) => {
        setSelectedNode(node);
        onSelectEditItem(node);
    }

    return (
        <div className={classes.bottomBox}>
            <FormsTreeNode key={currentForm.id} node={currentForm} level={0} isOpen={true} isSelected={selectedNode === currentForm || !selectedNode} onSelect={handleSelectNode}>
                {currentForm.tasks && currentForm.tasks.map(task =>
                    <FormsTreeNode key={task.id} node={task} level={1} isOpen={false} isSelected={selectedNode === task} onSelect={handleSelectNode}>
                        {task.subTasks.sort((a, b) => (a.sortIndex > b.sortIndex) ? 1 : -1).map(subTask =>
                            <FormsTreeNode key={subTask.id} node={subTask} level={2} isOpen={false} isSelected={selectedNode === subTask} onSelect={handleSelectNode}>
                                { subTask.options.map(subTaskOption => 
                                    <FormsTreeNode key={subTaskOption.id} node={subTaskOption} level={3} labelPrefix={'Val:'} isSelected={selectedNode === subTaskOption} onSelect={handleSelectNode} />
                                )}
                                { subTask.approveBehaviour && 
                                    <FormsTreeNode node={subTask.approveBehaviour} level={3} labelPrefix={'Regler'} isSelected={selectedNode === subTask.approveBehaviour} onSelect={handleSelectNode} />
                                }
                            </FormsTreeNode>
                        )}
                    </FormsTreeNode>
                )}
            </FormsTreeNode>
        </div>
    )
}

export default FormTree;