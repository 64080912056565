import React, {
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react';

import { useSelector } from 'react-redux';
import {
    useRefresh,
    useNotify,
    useQueryWithStore,
    useQuery,
    useDataProvider,
    Loading,
    Error,
} from 'react-admin';

import {
    Grid,
    makeStyles,
    Switch,
    FormGroup,
    FormControlLabel,
    Dialog,
    DialogContent,
} from '@material-ui/core';

import { Gantt, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";

import { TimeplanGanttHeader } from './ProjectPlanning/TimeplanGanttHeader';
import { TimeplanGanttTooltip } from './ProjectPlanning/TimeplanGanttTooltip';
import { TimeplanGanttTable } from './ProjectPlanning/TimeplanGanttTable';
import AddTaskButton from './ProjectPlanning/AddTaskButton';
import TaskEditor from './ProjectPlanning/TaskEditor';
import ViewSwitcher from './ProjectPlanning/TimeplanViewSwitcher';
import HelpButton from '../shared/buttons/HelpButton';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '80vw',
    },
    ganttContainer: {
        maxWidth: '100%',
    },
}));

// TODO: Gantt chart
    // Dynamisk tidsaxel upptill (beroende på dataset (uppgifters start/slut))
    // Drag and drop för att flytta uppgift i tid
    // Drag för att förlänga/korta ned uppgifts tid
    // Spara uppgift efter drag

    // Kandidater
        // React-Google-Charts Gantt (https://www.react-google-charts.com/examples/gantt) : + Snabb, smidig, gratis. - Begränsad interaktivitet...?
        // Bryntum Gantt (https://www.bryntum.com/products/gantt/) : + Avancerad (?), svensk, support (?). - Dyr ($1500 för en utvecklarlicens), Onödigt komplex (?)
        // MaTeMaTuk gantt-task-react (https://github.com/MaTeMaTuK/gantt-task-react) : + Open source, lagom komplex (?). - Open source, ofärdig @ v. 0.37 oct 2021 (?)
        // Syncfusion react gantt (https://www.syncfusion.com/react-ui-components/react-gantt-chart) : + verkar kompetent (?). - Syncfusion, kostar $995 (per developer 1st year (65+ js/react/angular/vue-komponenter)), krångligt ekosystem (?)
        // (https://github.com/neuronetio/gantt-schedule-timeline-calendar) : + komplex/kompetent (?). - otydlig licens (?), onödigt komplex (?)

const ControlBar = props => {
    const { isProjectTimeplanActive, onChangeTimeplanActive, onNewTaskClicked, disabled, canAddTimeplanItems } = props;

    return (
        <>
            <FormGroup row>
                <FormControlLabel 
                    control={<Switch checked={isProjectTimeplanActive} onChange={onChangeTimeplanActive} name="timeplanActiveCheck" disabled={disabled} />}
                    label="Tidsplan aktiv?"
                />
                <HelpButton textKey={'weavra.help.timePlanActive'} />
            </FormGroup>
            { isProjectTimeplanActive && canAddTimeplanItems &&
                <FormGroup row>
                    <AddTaskButton onClick={onNewTaskClicked}/>    
                </FormGroup>
            }

        </>
    );
}

const ProjectTasksTimeplan = props => {
    const classes = useStyles();

    const [saving, setSaving] = useState(false);
    const [tasks, setTasks] = useState();
    const [timeplanActive, setTimeplanActive] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [editTask, setEditTask] = useState();
    const [view, setView] = useState(ViewMode.Day);
    const [isChecked, setIsChecked] = useState(true);

    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    // Ladda projekt; id från redux-state
    const projectId = useSelector(state => state.workspace.id); // TODO: Handle null?

    // Hämta projektdata från backend
    const { data:projectData, loaded:isProjectLoaded, error:projectError } = useQuery({
        type: 'getOne',
        resource: 'project/timeplan',
        payload: { id: projectId }
    });

    // Hämta företag 
    const { data:companiesData, loaded:isCompaniesLoaded, error:companiesError } = useQueryWithStore({
        type: 'getList',
        resource: 'general/companies',
        payload: {
            pagination: { page: 1, perPage: 999 },
            sort: { field: 'name', order: 'ASC' }
        }

        // TODO: Filter? Endast aktiva? Endast medlemsföretag för snabbare filtrering nedan?
    });

    // Hämta rättigheter
    const { data:permissionsData, loaded:isPermissionsLoaded, error: permissionsError } = useQuery({
        type: 'getOne',
        resource: 'general/projects',
        payload: { id: projectId, getPermissions: true, getMembersPermissions: false }
    });

    useEffect(() => {
        //Convert project moments/tasks to gantt-tasks
        if (!projectData || !projectData.projectMoments || !permissionsData) {
            setTasks(null);
            return;
        }

        const filteredSortedProjectMoments = projectData.projectMoments
            .filter(m => m.momentType === 1 && m.startTime && m.endTime)
            .sort((a, b) => new Date(a.created) - new Date(b.created));
        
        setTasks(filteredSortedProjectMoments
            .map((m, index) => ({
                start: new Date(m.startTime),
                end: new Date(m.endTime),
                name: m.name,
                id: m.id,
                type: 'task',
                progress: (m.tasks && m.tasks.length > 0
                    ? (100 * m.tasks.filter(t => t.completed).length) / m.tasks.length
                    : 0
                ), 
                isDisabled: !timeplanActive || m.completed || !permissionsData.canEditTimeplanItems, 
                project: m.ownerName, // Note the hack: we hold task owner here instead of project (since all tasks belong to same project)
                displayOrder: index,
            }))
        );
        
        //Is timeplan active?
        setTimeplanActive(projectData.timeplanActive);

    }, [permissionsData, projectData, timeplanActive]);

    const permittedToEdit = useMemo(() => {
        if (!permissionsData || !editTask || !timeplanActive)
            return false;
        
        if (permissionsData.canEditTimeplanItems)
            return true;
        
    }, [editTask, permissionsData, timeplanActive]);

    const permittedToFinish = useMemo(() => {
        if (!permissionsData || !editTask || !timeplanActive)
            return false;
        
        if (permissionsData.canEditTimeplanItems)
            return true;
        
        return editTask.ownerId === permissionsData.companyId
    }, [editTask, permissionsData, timeplanActive]);

    const handleSaveTask = useCallback((task) => {
        // TODO: Move this logic into TaskEditor?
        setSaving(true);

        if (!task.id) {
            dataProvider
                .create('general/projectmoments', { data: task })
                .then(response => {
                    setSaving(false);
                    notify('weavra.notification.taskSaved', 'info');
                    setShowEditor(false);
                    // TODO: Add optimistically to model, only refresh on error?
                    refresh();
                })
                .catch(error => {
                    setSaving(false);
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    refresh();
                });
            
            setSaving(false);
            return;
        }

        // Times could be changed in gantt-chart so update from there
        const ganttTask = tasks.find(t => t.id === task.id);
        const newLength = ganttTask ? Math.round((ganttTask.end.getTime() - ganttTask.start.getTime()) / (1000 * 60 * 60 * 24)) : task.length;
        const newStart = ganttTask ? new Date(ganttTask.start.toDateString()) : task.startTime;

        dataProvider
            .update('general/projectmoments', { id: task.id, data: {...task, length: newLength, startTime: newStart } })
            .then(response => {
                setSaving(false);
                notify('weavra.notification.taskSaved', 'info');
                setShowEditor(false);
                refresh();
            })
            .catch(error => {
                setSaving(false);
                console.log('Fel fel fel', error, saving);
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                refresh();
            });

        setSaving(false);
    }, [dataProvider, notify, refresh, saving, tasks]);

    const handleGanttUpdateTask = (task) => {
        setSaving(true);

        const projectTask = projectData.projectMoments.find(m => m.id === task.id);

        if (!projectTask) {
            console.error('Fel fel fel! Ingen projektuppgift...', task);
            return;
        }

        const newLength = Math.round((task.end.getTime() - task.start.getTime()) / (1000 * 60 * 60 * 24));
        const newStart = new Date(task.start.toDateString());

        dataProvider
            .update('general/projectmoments', { id: task.id, data: {...projectTask, length: newLength, startTime: newStart} })
            .then(response => {
                setSaving(false);
                notify('weavra.notification.taskSaved', 'info');
                return;
            })
            .catch(error => {
                setSaving(false);
                console.error('Fel fel fel!', error);
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                refresh();
                return;
            });
        
        setSaving(false);
    }

    const handleTaskDelete = useCallback((task) => {
        setSaving(true);
        dataProvider
            .delete('general/projectmoments', { id: task.id })
            .then(response => {
                setSaving(false);
                setShowEditor(false);
                refresh();
                // TODO: Uppdatera vyn utan refresh?
            })
            .catch(error => {
                setSaving(false);
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                console.log('Fel fel fel', error, saving);
                //refresh();
            })
    }, [dataProvider, notify, refresh, saving]);

    const taskSaveContext = useMemo(() => ({
        save: handleSaveTask,
        saving
    }), [saving, handleSaveTask]);

    if (!isProjectLoaded || !isCompaniesLoaded || !isPermissionsLoaded) { 
        return <Loading />
    }

    if (projectError || companiesError || permissionsError) {
        return <Error />
    }

    const handleTaskChange = (task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t))); 
        handleGanttUpdateTask(task);
    };

    const handleSelect = (task) => { 
        // TODO: Remove?
    };

    const handleExpanderClick = (task) => {
        // TODO: Remove?
    };

    const handleDoubleClick = (task) => {
        const projectTask = projectData.projectMoments.find(m => m.id === task.id);

        if (projectTask) {
            setEditTask(projectTask);
            setShowEditor(true);
        } else {
            // TODO: Notify? Log?
        }  
    }

    const handleNewTaskClicked = () => {

        const defaultStartTime = projectData.startDate ?? new Date(new Date().getFullYear, 0, 1);
        const newTask = {
            name: 'Ny uppgift',
            projectId: projectData.id,
            startTime: defaultStartTime,
            length: 1,
            createSimplifiedChildren: true,
            momentType: 1,
        };

        setEditTask(newTask);
        setShowEditor(true);
    }

    const handleClose = () => {
        setShowEditor(false);
    }

    const handleSetTimeplanActive = (event) => {
        
        setSaving(true);
        setTimeplanActive(event.target.checked);

        projectData.timeplanActive = event.target.checked;

        dataProvider
            .update('project/timeplan', { id: projectData.id, data: { setTimeplanActive: true, timeplanActive: projectData.timeplanActive } })
            .then(response => {
                notify(event.target.checked ? 'weavra.notification.timeplanActivated' : 'weavra.notification.timeplanInactivated', 'info');
            })
            .catch(error => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                setSaving(false);
                refresh();
            })
        
        setSaving(false);
    };

    let timeStep = (1000 * 60 * 60 * 24);
    let columnWidth = 24;
    if (view === ViewMode.Month) {
        columnWidth = 96;
    }
    else if (view === ViewMode.Week) {
        columnWidth = 72;
    }

    const memberCompanies = [];
    for (let i = 0; i < projectData.memberCompanies.length; i++) {
        const company = companiesData.find(c => c.id === projectData.memberCompanies[i]);
        if (company)
            memberCompanies.push(company);
    }

    const CustomGanttTable = props => {
        return (
            <TimeplanGanttTable onMomentClick={handleDoubleClick} {...props} />
        );
    }

    return (
        <>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <ControlBar
                        onChangeTimeplanActive={handleSetTimeplanActive}
                        isProjectTimeplanActive={timeplanActive}
                        onNewTaskClicked={handleNewTaskClicked}
                        disabled={!permissionsData.canToggleTimeplanActive}
                        canAddTimeplanItems={permissionsData.canAddTimeplanItems}
                    />
                </Grid>
                { (timeplanActive || permissionsData.canAddTimeplanItems) && 
                    <>
                        <Grid item xs={12}>
                            <ViewSwitcher
                                onViewModeChange={viewMode => setView(viewMode)}
                                onViewListChange={setIsChecked}
                                isChecked={isChecked}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.ganttContainer}>
                        { tasks && tasks.length > 0 &&
                            <Gantt
                                tasks={tasks}
                                onDateChange={handleTaskChange}
                                onSelect={handleSelect}
                                onExpanderClick={handleExpanderClick}
                                onDoubleClick={handleDoubleClick}
                                locale="sv-SE"
                                viewMode={view}
                                TaskListHeader={TimeplanGanttHeader}
                                TooltipContent={TimeplanGanttTooltip}
                                TaskListTable={CustomGanttTable}
                                headerHeight={60}
                                rowHeight={42}
                                columnWidth={columnWidth}
                                fontFamily="Work Sans"
                                barBackgroundColor="#253746"
                                barBackgroundSelectedColor="#A7A8AA"
                                timeStep={timeStep}
                                listCellWidth={isChecked ? "155px" : ""}
                            />
                        }
                        </Grid>
                    </>
                }
            </Grid>

            <Dialog
                open={showEditor}
                onClose={handleClose}
                fullWidth
            >
                <DialogContent>
                    {editTask &&
                        <TaskEditor
                            task={editTask}
                            project={projectData} 
                            saveContext={taskSaveContext}
                            onSave={handleSaveTask}
                            onDelete={handleTaskDelete}
                            onCancel={handleClose}
                            isSaving={saving}
                            canEditOwner={true}
                            canEditDate={true}
                            canEditDependencies={false}
                            companies={memberCompanies}
                            canDelete={timeplanActive && permissionsData.canDeleteTimeplanItems}
                            disabled={editTask.isCompleted || !permittedToEdit}
                            canFinish={permittedToEdit || permittedToFinish}
                        />
                    }
                </DialogContent>
            </Dialog>
        </>
    );
}

export default ProjectTasksTimeplan;