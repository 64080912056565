import React, { Fragment, useState, useMemo } from 'react';
import { useSelector } from "react-redux";
import { Form } from 'react-final-form';
import {
    Button,
    List,
    SimpleForm,
    TextInput,
    FileInput,
    DateField,
    TextField,
    ImageField,
    FileField,
    SelectInput,
    useNotify,
    useRefresh,
    useTranslate,
    useDataProvider,
    useCreate,
    useUpdate,
    useListContext,
    useQuery,
} from 'react-admin';

import { 
    Grid, 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    Card,
    CardContent,
    Typography,
    makeStyles,
} from '@material-ui/core';

import { Add, ReportProblem, Info, Work, HourglassEmpty, Build, HelpOutline, FilterList, ArrowUpward, ArrowDownward } from '@material-ui/icons';

import QuickDialogActions from '../shared/QuickDialogActions';
import DateTimeOptions from '../shared/DateTimeOptions';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    topButton: {
        marginRight: theme.spacing(3),
    },
    cardList: {
        display: 'flex',
        margin: theme.spacing(1),
        flexDirection: 'column',
    },
    eventCard: {
        marginBottom: theme.spacing(0.5),
    },
    eventTypeIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex',
    },
    sortTextButton: {
        verticalAlign: 'middle',
        display: 'inline-flex',
        cursor: 'pointer',
    },
    filterWrapper: {
        margin: theme.spacing(1),
        width: '100%',
    },
    clickableArea: {
        cursor: 'pointer',
        transition: 'background 0.5s',

        "&:hover": {
            transition: 'background 0.5s',
            background: theme.palette.background.default,
        },
    },
}));

const AddNewEventButton = props => {
    const { onClick } = props;
    const classes = useStyles();

    return (
        <Button
            label="Ny händelserapport"
            variant="contained"
            color="primary"
            size="large"
            onClick={onClick}
            className={classes.topButton}
        >
            <Add fontSize="large" />
        </Button>
    )
}

// TODO: Tillfälliga ikoner, kolla med kund vad som önskas...
const IncidentTypes = [
    { id: 'Tillbud', name: 'Tillbud', icon: ReportProblem },
    { id: 'Olycka', name: 'Olycka', icon: ReportProblem  },
    { id: 'Information', name: 'Information', icon: Info },
    { id: 'Arbetsmiljö', name: 'Arbetsmiljö', icon: Work },
    { id: 'Väntetid', name: 'Väntetid', icon: HourglassEmpty },
    { id: 'Extra arbete', name: 'Extra arbete', icon: Add },
    { id: 'ÄTA', name: 'ÄTA', icon: Build }
];

const EventQuickCreate = ({ initialValues, onSave, onCancel, isLoading }) => {

    return (
        <SimpleForm 
            name="event-quick-create"
            resource="general/projectincidentreports"
            initialValues={initialValues}
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="title" label="Rubrik" />
            <SelectInput source="incidentType" label="Kategori" choices={IncidentTypes} />
            <TextInput source="message" label="Meddelande" multiline rows={3} />

            <FileInput source="fileData" label="weavra.companyUsers.fileData" multiple={false} accept="image/*">
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    );
}

const EventQuickEdit = ({ record, onSave, onCancel, onDelete, isLoading, readonly }) => {

    return (
        <SimpleForm
        name="event-quick-update"
        resource="general/projectincidentreports"
        record={record}
        save={onSave}
        toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} enableDelete={!readonly} record={record} onDelete={onDelete} disabled={readonly} />}
        >
            <TextInput source="title" label="Rubrik" disabled={readonly} />
            <SelectInput source="incidentType" label="Kategori" choices={IncidentTypes} disabled={readonly} />
            <TextInput source="message" label="Meddelande" multiline rows={3} disabled={readonly} />
            <DateField source="created" label="Skapad" showTime options={DateTimeOptions} locales="sv-SE" />
            <TextField source="createdByUserName" label="Skapad av" />
            <TextField source="createdByCompanyName" label="Företag" />
            <ImageField source="thumbUrl" label="" />
        </SimpleForm>
    );
}

const ProjectEventsCardList = props => {
    const { ids, data } = useListContext();
    const classes = useStyles();
    const { onEventClicked } = props;

    // TODO: Style

    return (
        <div className={classes.cardList}>
            {ids.map(id => 
                <ProjectEventCard key={id} record={data[id]} onEventClicked={onEventClicked} />
            )}
        </div>
    )
}

const ProjectEventCard = props => {
    const { record, onEventClicked } = props;
    const classes = useStyles();

    // TODO: Style... 
        // - minska space runt allt
        // - custom css (ej använda card och grid?)
    
    const headerVariant = "subtitle1";
    const descriptionVariant = "body2";

    return (
        <Card className={classes.eventCard}>
            <CardContent className={classes.clickableArea}>
                <Grid container>
                    <Grid item xs={2}>
                        { record.file &&
                            <a href={record.file.url} rel="noreferrer" target="_blank">
                                <img src={record.file.thumbUrl} alt="" />
                            </a>  
                        }
                    </Grid>
                    <Grid container item xs={10} onClick={() => onEventClicked(record)}>
                        <Grid container item xs={12}>
                            <Grid item xs={4}>
                                <EventTypeField record={record} variant={headerVariant} />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant={headerVariant}>{record.createdByCompanyName}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <DateField variant={headerVariant} record={record} source="created" showTime options={DateTimeOptions} locales="sv-SE" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant={descriptionVariant} record={record} source="message" />
                        </Grid>
                    </Grid> 
                </Grid>
            </CardContent>
        </Card>
    );
}

const EventTypeField = props => {
    const { record, variant } = props;
    const classes = useStyles();
    const typeData = IncidentTypes.find(x => x.id === record.incidentType || x.name === record.incidentType);
    let typeName = typeData?.name ?? record.incidentType;
    //typeName += ` : "${record.title}"`; // TODO: Enable for incidents created by admin?

    return (
        <span>
            <Typography variant={variant}>
            { typeData && typeData.icon ? <typeData.icon className={classes.eventTypeIcon}/> : <HelpOutline className={classes.eventTypeIcon} />}
            &nbsp;{typeName}
            </Typography>
        </span>
    )
}

const EventsFilter = props => {
    const classes = useStyles();
    const { 
        filterValues, 
        setFilters, 
        currentSort,
        setSort,
        ids,
        data, 
    } = useListContext();

    if (props.context === 'button')
        return <div></div>

    const onSubmit = values => {
        setFilters(values);
    }

    const dateSortToggle = () => {
        setSort(currentSort.field, currentSort.order === 'DESC' ? null : 'DESC');
    }

    const companies = [];
    ids.forEach(id => {
        const x = data[id];
        if (!companies.some(c => c.name === x.createdByCompanyName))
            companies.push({ id: x.creatorCompanyId, name: x.createdByCompanyName });
    })

    return (
        <div className={classes.filterWrapper}>
            <Form onSubmit={onSubmit} initialValues={filterValues}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Card>
                            <CardContent>
                                <Grid container>
                                    <Grid item xs={2}>
                                        <FilterList fontSize="large" />
                                    </Grid>
                                    <Grid container item xs={10}>
                                        <Grid item xs={4}>
                                            <SelectInput
                                                label="Kategori"
                                                source="incidentType"
                                                choices={IncidentTypes}
                                                allowEmpty={true}
                                                emptyText="Alla"
                                                emptyValue=""
                                                onChange={handleSubmit}
                                                resettable     
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <SelectInput
                                                label="Företag"
                                                source="creatorCompanyId"
                                                choices={companies}
                                                allowEmpty={true}
                                                emptyText="Alla"
                                                emptyValue={null}
                                                onChange={handleSubmit}
                                                resettable
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography onClick={dateSortToggle} className={classes.sortTextButton}>Sortera
                                            { currentSort.order === 'DESC' 
                                                ? <ArrowDownward />
                                                : <ArrowUpward />
                                            }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </form>
                )}
            </Form>
        </div>

    );
}

const EmptyEventList = () => {
    return (
        <div></div>
    )
}

const ProjectEvents = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [shownDialog, setShownDialog] = useState(undefined);
    const [selectedEvent, setSelectedEvent] = useState(undefined);

    const notify = useNotify();
    const translate = useTranslate();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const [createEvent] = useCreate('general/projectincidentreports');
    const [updateEvent] = useUpdate('general/projectincidentreports');

    // Ladda projekt-Id från redux-state
    const projectId = useSelector(state => state.workspace.id);

    // Hämta rättigheter
    const { data:permissionsData, loaded:isPermissionsLoaded, error: permissionsError } = useQuery({
        type: 'getOne',
        resource: 'general/projects',
        payload: { id: projectId, getPermissions: true, getMembersPermissions: false }
    }); 

    const permittedToEdit = useMemo(() => {
        if (!permissionsData || !selectedEvent)
            return false;
        
        if (permissionsData.canEditProjectIncidentReportsByOthers)
            return true;
        
        return selectedEvent.creatorCompanyId === permissionsData.companyId;
    }, [selectedEvent, permissionsData]);

    if (!isPermissionsLoaded)
        return <div>Laddar...</div>

    if (!projectId || permissionsError)
        return <div>Något blev fel...</div>
    

    const handleClose = () => {
        setShownDialog(null);
    }

    const handleNewEventClicked = () => {
        setShownDialog('eventCreate');
    }

    const handleEventClicked = (record) => {
        setSelectedEvent(record);
        setShownDialog('eventEdit');
    }

    const handleCreateEventSubmit = async values => {
        setIsLoading(true);
        createEvent(
            { payload: { data: { ...values, useForm: true } } },
            {
                onSuccess: () => {
                    handleClose();
                    notify('ra.notification.created', 'info');
                    refresh();
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }
    
    const handleEditEventSave = async values => { 
        setIsLoading(true);
        updateEvent(
            { payload: { id: values.id, data: { ...values } } },
            {
                onSuccess: () => {
                    handleClose();
                    notify('ra.notification.updated', 'info', { smart_count: 1 });
                    refresh();
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }
    
    const handleEventDelete = (record) => {
        const confirmMessage =  translate("ra.message.delete_content"); // TODO: Custom message
        if (window.confirm(confirmMessage)) {
            setIsLoading(true);
            dataProvider.delete('general/projectincidentreports',
                { id: record.id }
            ).then(() => {
                handleClose();
                notify('ra.notification.deleted', 'info', { smart_count: 1 });
                refresh();
            })
            .catch(error => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
            });

            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {permissionsData.canAddProjectIncidentReports && <AddNewEventButton onClick={handleNewEventClicked} />}
                </Grid>
                <Grid item xs={12}>
                    <List
                        {...props} 
                        title="Händelser"
                        filters={<EventsFilter />} 
                        filter={{ projectId: projectId }}
                        sort={{ field: 'created', order: 'DESC' }}
                        bulkActionButtons={false}
                        exporter={false}
                        pagination={false}
                        perPage={9999}
                        component="div"
                        empty={<EmptyEventList />}
                    >
                        <ProjectEventsCardList onEventClicked={handleEventClicked} />
                    </List>
                </Grid>
            </Grid>

            <Dialog open={shownDialog === 'eventCreate'} onClose={handleClose} fullWidth>
                <DialogTitle>Ny Händelserapport</DialogTitle>
                <DialogContent>
                    <EventQuickCreate onSave={handleCreateEventSubmit} onCancel={handleClose} isLoading={isLoading} initialValues={{ projectId: projectId }} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'eventEdit'} onClose={handleClose} fullWidth>
                <DialogTitle>Redigera Händelserapport</DialogTitle>
                <DialogContent>
                    <EventQuickEdit record={selectedEvent} onSave={handleEditEventSave} onCancel={handleClose} isLoading={isLoading} onDelete={handleEventDelete} readonly={!permittedToEdit} />
                </DialogContent>
            </Dialog>
        </Fragment>
    );
}

export default ProjectEvents;