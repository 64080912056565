import React from "react";
import {
    SimpleForm,
    TextInput,
    BooleanInput,
    Button,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin';

import { Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import EditorToolBar from "./EditorToolBar";

const FormEditor = props => {
    const { record, saveContext, onAddNewTask } = props;

    return (
        <>
            <Typography>Redigera formulär - {record.name}</Typography>
                <SimpleForm 
                    name="form-edit"
                    resource="superadmin/taskforms"
                    record={record}
                    save={saveContext.save}
                    saving={saveContext.saving}
                    toolbar={<EditorToolBar />}
                    redirect={false}>
                    <TextInput source="name" label="Namn" />
                    <TextInput multiline source="description" label="Beskrivning" />
                    <BooleanInput source="active" label="Aktiv?" />
                    <BooleanInput source="isGlobal" label="weavra.superAdmin.isFormGlobal" />
                
                    <ReferenceArrayInput
                        allowEmpty
                        label="weavra.superAdmin.availableToCompanies"
                        source="companies"
                        reference="general/companies"
                        filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }}>
                        <SelectArrayInput optionText="name" />
                    </ReferenceArrayInput>
                
                    <label>Uppgifter</label>
                    <ul>
                        { record.tasks.map(task =>
                            <li key={task.id}><Typography>{task.name}</Typography></li>
                        )}
                    </ul>
                    <Button         
                        label="Lägg till uppgift"
                        variant="text"
                        size="small"
                        onClick={() => onAddNewTask(record)}>
                        <AddCircleIcon />
                    </Button>
                </SimpleForm>
        </>
    );
}

export default FormEditor;