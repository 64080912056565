import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const Map = props => {
    const { center, onMapClick, markers, defaultZoom, apiKey, containerStyle } = props;

    //const [map, setMap] = React.useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
    });

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        //map.fitBounds(bounds);
        map.setCenter(bounds.getCenter());
        map.setZoom(defaultZoom);
        //setMap(map)
    }, [center, defaultZoom]);

    const onUnmount = React.useCallback(function callback(map) {
        //setMap(null)
    }, []);

    const putMarkers = () => {
        if (!markers) return;

        if (markers instanceof Array) {
            return markers.map((mrk, i) => (
                <Marker
                    key={`mark${i}`}
                    position={mrk}
                />
            ));
        }

        return (
            <Marker
                key="mrk"
                position={markers}
            />
        );
    }

    // if (isLoaded) {
    //     console.log('Inner map props:', props);
    // }

    //console.log('Map is rendering...');

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={defaultZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={e => onMapClick(e)}
        >
            { /* Child components, such as markers, info windows, etc. */}
            { putMarkers() }
        </GoogleMap>
    ) : <></>;
}

export default React.memo(Map);