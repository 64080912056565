import * as React from 'react';
import { useSelector } from "react-redux";
import { Title, useShowController, SimpleShowLayout, TextField } from 'react-admin';
import { Card, CardContent } from '@material-ui/core';

const MyCompanyDetails = props => {
    //const { companyId, resource, basePath } = props;
    const controllerProps = useShowController({ id: props.companyId, ...props });

    // const {
    //     basePath, // deduced from the location, useful for action buttons
    //     //defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    //     loaded, // boolean that is false until the record is available
    //     //loading, // boolean that is true on mount, and false once the record was fetched
    //     record, // record fetched via dataProvider.getOne() based on the id from the location
    //     resource, // the resource name, deduced from the location. e.g. 'posts'
    //     version, // integer used by the refresh feature
    // } = controllerProps;

    return (
        <SimpleShowLayout {...controllerProps}>
            <TextField source="name" label="Namn" />
            <TextField source="legalRegistrationNumber" label="Org.Nr." />
            <TextField source="phone" label="Telefon" />
        </SimpleShowLayout>
    )
}

const CompanyDashboard = (props) => {
    const companyId = useSelector(state => state.workspace.id);
    
    return (
        <Card variant="outlined">
            <Title title="Mitt företag" />
            <CardContent>
                {companyId &&
                    <MyCompanyDetails companyId={companyId} resource="general/companies" basePath="company" />
                }
            </CardContent>
        </Card>
    )
};

export default CompanyDashboard;
