import * as React from 'react';
import { useCallback } from 'react';
import {
    Button
} from 'react-admin';
import {
    //fetchRelatedRecords,
    useDataProvider,
    useNotify,
    useListContext,
    // SortPayload,
    // Exporter,
    // FilterPayload,
    useResourceContext,
} from 'ra-core';
import DownloadIcon from '@material-ui/icons/GetApp';

const defaultIcon = <DownloadIcon />;

const LedgerExportButton = props => {
    const {
        maxResults = 9999,
        onClick,
        label = 'ra.action.export',
        icon = defaultIcon,
        exporter: customExporter,
        ledgerInfo,
        filter,
        filterValues,
        sort,
        //...rest
    } = props;

    const {
        //filter,
        //filterValues,
        //sort,
        exporter: exporterFromContext,
        total,
    } = useListContext(props);

    const resource = useResourceContext(props);
    const exporter = customExporter || exporterFromContext;
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClick = useCallback(
        event => {

            console.log('DEBUG: ', props);

            dataProvider
                .getList(resource, {
                    sort,
                    filter: filter
                        ? { ...filterValues, ...filter }
                        : filterValues,
                    pagination: { page: 1, perPage: maxResults },
                })
                .then(
                    ({ data }) =>
                        exporter &&
                        exporter(
                            data,
                            ledgerInfo
                        )
                )
                .catch(error => {
                    console.error(error);
                    notify('ra.notification.http_error', { type: 'warning' });
                });
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [props, dataProvider, resource, sort, filter, filterValues, maxResults, onClick, exporter, ledgerInfo, notify]
    );

    return (
        <Button
            onClick={handleClick}
            label={label}
            disabled={total === 0}
            //{...sanitizeRestProps(rest)}
        >
            {icon}
        </Button>
    );
}

export default LedgerExportButton;