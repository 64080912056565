import * as React from 'react';
import {
    SimpleForm,
    TextInput,
} from 'react-admin'
import QuickDialogActions from '../../shared/QuickDialogActions';

const CompanyQuickEdit = ({ onSave, onCancel, isLoading, company }) => {
    return (
        <SimpleForm 
            name="company-quick-create"
            resource="superadmin/companies"
            record={company}
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="name" label="weavra.company.name"/>
            <TextInput source="phone" label="Telefon" />
            <TextInput source="legalRegistrationNumber" label="weavra.company.legalRegistrationNumber" placeholder='xxxxxxxxxx' maxLength='10' />

            {
                // TODO: Toggle active?
            }

        </SimpleForm>
    );
}

export default CompanyQuickEdit;