import { useLogout } from 'react-admin';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrap: props => ({
      cursor: 'pointer',
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      padding: theme.spacing(2),
      boxSizing: 'border-box',
      background: 'transparent',
      transition: 'background 0.5s',
      borderRadius: props.big ? '4px' : 0,

      "&:hover": {
        transition: 'background 0.5s',
        background: theme.palette.background.default,
      },

      "& svg": {
        marginRight: props.big ? theme.spacing(2) : theme.spacing(1)
      }
  })
}))

const LogoutButton = props => {
    const dispatch = useDispatch();
    const logout = useLogout();

    const onLogoutClick = (e) => {
      dispatch({ type: 'SET_USER', payload: { name: '', companyName: '', companyId: '', id: '' }});
      dispatch({ type: 'SET_WORKSPACE', payload: { type: '', id: '', name: ''}});
      logout();
    }

    const classes = useStyles(props);
    const className = [classes.wrap];
    if (props.className) className.push(props.className);
    const variant = props.big ? 'h5' : 'subtitle1';

    return (
      <div className={className.join(' ')} onClick={onLogoutClick} title="Utloggning">
        <PowerIcon />
        <Typography variant={variant}>
            Logga ut
        </Typography>
      </div>
    );
}

export default LogoutButton;
