import * as React from "react";
import { useLocation } from 'react-router';
import PropTypes from "prop-types";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import lodashGet from "lodash/get";
import inflection from "inflection";
import { useMediaQuery, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DefaultIcon from "@material-ui/icons/ViewList";
import { Person, Business, Work } from '@material-ui/icons';
import classnames from "classnames";
import {
  getResources,
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  usePermissions,
} from "react-admin";

// import HelpButton from "./buttons/HelpButton";

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      marginTop: "0.5em",
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
      },
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(2),
      },
    },
    context: {
      cursor: "pointer",
      background: theme.palette.background.paper,
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textAlign: "center",

      "svg": {
        marginBottom: theme.spacing(1),
      },
    },
    open: {
      width: lodashGet(theme, "menu.width", MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, "menu.closedWidth", CLOSED_MENU_WIDTH),
    },
    topLink: {
      color: theme.palette.primary.main,
    },
    topLinkActive: {
      color: theme.palette.success.main,
    },
    menuItemLink: {
      '&[aria-current]:not([aria-current="false"])': {
        color: theme.palette.success.main,
      },
    },
  }),
  { name: "RaMenu" }
);

const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label,
          })
        : inflection.humanize(inflection.pluralize(resource.name)),
  });

const permissionGranted = (resource, permissions) => {
  //console.debug(`Checking permissions for resource ${resource.name}, has showFor = ${resource.options.showFor ? 'yes' : 'no'}`, resource, permissions);
  return resource.options && resource.options.showFor && resource.options.showFor.length > 0
    ? resource.options.showFor.some(r => permissions.indexOf(r) > 0)
    : true;
}

const Menu = (props) => {
  const {
    classes: classesOverride,
    className,
    dense,
    hasDashboard,
    onMenuClick,
    logout,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const translate = useTranslate();
  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources, shallowEqual);
  const currentWorkspaceId = useSelector(state => state.workspace.id);
  const currentWorkspaceName = useSelector(state => state.workspace.name);
  const currentWorkspaceType = useSelector((state) => state.workspace.type);
  const { loaded, permissions } = usePermissions();
  const location = useLocation();

  let icon;
  // let helpTextKey;
  switch (currentWorkspaceType) {
    case "user":
      icon = <Person />;
      // helpTextKey = 'weavra.help.myPage';
      break;
    case "company":
      icon = <Business />;
      // helpTextKey = 'weavra.help.companyPage2';
      break;
    case "project":
      icon = <Work />;
      // helpTextKey = 'weavra.help.projectPage';
      break;
    default:
  }

  const topLinkClass = location.pathname === `/${currentWorkspaceType}` ? classes.topLinkActive : classes.topLink;

  const onWorkspaceClick = () => {
    dispatch({ type: 'SET_WORKSPACE', payload: { type: currentWorkspaceType, id: currentWorkspaceId, name: currentWorkspaceName } });
  };

  return loaded ? (
    <div
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      <Card className={classes.context}>
        <CardContent onClick={onWorkspaceClick}>
          {icon}
          <Typography variant="subtitle1" className={topLinkClass}>{currentWorkspaceName}</Typography>
        </CardContent>
      </Card>
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {resources
        .filter((r) => r.hasList)
        .map((resource) => permissionGranted(resource, permissions) ? (
          <MenuItemLink
            key={resource.name}
            className={classes.menuItemLink}
            to={{
              pathname: `/${resource.name}`,
              state: { _scrollToTop: true },
            }}
            primaryText={translatedResourceName(resource, translate)}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        ) : null
        )}
      {isXSmall && logout}
    </div>
  ) : null;
};

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: () => null,
};

export default Menu;
