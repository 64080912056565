import * as React from 'react';
import { 
    Button,
    useTranslate
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(() => ({
    table: {
        padding: '12px',
        borderCollapse: 'collapse',
    },
    headerRow: {
        borderBottom: '1px solid #ddd',
    },
    headerCell: {
        padding: '6px',
    },
    bodyRow: {
        borderBottom: '1px solid #ddd',
    },
    bodyCell: {
        padding: '6px',
    },
}));

const SuperAdminCompanyList = (props) => {
    const { companies, companyIds, onEditCompanyClicked } = props;

    const translate = useTranslate();
    const classes = useStyles();

    if (!companies || companies.length === 0)
        return <div><p>Inga företag hittades...</p></div>

    return (
        <div>
            <table className={classes.table}>
                <thead>
                    <tr className={classes.headerRow}>
                        <td><h4 className={classes.headerCell}>Namn</h4></td>
                        <td><h4 className={classes.headerCell}>{translate('weavra.company.legalRegistrationNumber')}</h4></td>
                        <td><h4 className={classes.headerCell}>Aktiv?</h4></td>
                        <td className={classes.headerCell}></td>
                    </tr>
                </thead>
                <tbody>
                    { companyIds.map(id => 
                        <tr key={companies[id].id} className={classes.bodyRow}>
                            <td className={classes.bodyCell}>{companies[id].name}</td>
                            <td className={classes.bodyCell}>{companies[id].legalRegistrationNumber}</td>
                            <td className={classes.bodyCell}>{companies[id].active ? 'Ja' : 'Nej'}</td>
                            <td className={classes.bodyCell}>
                                <Button onClick={() => onEditCompanyClicked(companies[id])}>
                                    <EditIcon />
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default SuperAdminCompanyList;