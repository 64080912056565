import React, {useState, useEffect} from 'react';
import {
    useDataProvider,
    useNotify,
    TextInput,
    DateInput,
    NumberInput,
    Loading,
    Error,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';

import { FormDialog } from '../shared/FormDialog';

const useStyles = makeStyles(theme => ({
    monthPickerRoot: {
        height: '1.8rem',
    },
    monthPickerButton: {
        display: 'inline',
        cursor: 'pointer',
        fontSize: '1.2rem',
        fontWeight: 'bold',

        '&:hover': {
            textDecorationLine: 'underline',
        }
    },
    monthPickerText: {
        display: 'inline',
        fontSize: '1.2rem',
        textTransform: 'capitalize',
    },
    dataTable: {
        borderCollapse: 'separate',
        borderSpacing: '6px 9px',
    },
    tableRow: {
        cursor: 'pointer',

        '&:hover': {
            transition: 'background 0.5s',
            background: theme.palette.background.default,
        }
    }
}));

const monthName = (month) => {
    return new Date(2022, month, 1).toLocaleString('sv-SE',{month:'long'})
};

const MonthPicker = props => {
    const { initialYear, initialMonth, onMonthChange } = props;

    const classes = useStyles();

    const [year, setYear] = useState(initialYear);
    const [month, setMonth] = useState(initialMonth);

    const handleNextClicked = () => {

        const newYear = month === 12 ? year + 1 : year;
        const newMonth = month === 12 ? 1 : month + 1;
        
        setYear(newYear);
        setMonth(newMonth);

        onMonthChange(newYear, newMonth);
    }

    const handlePrevClicked = () => {

        const newYear = month === 1 ? year - 1 : year;
        const newMonth = month === 1 ? 12 : month - 1;
        
        setYear(newYear);
        setMonth(newMonth);

        onMonthChange(newYear, newMonth);
    }

    return (
        <div className={classes.monthPickerRoot}>
            <div className={classes.monthPickerButton} onClick={handlePrevClicked}>&lt;</div>
            <div className={classes.monthPickerText}>{` ${monthName(month)} ${year} `}</div>
            <div className={classes.monthPickerButton} onClick={handleNextClicked}>&gt;</div>
        </div>
    )
}

const SuperAdminInvoices = () => {

    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const today = new Date();

    const [periodStart, setPeriodStart] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [refreshTime, setRefreshTime] = useState();

    const [ongoingProjects, setOngoingProjects] = useState(null);
    const [createdProjects, setCreatedProjects] = useState(null);
    const [activeCustomers, setActiveCustomers] = useState(null);

    const [isLoadingProjects, setIsLoadingProjects] = useState(true);
    const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
    const [loadingError, setLoadingError] = useState();

    const [dialogInitialValues, setDialogInitialValues] = useState();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [saving, setSaving] = useState();

    useEffect(() => {
        setIsLoadingProjects(true);
        dataProvider.getList('superadmin/projects',
            {
                pagination: { page: 1, perPage: 999 },
                sort: { field: 'name', order: 'ASC' },
                filter: {},
                month: periodStart.getMonth() + 1,
                year: periodStart.getFullYear(),
                getInvoiceData: true,
            })
            .then(({ data }) => {
                const periodEnd = new Date(periodStart.getFullYear(), periodStart.getMonth() + 1, 0);

                const ongoing = data
                    .filter(p => new Date(p.startDate).getTime() <= periodEnd.getTime() && (new Date(p.endDate).getTime() >= periodStart.getTime() || !p.endDate))
                    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
                setOngoingProjects(ongoing);

                const created = data
                    .filter(p => new Date(p.created).getTime() >= periodStart.getTime() && new Date(p.created).getTime() <= periodEnd.getTime())
                    .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
                setCreatedProjects(created);

                setIsLoadingProjects(false);
            })
            .catch(error => {
                setLoadingError(error);
                setIsLoadingProjects(false);
            });
        
        setIsLoadingCustomers(true);
        dataProvider.getList('superadmin/companies',
            {
                pagination: { page: 1, perPage: 999 },
                sort: { field: 'name', order: 'ASC' },
                filter: {},
                month: periodStart.getMonth() + 1,
                year: periodStart.getFullYear(),
                getInvoiceData: true,
            })
            .then(({ data }) => {
                setActiveCustomers(data);
                setIsLoadingCustomers(false);
            })
            .catch(error => {
                setLoadingError(error);
                setIsLoadingCustomers(false);
            });
        
    }, [dataProvider, periodStart, refreshTime]);

    const handleMonthChange = (year, month) => {
        setPeriodStart(new Date(year, month, 1));
    }

    const handleProjectRowClicked = (project) => {
        setDialogInitialValues({
            id: project.invoice ? project.invoice.id : null,
            projectId: project.id,
            invoiceNumber: project.invoice ? project.invoice.invoiceNumber : '',
            invoiceDate: project.invoice ? project.invoice.invoiceDate : new Date(),
            amount: project.invoice ? project.invoice.amount : 0
        });

        setDialogOpen(true);
    }

    const handleCompanyRowClicked = (company) => {
        setDialogInitialValues({
            id: company.invoice ? company.invoice.id : null,
            companyId: company.id,
            invoiceNumber: company.invoice ? company.invoice.invoiceNumber : '',
            invoiceDate: company.invoice ? company.invoice.invoiceDate : new Date(),
            amount: company.invoice ? company.invoice.amount : 0
        });

        setDialogOpen(true);
    }

    const handleInvoiceDialogSubmit = async values => {
        
        const resource = values.projectId ? 'superadmin/projects' : 'superadmin/companies';
        setSaving(true);
        if (values.id) { 
            dataProvider.update(resource, { id: values.id, data: {...values, updateInvoice: true } })
                .then(() => {
                    setDialogOpen(false);
                    notify('weavra.notification.invoiceUpdated', 'info');
                    setRefreshTime(new Date().getTime());
                    setSaving(false);
                })
                .catch(error => {
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    setSaving(false);
                });
        }
        else {
            dataProvider.create(resource, { data: {...values, createInvoice: true } })
                .then(() => {
                    setDialogOpen(false);
                    notify('weavra.notification.invoiceSaved', 'info');
                    setRefreshTime(new Date().getTime());
                    setSaving(false);
                })
                .catch(error => {
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    setSaving(false);
                });
        }
    }

    const handleDialogCancel = () => {
        setDialogOpen(false);
    }

    const projectActiveDays = (project) => {
        // Totala summan aktiva dagar hittills, max t.o.m. aktuell månad
 
        const projectStart = new Date(project.startDate); 
        const today = new Date();
        const projectEnd = project.endDate ? new Date(project.endDate) : new Date();

        const fromDate = moment(projectStart);
        const toDate = projectEnd.getTime() > today.getTime() ? moment(today) : moment(projectEnd);

        const activeDays = toDate.startOf('day').diff(fromDate.startOf('day'), 'days');
        
        return activeDays;     
    }

    const projectActiveDaysMonth = (project) => {
        // Aktiva dagar denna månad
        
        const periodEnd = new Date(periodStart.getFullYear(), periodStart.getMonth() + 1, 0);
        const projectStart = new Date(project.startDate);
        const projectEnd = project.endDate ? new Date(project.endDate) : periodEnd;

        const from = projectStart.getTime() < periodStart.getTime() ? moment(periodStart).add(-1, 'days') : moment(projectStart);
        const to = projectEnd.getTime() < periodEnd.getTime() ? moment(projectEnd) : moment(periodEnd);

        return to.startOf('day').diff(from.startOf('day'), 'days');
    }

    const numberOfActiveProjects = (customer) => {
        return ongoingProjects.filter(p => p.ownerId === customer.id).length;
    }

    const numberOfStartedProjects = (customer) => {
        return createdProjects.filter(p => p.ownerId === customer.id).length;
    }

    const numberOfProjectDays = (customer) => {
        const projects = ongoingProjects.filter(p => p.ownerId === customer.id);
        let sum = 0;

        for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            sum += projectActiveDays(project);
        }

        return sum;
    }

    const numberOfActiveLedgers = (customer) => {
        return ongoingProjects.filter(p => p.ownerId === customer.id && p.personnelLedgerActive).length;
    }

    const leadEmailLink = (project) => {
        if (!project.ownerLead)
            return null;
        
        const mailto = `mailto:${project.ownerLead.email}`;
        return <a href={mailto}>{project.ownerLead.email}</a>;
    }

    if (isLoadingProjects || isLoadingCustomers)
        return <Loading />
    
    if (loadingError)
        return <Error />

    return (
        <div>
            <MonthPicker initialYear={periodStart.getFullYear()} initialMonth={periodStart.getMonth()} onMonthChange={handleMonthChange} />
            <div>
                <h3>Pågående projekt</h3>
                <table className={classes.dataTable}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Projekt</th>
                            <th>Ägare</th>
                            <th>Ansvarig</th>
                            <th>Email</th>
                            <th>Startdatum</th>
                            <th>Slutdatum</th>
                            <th>Medlemmar</th>
                            <th>Aktiv liggare</th>
                            <th title='Aktiva dagar under aktuell månad'>Dagar, månad</th>
                            <th title='Totalt antal aktiva dagar hittills'>Dagar, total</th>
                            <th>Fakt.nr.</th>
                            <th>Fakt.datum</th>
                            <th>Fakt.summa</th>
                        </tr>
                    </thead>
                    <tbody>
                        { ongoingProjects && ongoingProjects.map(project => 
                            <tr key={project.id} className={classes.tableRow} onClick={() => handleProjectRowClicked(project)}>
                                <td>{project.orderNumber}</td>
                                <td>{project.name}</td>
                                <td>{project.ownerCompanyName}</td>
                                <td>{project.ownerLead ? `${project.ownerLead.firstName} ${project.ownerLead.lastName}` : ''}</td>
                                <td>{leadEmailLink(project)}</td>
                                <td>{project.startDate ? new Date(project.startDate).toLocaleDateString('sv-SE') : ''}</td>
                                <td>{project.endDate ? new Date(project.endDate).toLocaleDateString('sv-SE') : ''}</td>
                                <td>{project.membersCount}</td>
                                <td>{project.personnelLedgerActive ? 'Ja' : 'Nej'}</td>
                                <td>{projectActiveDaysMonth(project)}</td>
                                <td>{projectActiveDays(project)}</td>
                                <td>{project.invoice ? project.invoice.invoiceNumber : ''}</td>
                                <td>{project.invoice && project.invoice.invoiceDate ? new Date(project.invoice.invoiceDate).toLocaleDateString('sv-SE') : ''}</td>
                                <td>{project.invoice ? project.invoice.amount : ''}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div>
                <h3>Projekt skapade under {monthName(periodStart.getMonth())} månad</h3>
                <table className={classes.dataTable}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Projekt</th>
                            <th>Ägare</th>
                            <th>Ansvarig</th>
                            <th>Email</th>
                            <th>Skapad</th>
                            <th>Startdatum</th>
                            <th>Aktiv liggare</th>
                            <th>Fakt.nr.</th>
                            <th>Fakt.datum</th>
                            <th>Fakt.summa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {createdProjects && createdProjects.map(project =>
                            <tr key={project.id} className={classes.tableRow} onClick={() => handleProjectRowClicked(project)}>
                                <td>{project.orderNumber}</td>
                                <td>{project.name}</td>
                                <td>{project.ownerCompanyName}</td>
                                <td>{project.ownerLead ? `${project.ownerLead.firstName} ${project.ownerLead.lastName}` : ''}</td>
                                <td>{leadEmailLink(project)}</td>
                                <td>{new Date(project.created).toLocaleDateString('sv-SE')}</td>
                                <td>{project.startDate ? new Date(project.startDate).toLocaleDateString('sv-SE') : ''}</td>
                                <td>{project.personnelLedgerActive ? 'Ja' : 'Nej'}</td>
                                <td>{project.invoice ? project.invoice.invoiceNumber : ''}</td>
                                <td>{project.invoice && project.invoice.invoiceDate ? new Date(project.invoice.invoiceDate).toLocaleDateString('sv-SE') : ''}</td>
                                <td>{project.invoice ? project.invoice.amount : ''}</td>
                            </tr>    
                        )}
                    </tbody>
                </table>
            </div>
            <div>
                <h3>Aktiva kunder under {monthName(periodStart.getMonth())} månad</h3>
                <table className={classes.dataTable}>
                    <thead>
                        <tr>
                            <th>Företag</th>
                            <th>Aktiva projekt</th>
                            <th>Admin</th>
                            <th>Användare</th>
                            <th>Totalt</th>
                            <th>Startade projekt</th>
                            <th>Aktiva projektdagar</th>
                            <th>Aktiv liggare</th>
                            <th>Skapad</th>
                            <th>Fakt.nr.</th>
                            <th>Fakt.datum</th>
                            <th>Fakt.summa</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeCustomers && activeCustomers.map(customer => 
                            <tr key={customer.id} className={classes.tableRow} onClick={() => handleCompanyRowClicked(customer)}>
                                <td>{customer.name}</td>
                                <td>{numberOfActiveProjects(customer)}</td>
                                <td>{customer.users ? customer.users.filter(u => u.role === 0).length : 0}</td>
                                <td>{customer.users ? customer.users.filter(u => u.role === 1).length : 0}</td>
                                <td>{customer.users ? customer.users.length : 0}</td>
                                <td>{numberOfStartedProjects(customer)}</td>
                                <td>{numberOfProjectDays(customer)}</td>
                                <td>{numberOfActiveLedgers(customer)}</td>
                                <td>{new Date(customer.created).toLocaleDateString('sv-SE')}</td>
                                <td>{customer.invoice ? customer.invoice.invoiceNumber : ''}</td>
                                <td>{customer.invoice && customer.invoice.invoiceDate ? new Date(customer.invoice.invoiceDate).toLocaleDateString('sv-SE') : ''}</td>
                                <td>{customer.invoice ? customer.invoice.amount : ''}</td>
                            </tr>
                        )}    
                    </tbody>
                </table>
            </div>

            <FormDialog
                open={dialogOpen}
                loading={saving}
                onSubmit={handleInvoiceDialogSubmit}
                onCancel={handleDialogCancel}
                title={dialogInitialValues && dialogInitialValues.projectId ? "Projektfaktura" : "Företagsfaktura"}
                submitLabel="Spara"
                initialValues={dialogInitialValues}
            >
                <TextInput source="invoiceNumber" label="Fakturanummer" fullWidth />
                <NumberInput source="amount" label="Summa" fullWidth />
                <DateInput source="invoiceDate" label="Fakturadatum" fullWidth />
            </FormDialog>
        </div>
    )
}

export default SuperAdminInvoices;
