import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import {
    Button,
    SimpleForm,
    TextInput,
    NumberInput, 
    Loading,
    Error,
    useDataProvider,
    useNotify,
    useTranslate,
    useCreate,
    useUpdate,
    useQuery,
} from 'react-admin';

import { 
    Grid, 
    Card, 
    CardHeader, 
    CardContent, 
    Avatar, 
    Typography, 
    Dialog, 
    DialogTitle,
    DialogContent,
    TextField,
    makeStyles 
} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonIcon from '@material-ui/icons/Person';

import QuickDialogActions from '../shared/QuickDialogActions';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    topButton: {
        marginRight: theme.spacing(3),
    },
    contactCardHeader: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: 0,
        display: 'flex',
    },
    contactCard: {
        cursor: 'pointer',
        transition: 'background 0.5s',

        "&:hover": {
            transition: 'background 0.5s',
            background: theme.palette.background.default,
          },
    },
    copyContactSelect: {
        padding: theme.spacing(2),
    },
}));

const AddNewContactButton = props => {
    const { onClick } = props;
    const classes = useStyles();

    return (
        <Button
            label="Ny kontakt"
            variant="contained"
            color="primary"
            size="large"
            onClick={onClick}
            className={classes.topButton}
        >
            <PersonAddIcon fontSize="large" />
        </Button>
    )
}

const ProjectContactList = props => {
    const { contacts, onCardClick } = props;

    // TODO: More styling

    return (
        <div>
            <h3>Kontaktpersoner</h3>
            <Grid container spacing={1} direction="column">
                {contacts && contacts.map(contact =>
                    <Grid item key={contact.id}>
                        <ProjectContactCard contact={contact} onCardClick={onCardClick} />
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

const ProjectContactCard = props => {
    const { contact, onCardClick } = props;
    const classes = useStyles();

    // TODO: More Styling

    return (
        <Card className={classes.contactCard} onClick={() => onCardClick(contact)}>
            <CardHeader 
                title={contact.function}
                titleTypographyProps={{ variant: 'h6' }}
                classes={{
                    root: classes.contactCardHeader,
                }}
                avatar={
                    <Avatar>
                        <PersonIcon />
                    </Avatar>
                }
            />
            <CardContent>
                <Typography>{contact.name}</Typography>
                <Typography variant='body2'>{contact.company}</Typography>
                <Typography variant='body2'>{contact.telephone}</Typography>
                <Typography variant='body2'>{contact.email}</Typography>
            </CardContent>
        </Card>
    )
}

const CurrentlyCheckedInUsersList = props => {
    const { users } = props;

    return (
        <div>
            <h3>Incheckade på arbetsplats</h3>
            <Grid container spacing={1} direction="column">
                { users && users.map(user => 
                    <Grid item key={user.id}>
                        <CheckedInUserRow user={user} />
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

const AllCheckedInUsersList = props => {
    const { users } = props;

    return (
        <div>
            <h3>Tidigare incheckningar</h3>
            <Grid container spacing={1} direction="column">
                { users && users.map(user => 
                    <Grid item key={user.id}>
                        <CheckedInUserRow user={user} />
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

const CheckedInUserRow = props => {
    const { user } = props;

    // TODO: Style

    return (
        <Card>
            <CardContent>
                <Typography>{user.firstName} {user.lastName}</Typography>
                <Typography variant='body2'>{user.companyName}</Typography>
                <Typography variant='body2'>{user.telephone}</Typography>
            </CardContent>
        </Card>
    )
}

const ContactQuickCreate = ({ initialValues, onSave, onCancel, isLoading, companyContacts }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [initValues, setInitValues] = useState(initialValues);

    // TODO: First step: filter options on typing...
    // TODO: Second step: live search/filtering against API

    const handleCopyContact = (originalContact) => {
        if (originalContact)
            setInitValues({
                projectId: initialValues.projectId,
                name: originalContact.name,
                function: originalContact.function,
                email: originalContact.email,
                telephone: originalContact.telephone,
                company: originalContact.company,
                sortIndex: 0,
                originalContactId: originalContact.id
            });
        else
            setInitValues({
                projectId: initialValues.projectId,
                name: '',
                function: '',
                email: '',
                telephone: '',
                company: '',
                sortIndex: 0,
                originalContact: ''
            });
    }

    return (
        <div>
            <div className={classes.copyContactSelect}>
                <Autocomplete 
                    id="copy-contacts-select"
                    options={companyContacts}
                    noOptionsText={translate("weavra.autocomplete.noOptions")}
                    placeholder="Kopiera kontaktuppgifter från ..."
                    onChange={(event, newValue) => {
                        handleCopyContact(newValue);
                    }}
                    getOptionLabel={(option) => {
                        return typeof option === "string" ? option : option.name;
                    }}
                    filterOptions={(x) => x}
                    autoComplete
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params} 
                            placeholder={"Kopiera kontaktuppgifter från ..."}
                        />
                    )}
                />
            </div>
            <SimpleForm 
                name="contact-quick-create"
                resource="general/projectcontacts"
                initialValues={initValues}
                save={onSave}
                toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
            >
                <TextInput source="name" label="Namn" />
                <TextInput source="function" label="Funktion" />
                <TextInput source="email" />
                <TextInput source="telephone" label="Telefon" />
                <TextInput source="company" label="Företag" />
                <NumberInput source="sortIndex" label="Sortering" />
            </SimpleForm>
        </div>
    );
}

const ContactQuickEdit = ({ record, onSave, onCancel, onDelete, isLoading, canDelete, readonly}) => {
    return (
        <SimpleForm
        name="contact-quick-update"
        resource="general/projectcontacts"
        record={record}
        save={onSave}
        toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} enableDelete={canDelete} disabled={readonly} record={record} onDelete={onDelete} />}
        >
            <TextInput source="name" label="Namn" disabled={readonly} />
            <TextInput source="function" label="Funktion" disabled={readonly} />
            <TextInput source="email" disabled={readonly} />
            <TextInput source="telephone" label="Telefon" disabled={readonly} />
            <TextInput source="company" label="Företag" disabled={readonly} />
            <NumberInput source="sortIndex" label="Sortering" disabled={readonly} />
        </SimpleForm>
    );
}

const ProjectContacts = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [shownDialog, setShownDialog] = useState(undefined);
    const [checkedInUsers, setCheckedInUsers] = useState(undefined);
    const [contacts, setContacts] = useState(undefined);
    const [companyContacts, setCompanyContacts] = useState(undefined);
    const [selectedContact, setSelectedContact] = useState(undefined);
    const [shouldReloadContacts, setShouldReloadContacts] = useState(true);
    const [shouldReloadCheckIns, setShouldReloadCheckIns] = useState(true);

    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [createContact] = useCreate('general/projectcontacts');
    const [updateContact] = useUpdate('general/projectcontacts');

    // Ladda projekt-Id från redux-state
    const projectId = useSelector(state => state.workspace.id);
    const userCompanyId = useSelector(state => state.user.companyId);

    // Hämta rättigheter
    const { data:permissionsData, loaded:isPermissionsLoaded, error:permissionsError } = useQuery({
        type: 'getOne',
        resource: 'general/projects',
        payload: { id: projectId, getPermissions: true, getMembersPermissions: false }
    });

    useEffect(() => {
        if (projectId) {
            setIsLoading(true);

            if (shouldReloadContacts) {
                dataProvider.getMany('general/projects', { ids: [projectId], getContacts: true })
                    .then(({ data }) => {
                        setContacts(data);
                    }).catch(error => {
                        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    });
                
                dataProvider.getMany('general/companies', { ids: [userCompanyId], getContacts: true })
                    .then(({ data }) => {
                        setCompanyContacts(data);
                    }).catch(error => {
                        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    });

                setShouldReloadContacts(false);
            }

            if (shouldReloadCheckIns) {
                dataProvider.getMany('general/projects', { ids: [projectId], getCheckedInUsers: true })
                    .then(({ data }) => {
                        setCheckedInUsers(data);
                    }).catch(error => {
                        notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                });

                setShouldReloadCheckIns(false);
            }

            setIsLoading(false);
        }
    }, [dataProvider, notify, projectId, shouldReloadCheckIns, shouldReloadContacts, userCompanyId]);

    if (!isPermissionsLoaded)
        return <Loading />
    
    if (permissionsError)
        return <Error />

    const handleClose = () => {
        setShownDialog(null);
    }

    const handleNewContactClicked = () => {
        setShownDialog('contactCreate');
    }

    const handleCreateContactSubmit = async values => {
        setIsLoading(true);
        createContact(
            { payload: { data: { ...values } } },
            {
                onSuccess: () => {
                    handleClose();
                    setShouldReloadContacts(true);

                    // TODO: Notify?
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }

    const handleEditContactClicked = (contact) => {
        setSelectedContact(contact);
        setShownDialog('contactEdit');
    }

    const handleEditContactSave = async values => {
        setIsLoading(true);
        updateContact(
            { payload: { id: values.id, data: { ...values } } },
            {
                onSuccess: () => {
                    handleClose();
                    setShouldReloadContacts(true);

                    // TODO: Notify?
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }

    const handleContactDelete = (contact) => {
        const confirmMessage =  translate("ra.message.delete_content"); // TODO: Custom message
        if (window.confirm(confirmMessage)) {
            setIsLoading(true);
            dataProvider.delete('general/projectcontacts',
                { id: contact.id }
            ).then(() => {
                handleClose();
                setShouldReloadContacts(true);
                // TODO: Notify?
            })
            .catch(error => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
            });

            setIsLoading(false);
        }
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {permissionsData.canAddContacts && <AddNewContactButton onClick={handleNewContactClicked} />}
                </Grid>
                <Grid item sm={12} lg={4}>
                    <ProjectContactList contacts={contacts} onCardClick={handleEditContactClicked} />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <CurrentlyCheckedInUsersList users={checkedInUsers && checkedInUsers.filter(user => user.currentlyCheckedIn)} />
                </Grid>
                <Grid item sm={12} lg={4}>
                    <AllCheckedInUsersList users={checkedInUsers} />
                </Grid>
            </Grid>

            <Dialog open={shownDialog === 'contactCreate'} onClose={handleClose} fullWidth>
                <DialogTitle>Ny Kontakt</DialogTitle>
                <DialogContent>
                    <ContactQuickCreate onSave={handleCreateContactSubmit} onCancel={handleClose} isLoading={isLoading} initialValues={{ projectId: projectId }} companyContacts={companyContacts} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'contactEdit'} onClose={handleClose} fullWidth>
                <DialogTitle>Redigera Kontakt</DialogTitle>
                <DialogContent>
                    <ContactQuickEdit
                        record={selectedContact}
                        onSave={handleEditContactSave}
                        onCancel={handleClose}
                        isLoading={isLoading}
                        onDelete={handleContactDelete}
                        canDelete={permissionsData.canDeleteContacts}
                        readonly={!permissionsData.canEditContacts} />
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default ProjectContacts;