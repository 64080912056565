const defaultWorkspaceState = {
  id: localStorage.getItem('workspace.id') || '',
  name: localStorage.getItem('workspace.name') || '',
  type: localStorage.getItem('workspace.type') || '',
};

const workspaceReducer = (state = defaultWorkspaceState, action) => {
    if (action.type === 'SET_WORKSPACE') {
        return action.payload;
    }
    return state;
};

export default workspaceReducer;
