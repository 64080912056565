import React from 'react';
import {
    Button,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

const useStyles = makeStyles(theme => ({
    topButton: {
        marginRight: theme.spacing(3),
    },
}));

const CreateFolderButton = ({onClick}) => {
    const classes = useStyles();
    return (
        <Button 
        label="weavra.actions.newFolder" 
        variant="contained" 
        color="primary" 
        size="large"
        onClick={onClick}
        className={classes.topButton}>
            <CreateNewFolderIcon fontSize="large" />
        </Button>
    );
}

export default CreateFolderButton;