import React, { Fragment } from 'react';
import {
    Button,
    TextField,
    FunctionField,
    DateField,
    ImageField,
} from 'react-admin';
import { makeStyles, Typography } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InfoIcon from '@material-ui/icons/Info';

import DateTimeOptions from '../DateTimeOptions';

const useStyles = makeStyles(theme => ({
    folderContainer: {
        marginBottom: theme.spacing(2),
    },
    folderHeader: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        borderRadius: '4px',
        display: 'flex',
    },
    folderToolbar: {
        marginLeft: 'auto',
    },
    folderHeaderButton: {
        marginLeft: theme.spacing(1),
    },
    folderContent: {
        borderLeft: `1px solid ${theme.palette.background.paper}`,
        borderRight: `1px solid ${theme.palette.background.paper}`,
        display: 'flex',
        flexFlow: 'row wrap',
        padding: theme.spacing(1),
    },
    folderFooter: {
        border: `1px solid ${theme.palette.background.paper}`,
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(1),
    },
    documentTable: {
        width: '100%',
        borderSpacing: 0,
    },
    documentTableHeader: {
        backgroundColor: theme.palette.background.paper,
        textAlign: 'left',
    },
    documentRow: {
        cursor: 'pointer',
        background: 'transparent',
        transition: 'background 0.5s',
        "&:hover td": {
            transition: 'background 0.5s',
            background: 'rgba(255,255,255,0.08)',
        },
    },
    documentCard: {
        width: '10em',
        margin: theme.spacing(1),
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        cursor: 'pointer',
        border: `1px solid ${theme.palette.text.primary}`,
        borderRadius: '4px',
        padding: theme.spacing(1),
        background: 'transparent',
        transition: 'background 0.5s',

        "&:hover": {
            transition: 'background 0.5s',
            background: 'rgba(255,255,255,0.08)',
        },

        "& img": {
            width: '8em',
        }
    },
}));

const DeleteFolderButton = props => {
    const classes = useStyles();
    return (
        <Button 
        label="ra.action.delete" 
        variant="outlined" 
        color="primary" 
        onClick={props.onClick}
        className={classes.folderHeaderButton}>
            <DeleteIcon fontSize="large" />
        </Button>
    );
}

const EditFolderButton = props => {
    const classes = useStyles();
    return (
        <Button 
        label="ra.action.edit" 
        variant="outlined" 
        color="primary" 
        onClick={props.onClick}
        className={classes.folderHeaderButton}>
            <EditIcon fontSize="large" />
        </Button>
    );
}

const AddDocumentButton = props => {
    return (
        <Button 
        label="Lägg till dokument" 
        variant="contained" 
        color="primary" 
        onClick={props.onClick}>
            <LibraryAddIcon fontSize="large" />
        </Button>
    );
}

const DocumentRow = ({ record, folders, onClickEdit, ...props }) => {
    const classes = useStyles();
    const className = [classes.documentRow];
    return (
        <tr className={className.join(' ')} onClick={() => onClickEdit(record, folders)}>
            <td><TextField record={record} source="name" /></td>
            <td><DateField record={record} source="created" showTime options={DateTimeOptions} locales="sv-SE" /></td>
            <td><TextField record={record} source="createdByName" /></td>
            {/* <td>{record.hasChanged ? 'Uppdaterad' : 'Ny'}</td> */}
        </tr>
    );
}

const DocumentCard = ({ record, folders, onClickEdit, ...props }) => {
    const classes = useStyles();
    return (
        <div className={classes.documentCard} onClick={() => onClickEdit(record, folders)}>
            <ImageField record={record} source="thumbUrl" />
            <FunctionField 
                record={record} 
                render={record => record ? (record["name"].length > 16 ? record["name"].slice(0, 15) + "..." : record["name"]) : null } 
            />
            <DateField record={record} source="created" showTime options={DateTimeOptions} locales="sv-SE" />
            {/* <div>{record.hasChanged ? 'Uppdaterad' : 'Ny'}</div> */}
        </div>
    );
}

const DocumentFolderContent = ({ showThumbnails, documents, folders, onDocumentEdit, ...props}) => {
    const classes = useStyles();
    if (!documents || documents.length === 0)
        // TODO: Translate empty message
        return (
            <div>Inga filer i mappen.</div>
        );
    if (showThumbnails) {
        return (
            <Fragment>
                { documents.map(doc => 
                    <DocumentCard key={doc.id} record={doc} folders={folders} onClickEdit={onDocumentEdit} />
                )}
            </Fragment>
        );
    } else {
        return (
            <table className={classes.documentTable}>
                <thead>
                    <tr className={classes.documentTableHeader}>
                        <th><DescriptionIcon fontSize="small" /></th>
                        <th><CalendarTodayIcon fontSize="small" /></th>
                        <th><PersonIcon fontSize="small" /></th>
                        <th><InfoIcon fontSize="small" /></th>
                    </tr>
                </thead>
                <tbody>
                { documents.map(doc => 
                    <DocumentRow key={doc.id} record={doc} folders={folders} onClickEdit={onDocumentEdit} />
                )}
                </tbody>
            </table>
        );
    }
}

const DocumentFolder = ({ record, folders, onFolderEdit, onFolderDelete, onDocumentEdit, onAddDocument, canAdd, canEdit, canDelete, ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.folderContainer}>
            <div className={classes.folderHeader}>
                <Typography variant='h6'>{record.name}</Typography>
                <div className={classes.folderToolbar}>
                    {canDelete && <DeleteFolderButton onClick={() => onFolderDelete(record)} />}
                    {canEdit && <EditFolderButton onClick={() => onFolderEdit(record)} />}
                </div>
            </div>
            <div className={classes.folderContent}>
                <DocumentFolderContent showThumbnails={record.showThumbnails} documents={record.documents} folders={folders} onDocumentEdit={onDocumentEdit} />
            </div>
            <div className={classes.folderFooter}>
                {canAdd && <AddDocumentButton onClick={() => onAddDocument(record)} />}  
            </div>
        </div>
    );
}

export default DocumentFolder;