import { createMuiTheme } from '@material-ui/core/styles';

const WeAvraTheme = createMuiTheme({
    palette: {
      type: 'dark',
      common: {
        black: '#131E29',
        white: '#A7A8AA',
      },
      text: {
        primary: '#A7A8AA',
        secondary: '#888B8D',
      },
      primary: {
        dark: '#131E29',
        main: '#A7A8AA',
        light: '#A7A8AA',
      },
      background: {
          default: '#131E29',
          paper: '#253746',
          contrastText: '#A7A8AA',
      },
      success: {
        dark: '#78AA00',
        main: '#93C90E',
        light: '#94C90E',
      },
      error: {
        dark: '#CB333B',
        main: '#F4364C',
        light: '#F4364C',
      },
    },
    typography: {
        fontFamily: '"Work Sans", sans-serif',
    },
    // Override i.e. class .MuiButton-outlinedPrimary
    overrides: {
      MuiButton: {
            //outlinedPrimary: {
            //    color: "#F00",
            //},
      },
      MuiSwitch: {
        switchBase: {
          color: '#A7A8AA',
        },
        colorSecondary: {
          '&$checked': {
            color: '#93C90E',
          },
        },
        track: {
          opacity: 0.2,
          backgroundColor: '#fff',
          '$checked$checked + &': {
            opacity: 0.7,
            backgroundColor: '#fff',
          },
        },
      },
      RaFileField: {
        root: {
          '& a': {
            color: '#fff',
          },
        },
      },
    },
});

export default WeAvraTheme;