import React from "react";
import { useDispatch } from "react-redux";
import { AppBar, Toolbar, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import LogoutButton from "./buttons/LogoutButton";
import ProjectAutocompleteSelect from "./ProjectAutocompleteSelect";
import CompanyWorkspaceLink from "./CompanyWorkspaceLink";
import UserWorkspaceLink from "./UserWorkspaceLink";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  logo: {
    cursor: "pointer",
  },
  projectFinder: {
    marginLeft: theme.spacing(3),
  },
}));

const DefaultAppBar = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onLogoClick = () => {
    dispatch({ type: "SET_WORKSPACE", payload: { type: '', id: '', name: '' }});
  };

  return (
    <div>
      <AppBar
        {...props}
        className={classes.appBar}
        position="static"
        title="Weavra admin"
      >
        <Toolbar>
          <Grid container>
            <Grid
              container
              item
              justify="flex-start"
              alignItems="center"
              xs={2}
            >
              <img
                src={process.env.PUBLIC_URL + '/images/weavra-horizontal-small.png'}
                alt="Weavra logotype"
                className={classes.logo}
                onClick={onLogoClick}
                title="Till startsidan"
              />
            </Grid>
            <Grid container item justify="center" alignItems="center" xs={4}>
              <ProjectAutocompleteSelect
                size="small"
                className={classes.projectFinder}
              />
            </Grid>
            <Grid container item justify="flex-end" alignItems="center" xs={6}>
              <CompanyWorkspaceLink />
              <UserWorkspaceLink />
              <LogoutButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default DefaultAppBar;
