import React, { useState, useCallback } from 'react';
import {
    useTranslate,
    useQueryWithStore,
    useRefresh,
    useNotify,
    useDataProvider,
    Confirm,
    Loading,
    Error,
} from 'react-admin';

import { List, ListItem, makeStyles } from '@material-ui/core';

import AddCompanyButton from './AddCompanyButton';
import MemberCompanyCard from './MemberCompanyCard';

const useStyles = makeStyles(theme => ({
    member: {
        position: 'relative',
        flex: '1 0 auto',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:hover button': {
            visibility: 'visible'
        },
    },
    addMember: {
        flex: '1 0 auto'
    },
    deleteButton: {
        visibility: 'hidden',
        position: 'absolute',
        bottom: 10,
        right: 10,
        minWidth: 32
    },
}));

const ProjectMembers = props => {
    const classes = useStyles();
    const translate = useTranslate();
    const { projectId, projectPermissions } = props;

    const { data: projectData, loaded: isProjectLoaded, error: projectError } = useQueryWithStore({
        type: 'getOne',
        resource: 'general/projects',
        payload: { id: projectId, getMembers: true }
    });

    const { data: companiesData, loaded } = useQueryWithStore({
        type: 'getList',
        resource: 'general/companies',
        payload: {
            pagination: { page: 1, perPage: 999 },
            sort: { field: 'name', order: 'ASC' }
        }
    });

    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [saving, setSaving] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [companyId, setCompanyId] = useState();
    
    const handleAddMemberCompany = useCallback((newCompanyId, newCompanyRole, projectLead) => {
        setSaving(true);

        const newMember = companiesData.find(c => c.id === newCompanyId)
        projectData.memberCompanies.push(newCompanyId);
        projectData.memberCompaniesDetailed.push({...newMember, projectRole: newCompanyRole, projectLeadId: projectLead});

        dataProvider
            .update('general/projects', { id: projectData.id, data: { updateProjectMembers: true, memberCompanies: projectData.memberCompaniesDetailed}}) 
            .then(() => {
                setSaving(false);
                refresh();
            })
            .catch(error => {
                setSaving(false);
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                console.log('Fel fel fel', error, saving);
            });
        
        setSaving(false);
    }, [dataProvider, notify, projectData, companiesData, refresh, saving]);

    const handleUpdateMemberCompany = useCallback((companyId, projectLead, projectRole) => {
        setSaving(true);

        const memberCompany = projectData.memberCompaniesDetailed.find(c => c.id === companyId);
        memberCompany.projectLeadId = projectLead;
        memberCompany.projectRole = projectRole;

        dataProvider
            .update('general/projects', { id: projectData.id, data: { updateProjectMembers: true, memberCompanies: projectData.memberCompaniesDetailed}})
            .then(() => {
                setSaving(false);
                refresh();
            })
            .catch(error => {
                setSaving(false);
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                console.log('Fel fel fel', error, saving);
            });

        setSaving(false);       
    }, [dataProvider, notify, projectData, refresh, saving]);

    const confirmDeleteMemberCompany = companyId => {
        if (companyId === projectData.ownerId) return;
        setCompanyId(companyId);
        setConfirmOpen(true);
    }

    const deleteMemberCompany = useCallback(e => {
        setSaving(true);
        const companyIndex = projectData.memberCompanies.indexOf(companyId);
        if (companyIndex > -1) {
            const company = projectData.memberCompaniesDetailed.find(c => c.id === companyId)
            const detailedIndex = projectData.memberCompaniesDetailed.indexOf(company);
            projectData.memberCompanies.splice(companyIndex, 1);
            detailedIndex > -1 && projectData.memberCompaniesDetailed.splice(detailedIndex, 1);

            dataProvider
                .update('general/projects', { id: projectData.id, data: { updateProjectMembers: true, memberCompanies: projectData.memberCompaniesDetailed}})
                .then(() => {
                    setSaving(false);
                    refresh();
                })
                .catch(error => {
                    setSaving(false);
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    console.log('Fel fel fel', error, saving);
                });
        }
        setConfirmOpen(false);
        setSaving(false);
    }, [dataProvider, notify, projectData, refresh, saving, companyId]);
    
    if (!isProjectLoaded || !loaded)
        return <Loading />

    if (projectError || !companiesData || !companiesData.length)
        return <Error />

    const { memberCompaniesDetailed, memberCompanies } = projectData
    const nonMemberCompanies = companiesData.filter(c => !memberCompanies.includes(c.id));
    const confirmMessage =  translate("ra.message.delete_content");

    // project owner always first in list
    if (memberCompaniesDetailed && memberCompaniesDetailed.length > 1)
        memberCompaniesDetailed.unshift(memberCompaniesDetailed.splice(memberCompaniesDetailed.findIndex(c => c.id === projectData.ownerId), 1)[0]);

    return <React.Fragment>
        <List>{memberCompaniesDetailed.map(company =>
            <ListItem key={company.id}>
                <MemberCompanyCard
                    company={company}
                    projectData={projectData}
                    projectPermissions={projectPermissions}
                    onDeleteClicked={confirmDeleteMemberCompany}
                    onMemberUpdate={handleUpdateMemberCompany} />
            </ListItem>)}
            {(projectPermissions.canAddAdminMembers || projectPermissions.canAddParticipantMembers) &&
                <ListItem>
                    <AddCompanyButton
                        className={classes.addMember}
                        companies={nonMemberCompanies}
                        onSaveClicked={handleAddMemberCompany}
                        projectPermissions={projectPermissions} />
                </ListItem>
            }
        </List>
        <Confirm
            isOpen={confirmOpen}
            loading={saving}
            title="Ta bort projektmedlem"
            content={confirmMessage}
            onConfirm={() => deleteMemberCompany(companyId)}
            onClose={() => setConfirmOpen(false)}
            value={companyId}
        />
    </React.Fragment>
}

export default ProjectMembers;