import React, { useState, useEffect } from "react";
import { useNotify, useDataProvider } from "react-admin";
import { FormControl, TextField, ButtonGroup, Button, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    addButton: {
        cursor: 'pointer',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        heigth: '64px',
        minWidth: '64px',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        background: 'transparent',
        transition: 'background 0.5s',
        border: `1px solid ${theme.palette.background.paper}`,
        borderRadius: '4px',
  
        "&:hover": {
          transition: 'background 0.5s',
          background: theme.palette.background.paper,
        },
    },
    error: {
        margin: '1rem 0',
        color: theme.palette.error.main
    }
}));

const AddCompanyButton = props => {
    const { onSaveClicked, companies, projectPermissions } = props;

    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [showPicker, setShowPicker] = useState(false);
    const [newCompanyId, setNewCompanyId] = useState('');
    const [projectLead, setProjectLead] = useState('');
    const [users, setUsers] = useState([]);
    const [newCompanyRole, setNewCompanyRole] = useState(1);

    const handleClick = () => {
        setShowPicker(true);
    }

    const handleCancel = () => {
        setProjectLead('');
        setUsers([]);
        setNewCompanyId('');
        setShowPicker(false);
    }

    const handleChange = (_, value) => {
        const companyId = value && value.id
        setNewCompanyId(companyId ? companyId : '');
    }

    useEffect(() => {
        if (newCompanyId) {
            dataProvider.getList('companies/users', { companyId: newCompanyId, pagination: { page: 1, perPage: 999 }, sort: { field: 'firstName', order: 'ASC' }, filter: { role:0, active:true } })
            .then(({ data }) => {
                setUsers(data)
            }).catch(error => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
            });
        }
    }, [newCompanyId, dataProvider, notify])

    const handleRoleChange = (event) => {
        setNewCompanyRole(event.target.value);
    }   

    const handleLeadChange = (event) => {
        setProjectLead(event.target.value);
    }

    const handleSave = () => {
        setShowPicker(false);
        onSaveClicked(newCompanyId, newCompanyRole, projectLead);
        setProjectLead('');
        setUsers([]);
        setNewCompanyId('');
        setNewCompanyRole(1);
    }

    if (showPicker) {
        return (
            <div className={props.className}>
                <FormControl fullWidth margin="normal">
                    <Autocomplete
                        id="select-company-label"
                        options={companies}
                        noOptionsText="Inga träffar (ange minst 3 tecken för att söka företag)"
                        getOptionLabel={(option) => option.name}
                        filterOptions={(options, state) => {
                            const input = state.inputValue.trim().toLowerCase()
                            if (input.length < 3) return []
                            return options.filter(o => o.name.toLowerCase().includes(input))
                        }}
                        renderInput={(params) => <TextField {...params} label="Välj företag" variant="filled" />}
                        onChange={handleChange}
                    />
                </FormControl>
                {users.length > 0 &&
                <FormControl fullWidth margin="normal">
                    <InputLabel id="select-lead-label" variant="filled">Välj projektansvarig</InputLabel>
                    <Select id="select-lead"  labelId="select-lead-label" value={projectLead} variant="filled" onChange={handleLeadChange}>
                        {users.map(user => <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>)}
                    </Select>
                </FormControl>
                }
                {newCompanyId && users.length === 0 &&
                <div className={classes.error}>Admin saknas för detta företag</div>
                }
                
                <FormControl fullWidth margin="normal">
                    <InputLabel id="select-role-label" variant="filled">Välj projektroll</InputLabel>
                    <Select id="select-role"  labelId="select-role-label" value={newCompanyRole} variant="filled" onChange={handleRoleChange}>
                        {projectPermissions.canAddAdminMembers && 
                            <MenuItem key='admin' value={0}>Administratör</MenuItem>
                        }
                        {(projectPermissions.canAddAdminMembers || projectPermissions.canAddParticipantMembers) && 
                            <MenuItem key='participant' value={1}>Entreprenör</MenuItem>
                        }
                        {(projectPermissions.canAddAdminMembers || projectPermissions.canAddParticipantMembers) && 
                            <MenuItem key='guest' value={2}>Gäst (readonly)</MenuItem>
                        }
                    </Select>
                </FormControl>
               
                <ButtonGroup>
                    <Button variant="text" size="small" disabled={!newCompanyId || !projectLead} onClick={() => handleSave()}>Spara</Button>
                    <Button variant="text" size="small" onClick={() => handleCancel()}>Avbryt</Button>
                </ButtonGroup>
            </div>
        )
    }

    return (
        <div className={classes.addButton} onClick={() => handleClick()} title="Lägg till utförare">
            <AddIcon /> Lägg till företag
        </div>
    );
}

export default AddCompanyButton;