
import * as React from 'react';
import {
    SimpleForm,
    TextInput,
    SelectInput,
} from 'react-admin'
import QuickDialogActions from '../../shared/QuickDialogActions';

const CompanyQuickCreate = ({ onSave, onCancel, isLoading }) => {
    return (
        <SimpleForm 
            name="company-quick-create"
            resource="superadmin/companies"
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="name" label="weavra.company.name"/>
            <TextInput source="phone" label="Telefon" />
            <TextInput source="legalRegistrationNumber" label="weavra.company.legalRegistrationNumber" placeholder='xxxxxxxxxx' maxLength='10' />
            <hr/>
            <h3>Användare</h3>
            <TextInput source="defaultUser.firstName" label="Förnamn" />
            <TextInput source="defaultUser.lastName" label="Efternamn" />
            <TextInput source="defaultUser.email" label="Email" />
            <TextInput source="defaultUser.telephone" label="Telefon" />
            {/* <TextInput source="defaultUser.id06" label="ID06" /> */}
            <SelectInput source="role" label="weavra.companyUsers.role" optionText="name" choices={[
                { id: 0, name: 'weavra.roles.administrator' },
                { id: 1, name: 'weavra.roles.user' }
            ]}/>
            <TextInput source="defaultUser.password" label="Lösenord" />
        </SimpleForm>
    );
}

export default CompanyQuickCreate;