import React from "react";
import styles from './TimeplanGanttTooltip.module.css';

export const TimeplanGanttTooltip = ({ task, fontSize, fontFamily }) => {

  const style = {
    fontSize,
    fontFamily,
  };

  const taskEnd = new Date(task.end);
  taskEnd.setDate(taskEnd.getDate() - 1);

  const getNumberOfWorkingDays = (startDate, endDate) => {
    const fromDate = new Date(startDate.getTime());
    const toDate = new Date(endDate.getTime());

    let numberOfWorkingDays = 1;

    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);

    while (fromDate < toDate) {
      fromDate.setDate(fromDate.getDate() + 1);
      const dayOfWeek = fromDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6)
        numberOfWorkingDays += 1; 
    }

    return numberOfWorkingDays;
  }

  return (
    <div className={styles.tooltipDefaultContainer} style={style}>
      <b style={{ fontSize: fontSize + 6 }}>
        {
          `${task.name}: ${task.start.getDate()}-${task.start.getMonth() + 1}-${task.start.getFullYear()} t.o.m. ${taskEnd.getDate()}-${taskEnd.getMonth() + 1}-${taskEnd.getFullYear()}`
        }
      </b>
      {taskEnd.getTime() - task.start.getTime() !== 0 && (
          <p className={styles.tooltipDefaultContainerParagraph}>
            {`Längd: ${getNumberOfWorkingDays(task.start, taskEnd)} arbetsdagar`}
          </p>
      )}

      {task.project &&
        <p className={styles.tooltipDefaultContainerParagraph}>
          {`Utförare: ${task.project}`}
        </p>
      }

      <p className={styles.tooltipDefaultContainerParagraph}>
        {!!task.progress && `Utfört: ${task.progress} %`}
      </p>
    </div>
  );
};