import { put, call, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';

export default function* workspaceSaga() {
  yield takeEvery('SET_WORKSPACE', function* (action) {
    yield call([localStorage, localStorage.setItem], 'workspace.id', action.payload.id || '');
    yield call([localStorage, localStorage.setItem], 'workspace.name', action.payload.name || '');
    yield call([localStorage, localStorage.setItem], 'workspace.type', action.payload.type || '');
    yield put(push('/' + (action.payload.type || '')));
  });
}
