import React from 'react';
import { useDispatch } from "react-redux";
import { AppBar, Toolbar, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LogoutButton from './buttons/LogoutButton';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    appBar: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
    },
    logo: {
      cursor: "pointer",
      maxHeight: '64px',
    },
    projectFinder: {
        marginLeft: theme.spacing(3),
    },
}));

const EmptyAppBar = props => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onLogoClick = () => {
    dispatch({ type: "SET_WORKSPACE", payload: { type: '', id: '', name: '' }});
  };

  return (
    <AppBar {...props} className={classes.appBar} position="sticky" title="Weavra admin">
      <Toolbar>
        <Grid container>
          <Grid container item justify="flex-start" alignItems="center" xs={3}>
              <img src={process.env.PUBLIC_URL + '/images/weavra-horizontal-small.png'} alt="Weavra logotype" className={classes.logo} onClick={onLogoClick} title="Till startsidan" />
          </Grid>
          <Grid container item justify="center" alignItems="center" xs={6}>
              <Typography variant="h6" id="react-admin-title" />
          </Grid>
          <Grid container item justify="flex-end" alignItems="center" xs={3}>
              <LogoutButton />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default EmptyAppBar;