import React from 'react';
import {
    Toolbar,
    SaveButton,
} from 'react-admin';

import { makeStyles } from '@material-ui/core';

import CancelButton from './buttons/CancelButton';
import SimpleDeleteButton from './buttons/SimpleDeleteButton';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const QuickDialogActions = ({ onCancel, isLoading, disabled, enableDelete, onDelete, record, ...props }) => {
    const classes = useStyles();
    return (
       <Toolbar {...props} classes={{ toolbar: classes.toolbar }}>
           <SaveButton submitOnEnter={true} disabled={disabled || isLoading} />
            { enableDelete && 
                <SimpleDeleteButton onClick={() => onDelete(record)} />
            }
           <CancelButton onClick={onCancel} disabled={isLoading} />
       </Toolbar>
    )
}

export default QuickDialogActions;