import React, { useCallback } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    DateInput,
    BooleanInput,
    Toolbar,
    SaveButton,
    useRedirect,
    required,
    FormGroupContextProvider,
    FormDataConsumer,
    useFormGroup,
    useCreate,
} from 'react-admin';
import { useForm } from 'react-final-form';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Grid, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import GMapDialogButtonInput from '../../shared/inputs/GMapDialogButtonInput';
//import DebouncedTextInput from '../../shared/inputs/DebouncedTextInput';
import MapsDataService from '../../data/MapsDataService';
import HelpButton from '../../shared/buttons/HelpButton';

const useStyles = makeStyles(theme => ({
    groupedInput: {
        width: '256px',
    },
    accordionRoot: {
        marginBottom: '12px',
        "& .MuiAccordionSummary-root": {
            "& .MuiAccordionSummary-content": {
                alignItems: 'center',
            },
        },
    },
    accordion: {
        flexDirection: 'column',
        marginBotton: '12px',
    },
}));

const AccordionSectionTitle = props => {
    const { children, name } = props;
    const formGroupState = useFormGroup(name);

    return (
        <Typography color={ formGroupState.invalid && formGroupState.dirty ? 'error' : 'inherit'}>
            {children}
        </Typography>
    )
}

const LocationTextInput = props => {
    const { source, label } = props;
    const classes = useStyles();

    return (
        <div className={ classes.groupedInput }>
            {/* <DebouncedTextInput source={source} label={label} delay={900} fullWidth /> */}
            <TextInput source={source} label={label} fullWidth />
        </div>
    )
}

const UseFormContainer = ({ children }) => {
    const form = useForm();
    if (typeof children === 'function')
        return children && children({ form });
}

const ProjectSaveButton = props => {
    const [create] = useCreate('company/projects');
    const redirectTo = useRedirect();

    const handleSave = useCallback((values, redirect) => {
        if (!values.coordinates && (values.city && values.streetAddress && values.zip)) {
            MapsDataService.fetchCoordinates(`${values.streetAddress}, ${values.zip}, ${values.city}`).then(coords => {
                create(
                    { payload: { data: { ...values, coordinates: coords || null } } },
                    {
                        onSuccess: () => {
                            redirectTo(redirect);
                        },
                        onFailure: (error) => {
                            console.log(error);
                        }
                    }
                )
            });
        } else {
            create(
                { payload: { data: {...values }}},
                {
                    onSuccess: () => {
                        redirectTo(redirect);
                    },
                    onFailure: (error) => {
                        console.log(error);
                    }
                }
            )
        }
    }, [create, redirectTo]);

    return (
        <SaveButton {...props} onSave={handleSave} />
    )
}

const ProjectCreateToolbar = props => {
    return (
        <Toolbar {...props}>
            <ProjectSaveButton submitOnEnter={false} />
        </Toolbar>
    )
}

const CompanyProjectCreate = props => {
    const classes = useStyles();
    
    const handleManualLocationChange = (address, form) => {
        if (!address) return;
        
        form.batch(() => {
            if (address.city) {
                form.change('city', address.city);
            }
            if (address.street) {
                form.change('streetAddress', address.street);
            }
            if (address.zip) {
                form.change('zip', address.zip);
            }
        });
    }

    const taxationIdValidation = (value) => {
        if (value && value.length > 15)
            return 'Max 15 tecken';
        
        return undefined;
    }

    const ledgerActiveValidation = (value, allValues) => {
        if (value === true) {
            if (!allValues.city || !allValues.streetAddress || !allValues.zip || !allValues.projectTaxationId)
                return 'SkatteId och komplett adress krävs för att aktivera personalliggaren';
        }

        return undefined;        
    }

    const validateTaxId = [taxationIdValidation];
    const validateLedgerActive = [ledgerActiveValidation];

    return (
        <Create {...props} title="Skapa nytt projekt">
            <SimpleForm redirect="/company/projects" toolbar={<ProjectCreateToolbar />}>
                <TextInput source="name" label="Projektnamn" validate={[required()]} />
                <TextInput multiline source="description" label="Beskrivning" />
                <TextInput source="orderNumber" label="ProjektID" validate={[required()]} />
                <TextInput source="orderer" label="Byggherre" />

                <Grid container style={{width: '256px'}}>
                    <Grid item xs={10}>
                        <DateInput source="startDate" label="Byggstart" validate={[required()]} />
                    </Grid>
                    <Grid item xs={2}>
                        <HelpButton textKey={'weavra.help.activeStatus'} />
                    </Grid>
                    <Grid item xs={10}>
                        <DateInput source="endDate" defaultValue={null} label="Byggavslut" />
                    </Grid>
                </Grid>  
                
                <FormGroupContextProvider name="location">
                    <Accordion className={classes.accordionRoot}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="location-content"
                            id="location-header"
                        >
                            <AccordionSectionTitle name="location">Arbetsplats</AccordionSectionTitle>
                            <HelpButton textKey={'weavra.help.openLocationSettings'} />
                        </AccordionSummary>
                        <AccordionDetails id="location-content" aria-labelledby="location-header" className={classes.accordion}>
                            <LocationTextInput source="city" label="Ort" />
                            <LocationTextInput source="streetAddress" label="Adress" />
                            <LocationTextInput source="zip" label="Postnummer" />                           
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <UseFormContainer>
                                        {({ form }) => (
                                            <GMapDialogButtonInput
                                                source="coordinates"
                                                city={formData.city}
                                                street={formData.streetAddress}
                                                zip={formData.zip}
                                                onAddressChange={(address) => handleManualLocationChange(address, form, formData)}
                                                canEdit={true}
                                                {...rest}
                                            />
                                        )}
                                    </UseFormContainer>
                                )}
                            </FormDataConsumer>
                        </AccordionDetails>
                    </Accordion>
                </FormGroupContextProvider>

                <TextInput source="projectTaxationId" label="weavra.project.taxationId" validate={validateTaxId} />

                <Grid container style={{width: '256px'}}>
                    <Grid item xs={10}>
                        <BooleanInput source="personnelLedgerActive" label="weavra.project.activeLedger" validate={validateLedgerActive} options={{ color: "secondary" }} />
                    </Grid>
                    <Grid item xs={2}>
                        <HelpButton textKey={'weavra.help.ledgerInfo'} /> 
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    );
}

export default CompanyProjectCreate;