import * as React from "react";
import { Route, Redirect } from 'react-router-dom';
import CompanyDashboard from '../company/CompanyDashboard';
import ProjectDashboard from '../project/ProjectDashboard';
import UserDashboard from '../user/UserDashboard';
import SuperAdminDashboard from "../superadmin/SuperAdminDashboard";
import ForgotPasswordPage from "./ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";
import WeAvraTheme from "./WeAvraTheme";

const CustomRoutes = [
    <Route exact path="/company" component={CompanyDashboard} />,
    <Route exact path="/project" component={ProjectDashboard} />,
    <Route exact path="/user" component={UserDashboard} />,
    <Route exact path="/admin" component={SuperAdminDashboard} />,
    <Route exact path="/forgotpassword" component={(props) => <ForgotPasswordPage {...props} theme={WeAvraTheme} />} noLayout />,
    <Route path="/resetpassword" component={(props) => <ResetPasswordPage {...props} theme={WeAvraTheme} />} noLayout />,
    <Route path="/general">
        <Redirect to="/" />
    </Route>,
];

export default CustomRoutes;
