import React from 'react';
import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    addButton: {
        cursor: 'pointer',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        heigth: '64px',
        width: '64px',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        background: 'transparent',
        transition: 'background 0.5s',
        border: `1px solid ${theme.palette.background.paper}`,
        borderRadius: '4px',
  
        "&:hover": {
          transition: 'background 0.5s',
          background: 'rgba(255,255,255,0.08)',
        },
    }
}));

const AddTaskButton = props => {
    const classes = useStyles();

    return (
        <div className={classes.addButton} onClick={() => props.onClick()} title="Skapa ny uppgift">
            <AddIcon />
        </div>
    );
}

export default AddTaskButton;