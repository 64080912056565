import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import { makeStyles } from '@material-ui/core/styles';

//TODO: get and show actual company logo (?)
//TODO: show inline...
//TODO: styling ?

const useStyles = makeStyles((theme) => ({
    wrap: props => ({
        cursor: 'pointer',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        background: 'transparent',
        transition: 'background 0.5s',
        borderRadius: props.big ? '4px' : 0,

        "&:hover": {
          transition: 'background 0.5s',
          background: theme.palette.background.default,
        },

        "& svg": {
            marginRight: props.big ? theme.spacing(2) : theme.spacing(1)
        }
    })
}))

const CompanyWorkspaceLink = props => {
    const dispatch = useDispatch();
    const companyId = localStorage.getItem('user.company.id');
    const companyName = localStorage.getItem('user.company.name');

    const handleClick = () => {
        dispatch({ type: 'SET_WORKSPACE', payload: { type: 'company', id: companyId, name: companyName }});
    };

    const classes = useStyles(props);
    const className = [classes.wrap];
    if (props.className) className.push(props.className);
    const variant = props.big ? 'h5' : 'subtitle1';

    return (
      <div className={className.join(' ')} onClick={() => {handleClick()}} title="Mitt företag">
        <BusinessIcon />
        <Typography variant={variant}>
          {companyName}
        </Typography>
      </div>
    );
}

export default CompanyWorkspaceLink;