import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
    useDataProvider,
    useRefresh,
    useNotify,
} from 'react-admin';
import { makeStyles, Grid } from '@material-ui/core';

import FormTree from './FormTree';
import FormEditor from './FormEditor';
import TaskEditor from './TaskEditor';
import SubTaskEditor from './SubTaskEditor';
import SubTaskOptionEditor from './SubTaskOptionEditor';
import SubTaskApproveBehaviourEditor from './SubTaskApproveBehaviourEditor';

const useStyles = makeStyles((theme) => ({
    formEditorRoot: {
        color: theme.palette.text.primary,
        margin: theme.spacing(2, 3),
        maxWidth: '60%',
    },
    bottomBox: {
        background: theme.palette.background.paper,
        borderRadius: '4px',
        padding: theme.spacing(2),
        minHeight: '180px',
    },
}));

const editTypes = {
    'form': 1,
    'task': 2,
    'subtask': 3,
    'subtaskoption': 4,
    'subtaskbehaviour': 5,
    'unknown': -1,
}

const getEditType = record => {
    if (record.tasks)
        return editTypes.form;
    else if (record.subTasks)
        return editTypes.task;
    else if (record.options)
        return editTypes.subtask;
    else if (record.hasOwnProperty('isConfirmation'))
        return editTypes.subtaskoption;
    else if (record.hasOwnProperty('rollbackMomentId'))
        return editTypes.subtaskbehaviour;
    else 
        return editTypes.unknown;
} 

const EditorBox = props => {
    const { editItem, onEditorSaved } = props;

    const classes = useStyles();

    const [saving, setSaving] = useState(false);

    const dataProvider = useDataProvider();
    
    const objectType = getEditType(editItem);

    const handleSaveEditItem = useCallback(async (record, resource) => {
        console.group();
        setSaving(true);
        
        if (record.id) {
            dataProvider
                .update(resource, { id: record.id, data: record })
                .then(response => {
                    setSaving(false);
                    onEditorSaved(true, 'updated', record);
                })
                .catch(error => {
                    setSaving(false);
                    onEditorSaved(false, error.message, record);
                    console.log('Fel fel fel', error, saving);
                });
        } else {

            dataProvider
                .create(resource, { data: record })
                .then(response => {
                    setSaving(false);
                    onEditorSaved(true, 'created', record);
                })
                .catch(error => {
                    setSaving(false);
                    //notify(`Det gick inte att spara: ${error.message}`, 'error');
                    console.log('Fel fel fel', error, saving);
                    onEditorSaved(false, error.message, record);
                });
        }
        setSaving(false);
        console.groupEnd();
    }, [dataProvider, onEditorSaved, saving]);

    const handleSaveForm = useCallback(async formValues => {
        // Hacky-hack children
        // TODO: Hitta och fixa grundproblemet... 
        formValues.tasks = editItem.tasks;

        await handleSaveEditItem(formValues, 'superadmin/taskforms');
    }, [handleSaveEditItem, editItem]);

    const handleSaveTask = useCallback(async taskValues => {
        // Hacky-hack children
        // TODO: Hitta och fixa grundproblemet... 
        taskValues.subTasks = editItem.subTasks;

        await handleSaveEditItem(taskValues, 'general/projecttasks');
    }, [handleSaveEditItem, editItem]);

    const handleSaveSubTask = useCallback(async subTaskValues => {
        // Hacky-hack children
        // TODO: Hitta och fixa grundproblemet... 
        subTaskValues.options = editItem.options;

        await handleSaveEditItem(subTaskValues, 'general/projectsubtasks');
    }, [handleSaveEditItem, editItem]);

    const handleSaveSubTaskOption = useCallback(async values => {
        await handleSaveEditItem(values, 'general/projectsubtaskoptions')
    }, [handleSaveEditItem]);

    const handleSaveSubTaskBehaviour = useCallback(async values => {
        await handleSaveEditItem(values, 'general/projectsubtaskapprovebehaviours')
    }, [handleSaveEditItem]);

    const handleDeleteTask = (record) => {
        handleDelete(record, 'general/projecttasks');
    }

    const handleDeleteSubTask = (record) => {
        handleDelete(record, 'general/projectsubtasks');
    }

    const handleDeleteSubTaskOption = (record) => {
        handleDelete(record, 'general/projectsubtaskoptions');
    }

    const handleDeleteSubTaskBehaviour = (record) => {
        handleDelete(record, 'general/projectsubtaskapprovebehaviours');
    }

    const handleDelete = (record, resource) => {
        setSaving(true);
        dataProvider
            .delete(resource, { id: record.id })
            .then(response => {
                setSaving(false);
                onEditorSaved(true, 'deleted', record);
            })
            .catch(error => {
                setSaving(false);
                //notify(`Det gick inte att spara: ${error.message}`, 'error');
                console.log('Fel fel fel', error, saving);
                onEditorSaved(false, error.message, record);
            });
        setSaving(false);
    }

    const handleAddNewTask = (form) => {
        // const maxSortIndex = Math.max(...moment.tasks.map(o => o.sortIndex), 0);
        const newTask = {
            name: 'Ny uppgift',
            taskFormId: form.id,
            //sortIndex: maxSortIndex + 1,
            subTasks: [],
        };

        handleSaveTask(newTask);
    }

    const handleAddNewSubTask = (task) => {
        const maxSortIndex = Math.max(...task.subTasks.map(o => o.sortIndex), 0);
        const newSubTask = {
            name: 'Ny underuppgift',
            taskId: task.id,
            sortIndex: maxSortIndex + 1,
            subTaskType: 0,
            options: []
        };

        handleSaveSubTask(newSubTask);
    }

    const handleAddApproveBehaviour = (subTask) => {
        const newBehaviour = {
            subTaskId: subTask.id,
            rollbackMomentId: subTask.task.taskFormId, // TODO: hur hanterar vi rollbacks med formulär?
            hasNotification: false,
            // TODO: Default notification? How to add notification later on? Possible? Find out!
        }

        handleSaveSubTaskBehaviour(newBehaviour);
    }

    const handleAddSubTaskOption = (subTask) => {
        const newOption = {
            name: 'Nytt val',
            subTaskId: subTask.id,
            isConfirmation: false,
        }

        handleSaveSubTaskOption(newOption);
    }

    const formSaveContext = useMemo(() => ({
        save: handleSaveForm,
        saving
    }), [saving, handleSaveForm]);

    const taskSaveContext = useMemo(() => ({
        save: handleSaveTask,
        saving
    }), [saving, handleSaveTask]);

    const subTaskSaveContext = useMemo(() => ({
        save: handleSaveSubTask,
        saving
    }), [saving, handleSaveSubTask]);

    const subTaskOptionSaveContext = useMemo(() => ({
        save: handleSaveSubTaskOption,
        saving
    }), [saving, handleSaveSubTaskOption]);

    const getEditor = () => {
        switch(objectType) {
            case editTypes.form:
                return (
                    <FormEditor 
                        record={editItem}
                        saveContext={formSaveContext}
                        onSave={handleSaveForm}
                        onAddNewTask={handleAddNewTask}
                    />
                )
            case editTypes.task:
                return <TaskEditor 
                    record={editItem}
                    saveContext={taskSaveContext}
                    onSave={handleSaveTask}
                    onDelete={handleDeleteTask}
                    onAddNewSubTask={handleAddNewSubTask}
                />
            case editTypes.subtask:
                return <SubTaskEditor 
                    record={editItem}
                    saveContext={subTaskSaveContext}
                    onSave={handleSaveSubTask}
                    onDelete={handleDeleteSubTask}
                    onAddApproveBehaviour={handleAddApproveBehaviour}
                    onAddSubTaskOption={handleAddSubTaskOption}    
                />
            case editTypes.subtaskoption:
                return <SubTaskOptionEditor 
                    record={editItem}
                    saveContext={subTaskOptionSaveContext}
                    onSave={handleSaveSubTaskOption}
                    onDelete={handleDeleteSubTaskOption}
                />
            case editTypes.subtaskbehaviour:
                return <SubTaskApproveBehaviourEditor 
                    record={editItem}
                    onSave={handleSaveSubTaskBehaviour}
                    onDelete={handleDeleteSubTaskBehaviour}
                    moments={null}
                />
            default:
                return <div>Fel</div>
        }
    }

    return (
        <div className={classes.bottomBox}>
            {getEditor()}
        </div>
    );
}

const Editor = props => {
    const { currentForm, onFormSelected } = props;

    const classes = useStyles();

    const [editForm, setEditForm] = useState(currentForm);
    const [editItem, setEditItem] = useState(currentForm);

    useEffect(() => {
        setEditForm(currentForm);
        setEditItem(currentForm);
    }, [currentForm])

    const refresh = useRefresh();
    const notify = useNotify();

    const handleSelectEditItem = (item) => {
        if (item.tasks)
            setEditForm(item);
        
        setEditItem(item);
    }

    const handleEditorSaved = (success, message, record) => {
        // TODO: Try to set state of editor/tree after save, before we refresh view...
        if (success) {
            notify('ra.notification.updated', 'info', { smart_count: 1 });
            refresh();

            if (record.tasks)
                onFormSelected(record);
        
            setEditItem(record);
        } else {
            notify('ra.notification.http_error', 'error');
        }
    }

    return (
        <div className={classes.formEditorRoot}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    {currentForm &&
                        <FormTree currentForm={editForm} selectedItem={editItem} onSelectEditItem={handleSelectEditItem}  />
                    }
                </Grid>
                <Grid item xs={8}>
                    { editItem &&
                        <EditorBox 
                            editItem={editItem}
                            onEditorSaved={handleEditorSaved}
                        />
                    }
                </Grid>
            </Grid>
        </div>
    );
}

export default Editor;