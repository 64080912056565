const googleKey = process.env.REACT_APP_GOOGLEKEY;
//const getAddressComponent = (typeName, record) => record.address_components.find(x => x.types.includes(typeName))?.long_name || null;
const getCoords = (googleRes) => {
    const lat = googleRes.results[0].geometry.location.lat;
    const lng = googleRes.results[0].geometry.location.lng;

    return { lat, lng };
}

const MapsDataService = {

    fetchCoordinates: async addressString => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=+${encodeURI(addressString)}&key=${googleKey}`;

        const res = await fetch(url);
        const json = await res.json();

        if (json.status === 'OK') {
            return getCoords(json);
        } else if (json.status === 'ZERO_RESULTS') {
            console.log('Google found no location...', addressString);
            return null;
        }
    },

    // fetchAddress: async coordinates => {
    //     const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&result_type=street_address&key=${googleKey}`;

    //     await fetch(url)
    //         .then(res => res.json())
    //         .then(res => {
    //             if (res.status === 'OK') {
    //                 const streetName = getAddressComponent('route', res.results[0]);
    //                 const streetNumber = getAddressComponent('street_number', res.results[0]);

    //                 const foundAddress = {
    //                     city: getAddressComponent('postal_town', res.results[0]),
    //                     street: streetName ? `${streetName} ${streetNumber}` : null,
    //                     zip: getAddressComponent('postal_code', res.results[0])
    //                 };
                    
    //                 setAddress(foundAddress);
    //             } else if (res.status === 'ZERO_RESULTS') {
    //                 // TODO: setAddress(null)?
    //                 console.log('Google found no address...', res);
    //             }
    //         })
    //         .catch(console.error);
    // },
}

export default MapsDataService;