import * as React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  AdminContext,
  AdminUI,
  Resource,
} from 'react-admin';

//icons
import { Work, Event, CalendarToday, People, Description, CheckBox } from '@material-ui/icons';

//providers and stuff
import WeAvraAdminDataProvider from './data/WeAvraAdminDataProvider';
import AuthProvider from './auth/AuthProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import swedish from './i18n/swedish';

//custom reducers & sagas
import userReducer from './redux/reducers/userReducer';
import workspaceReducer from './redux/reducers/workspaceReducer';
import userSaga from './redux/sagas/userSaga';
import workspaceSaga from './redux/sagas/workspaceSaga';

//theme and layout
import WeAvraTheme from './shared/WeAvraTheme';
import CustomRoutes from './shared/CustomRoutes';
import LoginPage from './shared/LoginPage';
import EmptyLayout from './shared/EmptyLayout';
import DefaultLayout from './shared/DefaultLayout';

//dashboards
import EmptyDashboard from './shared/EmptyDashboard';

//list, create, edit
import CompanyProjectList from './company/projects/CompanyProjectList';
import CompanyProjectCreate from './company/projects/CompanyProjectCreate';
//import CompanyProjectEdit from './company/projects/CompanyProjectEdit'; // TODO: Remove
//import { ProjectContactCreate, ProjectContactEdit } from './company/projects/projectContacts'; // TODO: Remove?
//import { ProjectMomentCreate, ProjectMomentEdit } from './company/projects/projectMoments'; // TODO: Remove?
//import { ProjectTaskCreate, ProjectTaskEdit } from './company/projects/projectTasks'; // TODO: Remove?
//import { ProjectSubTaskCreate, ProjectSubTaskEdit } from './company/projects/projectSubTasks'; // TODO: Remove?
//import { ProjectSubTaskApproveBehaviourCreate, ProjectSubTaskApproveBehaviourEdit } from './company/projects/projectSubTaskApproveBehaviours'; // TODO: Remove?
//import { ProjectSubTaskOptionCreate, ProjectSubTaskOptionEdit } from './company/projects/projectSubTaskOptions'; // TODO: Remove?
//import { ProjectNotificationCreate, ProjectNotificationEdit } from './company/projects/projectNotifications';
//import { ProjectDocumentEdit } from './company/projects/projectDocuments'; // TODO: Remove?
//import { ProjectIncidentReportCreate, ProjectIncidentReportEdit } from './company/projects/projectIncidentReports'; // TODO: Remove?
import CompanyDocumentList from './company/documents/CompanyDocumentList';
import CompanyUserList from './company/users/CompanyUserList';
//import CompanyPlanning from './company/CompanyPlanning';
import ProjectTasksChecklist from './project/ProjectTasksChecklist';
import ProjectTasksTimeplan from './project/ProjectTasksTimeplan';
import ProjectDocumentList from './project/ProjectDocumentsList';
import ProjectContacts from './project/ProjectContacts';
import ProjectEvents from './project/ProjectEvents';
//import UserDocuments from './user/UserDocuments';
import UserProjects from './user/UserProjects';
import ProjectPersonnelLedger from './project/ProjectPersonnelLedger';

const initialState = {
  user: {
    name: localStorage.getItem('user.name') || '',
    companyId: localStorage.getItem('user.company.id') || '',
    companyName: localStorage.getItem('user.company.name') || '',
    id: localStorage.getItem('user.id') || '',
  },
  workspace: {
    id: localStorage.getItem('workspace.id') || '',
    name: localStorage.getItem('workspace.name') || '',
    type: localStorage.getItem('workspace.type') || '',
  }
};

const messages = {
  sv: swedish
};

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'sv');

const App = () => {
    return (
        <AdminContext
            disableTelemetry
            i18nProvider={i18nProvider}
            dataProvider={WeAvraAdminDataProvider}
            authProvider={AuthProvider}
            customRoutes={CustomRoutes}
            customReducers={{ workspace: workspaceReducer, user: userReducer }}
            customSagas={[ userSaga, workspaceSaga ]}
            initialState={initialState}>
            <AdminResources />
        </AdminContext>
    );
}

// TODO: Put all our resource data here
// TODO: Move to it's own file (or get from API)
// TODO: Work to remove the temporary general/<xxx> stuff, it's a bit wonky
const allResources = [
  { name: 'start', workspace: '', props: { list: () => <div/> } },

  //Use the general/<xxx> resources for temporary stuff from old admin
  { name: 'general/projects', workspace: 'all', props: { list: null } }, // Used in multiple components
  { name: 'general/companies', workspace: 'all', props: { list: null } }, // Used in CompanyProjectCreate.js
  { name: 'general/projectusers', workspace: 'all', props: { list: null } }, 
  //{ name: 'general/projectcontacts', workspace: 'all', props: { list: null, create: ProjectContactCreate, edit: ProjectContactEdit } }, // TODO: Remove
  //{ name: 'general/projectmoments', workspace: 'all', props: { list: null, create: ProjectMomentCreate, edit: ProjectMomentEdit } }, // TODO: Remove
  { name: 'general/projecttasks', workspace: 'all', props: { list: null, create: null, edit: null } }, // Used in ProjectPlanning.js
  { name: 'general/projectsubtasks', workspace: 'all', props: { list: null, create: null, edit: null } }, // Used in ProjectPlanning.js
  { name: 'general/projectsubtaskapprovebehaviours', workspace: 'all', props: { list: null, create: null, edit: null } }, // Used in ProjectPlanning.js
  { name: 'general/projectsubtaskoptions', workspace: 'all', props: { list: null, create: null, edit: null } }, // Used in ProjectPlanning.js
  //{ name: 'general/projectdocuments', workspace: 'all', props: { list: null, edit: ProjectDocumentEdit } }, // TODO: Remove?
  { name: 'general/projectincidentreports', workspace: 'all', props: { list: null, create: null, edit: null } }, // Used in ProjectEvents.js
  { name: 'general/taskforms', workspace: 'all', props: { list: null, create: null, edit: null } },

  { name: 'user/documents', workspace: 'user', props: { list: null, icon: Description, create: null, edit: null, options: { label: 'weavra.navigation.documents' }} },
  { name: 'user/projects', workspace: 'user', props: { list: UserProjects, icon: Work, create: null, edit: null, options: { label: 'weavra.navigation.my_projects' } } },

  { name: 'project/checklist', workspace: 'project', props: { list: ProjectTasksChecklist, icon: CheckBox, create: null, edit: null, options: { label: 'weavra.navigation.checklist' } } },
  { name: 'project/timeplan', workspace: 'project', props: { list: ProjectTasksTimeplan, icon: CalendarToday, create: null, edit: null, options: { label: 'weavra.navigation.timeplan' } } },
  { name: 'project/projectmoments', workspace: 'project', props: {list: null, create: null, edit: null }},
  { name: 'project/documents', workspace: 'project', props: { list: ProjectDocumentList, edit: null, icon: Description, options: { label: 'weavra.navigation.documents' }} },
  { name: 'project/events', workspace: 'project', props: { list: ProjectEvents, edit: null, icon: Event, options: { label: 'weavra.navigation.events' } } },
  // { name: 'project/projectnotifications', workspace: 'project', props: { list: null, create: ProjectNotificationCreate, edit: ProjectNotificationEdit } },
  { name: 'project/contacts', workspace: 'project', props: { list: ProjectContacts, edit: null, icon: People, options: { label: 'weavra.navigation.contacts' } } },
  { name: 'project/personnelledger', workspace: 'project', props: { list: ProjectPersonnelLedger, edit: null, icon: People, options: { label: 'weavra.navigation.ledger', showFor: ['Admin', 'ProjectManager'] } } },

  { name: 'company/projects', workspace: 'company', props: { list: CompanyProjectList, icon: Work, create: CompanyProjectCreate, edit: null, options: { label: 'weavra.navigation.all_projects' }} },
  { name: 'company/planning', workspace: 'company', props: { list: null, icon: CalendarToday, options: { label: 'weavra.navigation.planning' }} },
  { name: 'company/documents', workspace: 'company', props: { list: CompanyDocumentList, edit: null, icon: Description, options: { label: 'weavra.navigation.companyDocuments' }} },
  { name: 'company/projectusers', workspace: 'company', props: { list: CompanyUserList, create: null, edit: null, icon: People, options: { label: 'weavra.navigation.users' }} },
  { name: 'company/companyusergroups', workspace: 'company', props: { list: null } },
  { name: 'company/projectuserfiles', workspace: 'company', props: { list: null } },

  //Superadmin
  { name: 'superadmin/companies', workspace: 'admin', props: { list: null, create: null } },
  { name: 'superadmin/forms', workspace: 'admin', props: { list: null, create: null } },
  { name: 'superadmin/userAgreements', workspace: 'admin', props: { list: null, create: null } },
  { name: 'superadmin/projects', workspace: 'admin', props: { list: null, create: null } },

];

const getWorkspaceResources = (workspace) => {
  // TODO: come up with a better way to handle resources over all...
  const resources = !workspace ? allResources.filter(r => r.name === 'start' || r.workspace === 'admin' || r.workspace === 'all') : allResources.filter(r => r.workspace === workspace || r.workspace === 'all');
  return Promise.resolve(resources);
}

//TODO: Move to AdminResources.js? Rename?
const AdminResources = () => {
  const [resources, setResources] = useState([]);

  //Get the current workspace from state/store
  const currentWorkspace = useSelector(state => state.workspace.type);
  let currentLayout = null;
  let currentDashboard = null;
  let currentReady = () => <span/>;

  //Get resources for current workspace
  useEffect(() => {
    getWorkspaceResources(currentWorkspace).then(r => setResources(r));
  }, [currentWorkspace]);

  //Set stuff up for different workspaces...
  switch (currentWorkspace) {
    case 'company':
    case 'user':
    case 'project':
    case 'general': // TODO: 'general' should be 'all'?
      currentLayout = DefaultLayout;
      currentDashboard = null;
      break;
    case 'admin':
      console.log('Alert! 👀 Setting workspace to admin');
      currentLayout = EmptyLayout;
      currentDashboard = null;
      break;
    default:
      currentLayout = EmptyLayout;
      currentDashboard = EmptyDashboard;
      break;
  }

  return (
    <AdminUI
      theme={WeAvraTheme}
      title="Weavra Admin"
      customRoutes={CustomRoutes}
      loginPage={LoginPage}
      layout={currentLayout}
      dashboard={currentDashboard}
      ready={currentReady}>
      {resources.map(r =>
          <Resource name={r.name} key={r.name} {...r.props} />
      )}
    </AdminUI>
  );
}

export default App;
