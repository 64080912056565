import * as React from 'react';
import { 
    useQuery,
    Loading,
    Error,
} from 'react-admin';

const SuperAdminActiveProjects= () => {

    // Hämta aktiva projekt från backend
    const { data: projects, loaded, error } = useQuery(
        {
            type: 'getList',
            resource: 'superadmin/projects',
            payload: {
                pagination: { page: 1, perPage: 999 },
                sort: { field: 'name', order: 'ASC' },
                filter: { activeOnly: true },
                getSearchResults: true
            }
        }
    );

    if (!loaded)
        return <Loading />

    if (error)
        return <Error />

    return (
        <div>
            <table style={{ borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <td><h4>Projektnamn</h4></td>
                        <td><h4>Projektägare</h4></td>
                    </tr>
                </thead>
                <tbody>
                    { projects && projects.map(project => 
                        <tr key={project.id}>
                            <td style={{ border: '1px solid #A7A8AA', padding: '8px' }}>{project.name}</td>
                            <td style={{ border: '1px solid #A7A8AA', padding: '8px' }}>{project.ownerName}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default SuperAdminActiveProjects;