import * as React from 'react'
import { useState } from 'react'
import { useLogin, useNotify, useTranslate, Link, Notification } from 'react-admin'
import { Card, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    logo: {
        maxWidth: '400px',
        marginTop: '-120px',
    },
    card: {
        textAlign: 'center',
        padding: theme.spacing(4),
    },
    title: {
        marginTop: '0',
    },
    form: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    input: {
        width: 300,
        marginBottom: theme.spacing(2),
    },
    forgot: {
        cursor: 'pointer',
    }
}));

const LoginForm = (props) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const classes = useStyles()
    const login = useLogin()
    const notify = useNotify()
    const translate = useTranslate()
    const submit = e => {
        e.preventDefault()
        login({ username, password })
            .catch(() => {
                notify('ra.auth.sign_in_error', 'error');
            })
    }

    return <React.Fragment>
        <img src={process.env.PUBLIC_URL + '/images/weavra-horizontal.png'} alt="" className={classes.logo} />
        <Card className={classes.card}>
            <h1 className={classes.title}>{translate('weavra.login.title')}</h1>
            <form onSubmit={submit} className={classes.form}>
                <TextField
                    placeholder={translate('weavra.login.email')}
                    name="email"
                    type="email"
                    variant="outlined"
                    value={username}
                    className={classes.input}
                    onChange={e => setUsername(e.target.value)}
                />
                <TextField
                    size="medium"
                    placeholder={translate('weavra.login.password')}
                    name="password"
                    type="password"
                    variant="outlined"
                    value={password}
                    className={classes.input}
                    onChange={e => setPassword(e.target.value)}
                />
                <Button type="submit" size="large" variant="contained">{translate('weavra.login.submit')}</Button>
            </form>
            <br/>
            <Link to={"/forgotpassword"}>
                <span className={classes.forgot}>Glömt lösenord?</span>
            </Link>
        </Card>
        <Notification />
    </React.Fragment>
}

export default LoginForm
