import React, {
  Component,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import {
  createMuiTheme,
  withStyles,
  createStyles,
} from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Sidebar, Notification, Error } from "react-admin";
import compose from "lodash/flowRight";

import DefaultAppBar from "./DefaultAppBar";
import Menu from "./Menu";

const styles = (theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      zIndex: 1,
      minHeight: "100vh",
      backgroundColor: theme.palette.background.default,
      position: "relative",
      minWidth: "fit-content",
      width: "100%",
      color: theme.palette.text.primary,
    },
    appFrame: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    contentWithSidebar: {
      display: "flex",
      flexGrow: 1,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      flexBasis: 0,
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
  });

class LayoutWithoutTheme extends Component {
  state = { hasError: false, errorMessage: null, errorInfo: null };

  constructor(props) {
    super(props);
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     */
    props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo });
  }

  render() {
    const {
      appBar,
      children,
      classes,
      className,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open,
      sidebar,
      title,
      // sanitize react-router props
      match,
      location,
      history,
      workspace,
      clearWorkspace,
      staticContext,
      ...props
    } = this.props;

    if (location.pathname.indexOf("/" + workspace) !== 0 && location.pathname.indexOf("/general") !== 0) {
      clearWorkspace();
    }

    const { hasError, errorMessage, errorInfo } = this.state;
    return (
      <>
        <div
          className={classnames("layout", classes.root, className)}
          {...props}
        >
          <div className={classes.appFrame}>
            {createElement(appBar, { title, open, logout })}
            <main className={classes.contentWithSidebar}>
              {createElement(sidebar, {
                id: "sidebar",
                children: createElement(menu, {
                  logout,
                  hasDashboard: !!dashboard,
                }),
              })}
              <div id="main-content" className={classes.content}>
                <Typography variant="h6" id="react-admin-title" />
                {hasError
                  ? createElement(error, {
                      error: errorMessage,
                      errorInfo,
                      title,
                    })
                  : children}
              </div>
            </main>
          </div>
        </div>
        {createElement(notification)}
      </>
    );
  }

  static defaultProps = {
    appBar: DefaultAppBar,
    error: Error,
    menu: Menu,
    notification: Notification,
    sidebar: Sidebar,
  };
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  workspace: state.workspace.type,
});

const mapDispatchToProps = dispatch => ({
  clearWorkspace: () => dispatch({ type: "SET_WORKSPACE", payload: { type: '', id: '', name: '' }})
});

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter,
  withStyles(styles, { name: "RaLayout" })
)(LayoutWithoutTheme);

const DefaultLayout = ({ theme: themeOverride, ...props }) => {
  const themeProp = useRef(themeOverride);
  const [theme, setTheme] = useState(createMuiTheme(themeOverride));

  useEffect(() => {
    if (themeProp.current !== themeOverride) {
      themeProp.current = themeOverride;
      setTheme(createMuiTheme(themeOverride));
    }
  }, [themeOverride, themeProp, theme, setTheme]);

  return (
    <ThemeProvider theme={theme}>
      <EnhancedLayout {...props} />
    </ThemeProvider>
  );
};

DefaultLayout.propTypes = {
  theme: PropTypes.object,
};

export default DefaultLayout;
