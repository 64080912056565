import { call, takeEvery } from 'redux-saga/effects';

export default function* userSaga() {
  yield takeEvery('SET_USER', function* (action) {
    yield call([localStorage, localStorage.setItem], 'user.name', action.payload.name || '');
    yield call([localStorage, localStorage.setItem], 'user.company.id', action.payload.companyId || '');
    yield call([localStorage, localStorage.setItem], 'user.company.name', action.payload.companyName || '');
    yield call([localStorage, localStorage.setItem], 'user.id', action.payload.id || '');
  });
}
