import { Button } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import { Form } from 'react-final-form';
 
export const FormDialog = ({
    open,
    loading,
    onSubmit,
    onCancel,
    title,
    cancelLabel,
    submitLabel,
    children,
    initialValues,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            fullWidth
        >
            
            <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        {title && (
                            <DialogTitle>{title}</DialogTitle>
                        )}
                        <DialogContent>
                            {children}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                label={cancelLabel || 'ra.action.cancel'}
                                onClick={onCancel}
                                disabled={loading}
                            />
                            <Button
                                label={submitLabel || 'ra.action.save'}
                                type="submit"
                                disabled={loading}
                            />
                        </DialogActions>
                    </form>
                )}
            />

        </Dialog>
    );
}