import React from 'react';
import {
    Button,
    useTranslate,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';

const CancelButton = (props) => {
    const translate = useTranslate();
    return (
        <Button label={translate('ra.action.cancel')} {...props}>
            <IconCancel />
        </Button>
    )
}

export default CancelButton;