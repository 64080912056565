import React, { useState } from 'react';
import {
    useRefresh,
    useNotify,
    useDataProvider,
    useQueryWithStore,
    Loading,
    Error,
    TextInput,
    TextField,
    BooleanInput,
    DateField,
    SimpleForm,
 } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { FormControl, InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import QuickDialogActions from '../shared/QuickDialogActions';

const useStyles = makeStyles((theme) => ({
    addButton: {
        cursor: 'pointer',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        heigth: '64px',
        width: '128px',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        background: 'transparent',
        transition: 'background 0.5s',
        border: `1px solid ${theme.palette.background.paper}`,
        borderRadius: '4px',
  
        "&:hover": {
          transition: 'background 0.5s',
          background: theme.palette.background.default,
        },
    },
    formContainer: {
        width: '50%',
    },
    selectorContainer: {
        width: '50%',
        padding: '16px',
    },
}));

const AgreementEditor = (props) => {
    const { record, onSaveAgreement, onCancel, isSaving } = props;

    return (
        <SimpleForm
            record={record}
            save={onSaveAgreement}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isSaving} />}
        >
            <TextInput source="name" label="Namn" />
            <RichTextInput source="body" label="Avtalstext" fullWidth />
            { record.id &&
                <DateField source="created" label="Skapad" />
            }
            {record.id &&
                <DateField source="lastModified" label="Senast ändrad" />
            }
            { record.id &&
                <TextField source="version" label="Version" />
            }
            <BooleanInput source="active" label="Aktiv" />
            { record.id &&
                <BooleanInput source="saveAsNewVersion" label="Spara som ny version" />
            }
        </SimpleForm>
    );
}

const SuperAdminUserAgreement = () => {

    const [currentAgreement, setCurrentAgreement] = useState(null);
    const [isSaving, setSaving] = useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const classes = useStyles();

    // Hämta agreements
    const { data: agreementsData, loaded, error } = useQueryWithStore(
        {
            type: 'getList',
            resource: 'superadmin/userAgreements',
            payload: {
                pagination: { page: 1, perPage: 999 },
                sort: { field: 'name', order: 'ASC' }
            }
        }
    );

    const handleChange = (event) => {
        console.log(event.target.value);
        setCurrentAgreement(agreementsData.filter(a => a.id === event.target.value)[0]);
    }

    const handleClickNew = () => {
        const newAgreement = {
            id: null,
            name: 'Nytt avtal',
            active: false,
        }

        setCurrentAgreement(newAgreement);
    }

    const handleSaveAgreement = (record) => {

        setSaving(true);

        if (!record.id) {
            dataProvider
                .create('superadmin/userAgreements', { data: record })
                .then(response => {
                    setSaving(false);
                    notify('ra.notification.created', 'info');
                    refresh();
                })
                .catch(error => {
                    setSaving(false);
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    refresh();
                });
            return;
        }

        dataProvider
            .update('superadmin/userAgreements', { id: record.id, data: record })
            .then(response => {
                setSaving(false);
                notify('ra.notification.updated', 'info');
                refresh();
            })
            .catch(error => {
                setSaving(false);
                console.log('Fel fel fel', error);
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                refresh();
            });
    }

    const handleCancel = () => {
        setCurrentAgreement(null);
    }

    if (!loaded)
        return <Loading />
    
    if (error)
        return <Error />

    return (
        <div>
            <div className={classes.selectorContainer}>
                <FormControl margin="normal" size="medium">
                    <InputLabel id="select-agreement-label" variant="filled">Välj avtal</InputLabel>
                    <Select
                        id="select-agreement"
                        labelId="select-agreement-label"
                        value={currentAgreement?.id ?? null}
                        variant="filled"
                        onChange={handleChange}
                        autoWidth
                    >
                        <MenuItem value={null}>- Välj avtal-</MenuItem>
                        {agreementsData.map(agreement =>
                            <MenuItem key={agreement.id} value={agreement.id}>{agreement.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>

                <div className={classes.addButton} onClick={() => handleClickNew()} title="Nytt avtal">
                    <AddIcon /> Nytt avtal
                </div>
            </div>
            {currentAgreement &&
                <div className={classes.formContainer}>    
                    <AgreementEditor
                        record={currentAgreement}
                        onSaveAgreement={handleSaveAgreement}
                        onCancel={handleCancel}
                        isSaving={isSaving}
                    />
                </div>
            }
        </div>
    );
}

export default SuperAdminUserAgreement;