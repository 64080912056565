import * as React from 'react';
import { useDispatch } from 'react-redux';
import { 
    Title,
    useQuery,
    useNotify,
    Loading,
    Error,
    useTranslate,
    usePermissions,
} from 'react-admin';
import { CssBaseline, Grid, Paper, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ProjectAutocompleteSelect from './ProjectAutocompleteSelect';
import CompanyWorkspaceLink from './CompanyWorkspaceLink';
import UserWorkspaceLink from './UserWorkspaceLink';
import SuperAdminDashboard from '../superadmin/SuperAdminDashboard';
import HelpButton from './buttons/HelpButton';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        padding: theme.spacing(2),
    },
    paper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    column: {
        flexFlow: 'column nowrap'
    }
}));

const EmptyDashboard = () => {
    const notify = useNotify();
    const dispatch = useDispatch();
    const translate = useTranslate();
    const { loaded : permissionsLoaded, permissions } = usePermissions();
    const classes = useStyles();

    // TODO: do this on other dashboards too if user data is missing from localStorage (example: user logged out, then in again)
    const { loaded, error, data } = useQuery({
        type: 'getOne',
        resource: 'currentUserData',
        payload: {}
    }, {
        action: 'GET_USER_DATA',
        onSuccess: ({ data }) => {
          dispatch({ type: 'SET_USER', payload: { name: data.username, companyName: data.companyName, companyId: data.companyId, id: data.id }});
        },
        onFailure: (error) => notify(`Error getting user data: ${error.message}`, 'warning')
    });
    
    if (!loaded || !permissionsLoaded)
        return <Loading />
    if (error)
        return <Error />

    if (!data.companyId && permissions.includes('SuperAdmin'))
      return <SuperAdminDashboard />
    else
      return <React.Fragment>
        <div className={classes.root}>
          <Title title={translate('weavra.dashboard.title')} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper className={[classes.paper, classes.column].join(' ')}>
                <Card elevation={3}>
                  <CardContent>
                    <Typography variant="h5">{translate('weavra.dashboard.selectProject')} <HelpButton textKey={'weavra.help.projectSearch'} /></Typography>
                    <br/>
                    <ProjectAutocompleteSelect />       
                  </CardContent>
                </Card>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <div>
                  <CompanyWorkspaceLink big={true} />  
                </div>
                <HelpButton textKey={'weavra.help.companyPage'} />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                <div>
                  <UserWorkspaceLink big={true} />
                </div>
                <HelpButton textKey={'weavra.help.myPage'} />
              </Paper>
            </Grid>
          </Grid>
        </div>
        <CssBaseline/>
    </React.Fragment>
}

export default EmptyDashboard;
