import React, { useMemo } from "react";
import styles from "./TimeplanGanttTable.module.css";

const localeDateStringCache = {};

const toLocaleDateStringFactory = (locale) => (
    date,
    isEndDate,
    dateTimeOptions
) => {
    const key = date.toString();
    let lds = localeDateStringCache[key];
    if (!lds) {
        lds = date.toLocaleDateString(locale, dateTimeOptions);
        localeDateStringCache[key] = lds;
    }

    if (isEndDate) {
        const modified = new Date(date);
        modified.setDate(date.getDate() - 1);
        return modified.toLocaleDateString(locale, dateTimeOptions);
    }


    return lds;
};

const dateTimeOptions = {
  weekday: "short",
  //year: "2-digit",
  month: "short",
  day: "numeric",
};

export const TimeplanGanttTable = ({
    rowHeight,
    rowWidth,
    tasks,
    fontFamily,
    fontSize,
    locale,
    onExpanderClick,
    onMomentClick,
}) => {
    
    const toLocaleDateString = useMemo(() => toLocaleDateStringFactory(locale), [locale,]);
    
    return (
        <div
            className={styles.taskListWrapper}
            style={{
                fontFamily: fontFamily,
                fontSize: fontSize,
            }}
        >
            { tasks.map(t => {
                let expanderSymbol = "";
                if (t.hideChildren === false) {
                    expanderSymbol = "▼";
                } else if (t.hideChildren === true) {
                    expanderSymbol = "▶";
                }

                return (
                    <div
                        className={styles.taskListTableRow}
                        style={{ height: rowHeight }}
                        key={`${t.id}row`}
                    >
                        <div
                            className={styles.taskListCell}
                            style={{
                                minWidth: rowWidth,
                                maxWidth: rowWidth,
                            }}
                            title={t.name}
                            onClick={() => onMomentClick(t)}
                        >
                            <div className={styles.taskListNameWrapper} >
                                <div
                                    className={
                                        expanderSymbol
                                            ? styles.taskListExpander
                                            : styles.taskListEmptyExpander
                                    }
                                    onClick={() => onExpanderClick(t)}
                                >
                                    {expanderSymbol}
                                </div>
                                <div><strong>{t.name}</strong></div>
                                <div><i>{t.project ? t.project : 'Utförare saknas'}</i></div>
                            </div>
                        </div>
                    <div
                        className={styles.taskListCell}
                        style={{
                            minWidth: rowWidth,
                            maxWidth: rowWidth,
                        }}
                    >
                        &nbsp;{toLocaleDateString(t.start, false, dateTimeOptions)}
                    </div>
                    <div
                        className={styles.taskListCell}
                        style={{
                            minWidth: rowWidth,
                            maxWidth: rowWidth,
                        }}
                    >
                        &nbsp;{toLocaleDateString(t.end, true, dateTimeOptions)}
                    </div>
                </div>
            );
        })}
        </div>
    );
};