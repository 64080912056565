import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';

const useStyles = makeStyles((theme) => ({
    wrap: props => ({
        cursor: 'pointer',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        background: 'transparent',
        transition: 'background 0.5s',
        borderRadius: props.big ? '4px' : 0,

        "&:hover": {
          transition: 'background 0.5s',
          background: theme.palette.background.default,
        },

        "& svg": {
            marginRight: props.big ? theme.spacing(2) : theme.spacing(1)
        }
    })
}))

const UserWorkspaceLink = props => {
    const dispatch = useDispatch();
    const userName = useSelector(state => state.user.name);
    const handleClick = () => {
        dispatch({ type: 'SET_WORKSPACE', payload: { type: 'user', id: '', name: userName }});
    };
    const classes = useStyles(props);
    const className = [classes.wrap];
    if (props.className) className.push(props.className);
    const variant = props.big ? 'h5' : 'subtitle1';

    return (
        <div className={className.join(' ')} onClick={() => {handleClick()}} title="Användarvy">
            <PersonIcon />
            <Typography variant={variant}>
                {userName}
            </Typography>
        </div>
    );
}

export default UserWorkspaceLink;