import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    //Font,
    pdf,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
    page: {
        paddingTop: 24,
        paddingBottom: 60,
        paddingLeft: 36,
        paddingRight: 24,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    },
    pageHeader: {
        marginLeft: 18,
        marginRight: 24,
        marginBottom: 18,
    },
    headerLabel: {
        fontSize: 9,
        fontWeight: 'light',
        marginBottom: 6,
    },
    headerText: {
        fontSize: 10,
        fontWeight: 'light',
        marginBottom: 6,
    },
    footer: {
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    footerText: {
        fontSize: 8,
    },
    footerPageCount: {
        fontSize: 9,
        position: 'absolute',
        bottom: 12,
        right: 54,
    },
    tableColumn: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '100%',
        flex: 1,
    },
    tableRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
    },
    mainTable: {

    },
    mainTableHead: {
        borderBottom: '1px solid black',
        marginBottom: 6,
    },
    mainTableBody: {

    },
    mainTableColumnLabel: {
        fontSize: 9,
        marginBottom: 4,
    },
    mainTableText: {
        fontSize: 8,
        marginBottom: 6,
    }
});
  
const LedgerHeader = props => {
    const { info } = props;
    return (
        <View style={styles.pageHeader} fixed>
            <View style={styles.tableRow}>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerLabel}>
                        Projektnamn
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerLabel}>
                        SkatteID
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerLabel}>
                        Adress
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerText}>
                        {info.name}
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerText}>
                        {info.projectTaxationId}
                    </Text>
                </View>
                <View style={styles.tableColumn}>
                    <Text style={styles.headerText}>
                        {info.address}
                    </Text>
                </View>
            </View>
            <View style={styles.tableRow}>
                <Text style={styles.headerText}>* avser utländskt personnummer</Text>
            </View>
        </View>
    )
}

const CheckInsTable = props => {
    const { checkIns } = props;

    const personalIdentityNumberString = (record) => {
        if (record.personalIdentityNumber)
            return record.personalIdentityNumber;
        else if (record.foreignPersonalIdentityNumber)
            return record.foreignPersonalIdentityNumber + '*';
        else
            return null;
    } 

    return (
        <View style={styles.mainTable}>
            <View style={styles.mainTableHead}>
                <View style={styles.tableRow}>
                    <View style={styles.tableColumn}>
                        <Text style={styles.mainTableColumnLabel}>
                            Namn
                        </Text>
                    </View>
                    <View style={styles.tableColumn}>
                        <Text style={styles.mainTableColumnLabel}>
                            Personnummer
                        </Text>
                    </View>
                    <View style={styles.tableColumn}>
                        <Text style={styles.mainTableColumnLabel}>
                            Företag
                        </Text>
                    </View>
                    <View style={styles.tableColumn}>
                        <Text style={styles.mainTableColumnLabel}>
                            Org.nmr
                        </Text>
                    </View>
                    <View style={styles.tableColumn}>
                        <Text style={styles.mainTableColumnLabel}>
                            Datum
                        </Text>
                    </View>
                    <View style={styles.tableColumn}>
                        <Text style={styles.mainTableColumnLabel}>
                            Tid in
                        </Text>
                    </View>
                    <View style={styles.tableColumn}>
                        <Text style={styles.mainTableColumnLabel}>
                            Tid ut
                        </Text>
                    </View>
                </View>
            </View>
            <View style={styles.mainTableBody}>
                {checkIns && checkIns.map(checkIn => 
                    <View style={styles.tableRow} key={checkIn.id}>
                        <View style={styles.tableColumn}>
                            <Text style={styles.mainTableColumnLabel}>
                                {checkIn.name}
                            </Text>
                        </View>
                        <View style={styles.tableColumn}>
                            <Text style={styles.mainTableColumnLabel}>
                                {personalIdentityNumberString(checkIn)}
                            </Text>
                        </View>
                        <View style={styles.tableColumn}>
                            <Text style={styles.mainTableColumnLabel}>
                                {checkIn.companyName}
                            </Text>
                        </View>
                        <View style={styles.tableColumn}>
                            <Text style={styles.mainTableColumnLabel}>
                                {checkIn.legalRegistrationNumber}
                            </Text>
                        </View>
                        <View style={styles.tableColumn}>
                            <Text style={styles.mainTableColumnLabel}>
                                {moment(checkIn.date).format('YYYY-MM-DD')}
                            </Text>
                        </View>
                        <View style={styles.tableColumn}>
                            <Text style={styles.mainTableColumnLabel}>
                                {moment(checkIn.checkInTime).format('HH:mm')}
                            </Text>
                        </View>
                        <View style={styles.tableColumn}>
                            <Text style={styles.mainTableColumnLabel}>
                                {checkIn.checkOutTime ? moment(checkIn.checkOutTime).format('HH:mm') : '-'}
                            </Text>
                        </View>
                    </View>
                )}
            </View>
        </View>
    )
}

const LedgerDocument = props => {
    const { info, checkIns } = props; 

    const documentProps = {
        title: `Personalliggare - ${info.name}`,
        author: 'Weavra Admin',
        subject: 'Personalliggare',
        keywords: '',
        creator: 'Weavra',
        producer: 'Weavra',
    };

    return (
        <Document {...documentProps}>
            <Page size="A4" style={styles.page}>
                <LedgerHeader info={info} />
                <CheckInsTable checkIns={checkIns} />
                <View style={styles.footer} fixed>
                    <Text style={styles.footerText}>
                        Skapad med Weavra
                    </Text>
                    <Text style={styles.footerPageCount} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} | ${totalPages}`
                    )} />
                </View>
            </Page>
        </Document>
    );
}

const generatePdf = async (info, checkIns, fileName) => {
    const blob = await pdf((
        <LedgerDocument info={info} checkIns={checkIns} />
    )).toBlob();

    saveAs(blob, fileName);
}

const LedgerExporterPDF = (data, ledgerInfo) => {
    generatePdf(ledgerInfo, data, `Personalliggare-${ledgerInfo.projectTaxationId}.pdf`);
}

export default LedgerExporterPDF;