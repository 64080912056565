import * as React from 'react';
import { Title } from 'react-admin';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const UserProjects = () => (
    <Card>
        <Title title="Mina projekt" />
        <CardContent>Placeholder för projekt (användare)</CardContent>
    </Card>
);

export default UserProjects;