module.exports = {
    ra: {
        action: {
            delete: 'Radera',
            show: 'Visa',
            list: 'Lista',
            save: 'Spara',
            create: 'Skapa',
            edit: 'Redigera',
            cancel: 'Avbryt',
            refresh: 'Ladda om',
            add_filter: 'Lägg till filter',
            remove_filter: 'Ta bort filter',
            add: 'Lägg till',
            back: 'Gå tillbaka',
            bulk_actions: '1 objekt valt |||| %{smart_count} objekt valda',
            clear_input_value: 'Rensa värde',
            clone: 'Klona',
            confirm: 'Bekräfta',
            export: 'Exportera',
            remove: 'Ta bort',
            search: 'Sök',
            sort: 'Sortera',
            undo: 'Ångra',
            expand: 'Expandera',
            close: 'Stäng',
            unselect: 'Avmarkera',
        },
        boolean: {
            true: 'Ja',
            false: 'Nej',
        },
        page: {
            list: 'Lista över %{name}',
            edit: '%{name} #%{id}',
            show: '%{name} #%{id}',
            create: 'Skapa %{name}',
            dashboard: 'Överblick',
            error: 'Något gick snett',
            loading: 'Laddar',
            not_found: 'Hittades inte',
        },
        input: {
            file: {
                upload_several:
                    'Drag och släpp filer för att ladda upp, eller klicka för att välja fil.',
                upload_single: 
                    'Drag och släpp en fil för att ladda upp, eller klicka för att välja fil.',
            },
            image: {
                upload_several: 
                    'Drag och släpp bildfiler för att ladda upp, eller klicka för att välja bildfil.',
                upload_single: 
                    'Drag och släpp en bildfil för att ladda upp, eller klicka för att välja bildfil.',
            },
            references: {
                all_missing: 'Hittade inte referensdata.',
                many_missing:
                    'Minst en av den associerade referensen verkar inte längre finnas tillgänglig.',
                single_missing:
                    'De associerade referenserna verkar inte längre finnas tillgängliga.',
            },
        },
        message: {
            yes: 'Ja',
            no: 'Nej',
            are_you_sure: 'Är du säker?',
            about: 'Om',
            bulk_delete_content:
                'Är du säker på att du vill radera denna %{name}? |||| Är du säker på att du vill radera dessa %{smart_count} objekt?',
            bulk_delete_title:
                'Radera %{name} |||| Radera %{smart_count} %{name}',
            delete_content: 'Är du säker på att du vill radera detta objekt?',
            delete_title: 'Radera %{name} #%{id}',
            details: 'Detaljer',
            error:
                "Ett klientfel inträffade och din förfrågan kunde inte slutföras.",
            invalid_form: 'Den angivna datan har fel form. Var god kontrollera datan.',
            loading: 'Sidan laddas, var god vänta',
            not_found:
                'Du har antingen skrivit en felaktig URL, eller följt en dålig länk.',
        },
        navigation: {
            no_results: 'Inga resultat hittades',
            page_out_of_boundaries: 'Sidnummer %{page} utanför spannet',
            page_out_from_end: 'Kan inte besöka sida efter sista sidan',
            page_out_from_begin: 'Kan inte besöka sida före första sidan',
            page_range_info: '%{offsetBegin}-%{offsetEnd} av %{total}',
            next: 'Nästa',
            prev: 'Föregående',
            no_more_results:
                'Sidnumret %{page} är utanför spannet. Försök med tidigare sida.',
            page_rows_per_page: 'Rader per sida:',
            skip_nav: 'Hoppa över',
        },
        auth: {
            username: 'Användarnamn',
            password: 'Lösenord',
            sign_in: 'Logga in',
            sign_in_error: 'Inloggning misslyckades, fel användarnamn eller lösenord.',
            logout: 'Logga ut',
            user_menu: 'Profil',
            auth_check_error: 'Ej inloggad',
        },
        notification: {
            item_doesnt_exist: 'Objektet hittas inte',
            http_error: 'Kommunikationsfel med servern',
            updated: 'Objekt uppdaterat |||| %{smart_count} objekt uppdaterade',
            created: 'Objekt skapat',
            deleted: 'Objekt raderat |||| %{smart_count} objekt raderade',
            bad_item: 'Felaktigt objekt.',
            data_provider_error:
                'dataProvider-fel. Se konsollen för detaljer.',
            canceled: 'Åtgärd avbruten',
            logged_out: 'Din session har gått ut, vänligen återanslut.',
        },
        validation: {
            required: 'Obligatorisk',
            minLength: 'Måste vara minst %{min} tecken',
            maxLength: 'Måste vara max %{max} tecken',
            minValue: 'Måste vara minst %{min}',
            maxValue: 'Måste vara max %{max}',
            number: 'Måste vara ett nummer number',
            email: 'Måste vara en giltig e-postadress',
            oneOf: 'Måste vara en av: %{options}',
            regex: 'Måste matcha ett specifikt format (regexp): %{pattern}',
        },
    },
    weavra: {
        login: {
            title: 'Logga in',
            submit: 'Logga in',
            email: 'E-post:',
            password: 'Lösenord',
            confirmPassword: 'Bekräfta lösenord',
            forgotPassword: 'Glömt lösenord',
            resetPassword: 'Återställ lösenord',
            sendReset: 'Skicka',
        },
        companyProjects: {
            title: 'Projekt',
            customersId: 'ID (byggherrens)',
            internalId: 'ID (internt)',
            projectName: 'Projektnamn',
            customer: 'Byggherre',
            projectLead: 'Projektledare',
            status: 'Status',
        },
        projectStatus: {
            notStarted: 'Ej aktivt',
            started: 'Aktivt',
            paused: 'Pausat',
            finished: 'Avslutat',
        },
        navigation: {
            all_projects: 'Projekt',
            my_projects: 'Mina projekt',
            checklist: 'Checklista',
            timeplan: 'Tidsplan',
            planning: 'Planering',
            documents: 'Bygghandlingar',
            companyDocuments: 'Dokument',
            users: 'Användarkonton',
            events: 'Händelserapporter',
            contacts: 'Kontakter',
            ledger: 'Personalliggare',
        },
        dashboard: {
            title: '',
            selectProject: 'Sök projekt',
        },
        actions: {
            newProject: 'Nytt projekt',
            newUser: 'Nytt användarkonto',
            newUserGroup: 'Ny projektgrupp',
            uploadFile: 'Ladda upp fil',
            newFolder: 'Ny mapp',
        },
        companyUsers: {
            title: 'Användarkonton',
            username: 'Användarnamn',
            name: 'Namn',
            newGroup: 'Skapa ny projektgrupp',
            editGroup: 'Redigera grupp',
            usersWithoutGroup: 'Användare utan grupp',
            newUser: 'Skapa nytt användarkonto',
            editUser: 'Redigera användare',
            newUserFile: 'Ladda upp ny användarfil',
            editUserFile: 'Redigera användarfil',
            firstName: 'Förnamn',
            lastName: 'Efternamn',
            emailUsername: 'Användarnamn (epost)',
            password: 'Lösenord',
            telephone: 'Telefon',
            role: 'Roll',
            group: 'Projektgrupp',
            fileDescription: 'Beskrivning',
            fileValidFrom: 'Giltig från',
            fileValidUntil: 'Giltig till',
            fileData: 'Fil',
            userFileType: 'Typ av dokument',
            personalIdentityNumber: 'Personnummer',
            foreignPersonalIdentityNumber: 'Utländskt personnummer',
        },
        company: {
            legalRegistrationNumber: 'Organisationsnummer',
            name: 'Namn',
        },
        ledger: {
            name: 'Namn',
            personalIdentityNumber: 'Personnummer',
            company: 'Företag',
            legalRegistrationNumber: 'Org.nmr',        
            date: 'Datum',
            checkInTime: 'Tid in',
            checkOutTime: 'Tid ut',
        },
        project: {
            taxationId: 'SkatteID',
            activeLedger: 'Aktivera personalliggare'
        },
        documents: {
            titleProject: 'Bygghandlingar',
            titleCompany: 'Dokument',
            newFolder: 'Skapa ny mapp',
            name: 'Namn',
            showThumbnails: 'Visa miniatyrer',
            showInApp: 'Visa i App',
        },
        roles: {
            administrator: 'Administratör',
            administrators: 'Administratörer',
            user: 'Användare',
            users: 'Användare',
        },
        autocomplete: {
            search: 'Sök',
            pickProject: 'Sök projekt',
            noOptions: 'Inga träffar',
        },
        notification: {
            taskSaved: 'Uppgift sparad',
            timeplanActivated: 'Tidsplanen är aktiverad',
            timeplanInactivated: 'Tidsplanen är inaktiverad',
            invoiceUpdated: 'Faktura uppdaterad!',
            invoiceSaved: 'Faktura sparad!',
        },
        input: {
            max_length_info: '%{count} av %{max} tecken',
        },
        help: {
            demo: 'Så här kan en hjälptext se ut! \n Vi kan ha radbrytning om vi vill. \n Tjohej!',
            projectSearch: 'Här kan du söka efter alla projekt som ditt företag är kopplat till.',
            companyPage: 'På företagets sida hanterar du alla projekt som är kopplat till ditt företag och administrerar alla användarkonton.',
            companyPage2: 'Hantera projekt och administrera användarkonton.',
            myPage: 'Mina pågående projekt och projektstatus.',
            projectPage: 'Hantera projektet.',
            openLocationSettings: 'Klicka för att visa adressinställningar och karta.',
            ledgerInfo: 'Aktivera personalliggaren för att tillåta arbetsplatsincheckningar i mobilappen.',
            activeStatus: 'Vid byggstart blir projektet aktivt och visas i den mobila appen.',
            unassignedTasks: 'Dra uppgiften till företaget som ska utföra den',
            createAccounts: 'Skapa användarkonto för administratörer eller användare av mobilappen.',
            userRoles: 'Administratörer kan logga in på webben.\nAnvändare kan endast logga in i mobilappen.',
            personalIdentityNumber: 'Personnummer krävs för incheckning på projekt, samt inloggning via BankID.',
            timePlanActive: 'Tidsplanen visas i mobil app när den är aktiv.',
            userPassword: 'Lösenordet skickas automatiskt till användaren.\nFör vanliga användare via SMS, för administratörer via epost.',
        },
        superAdmin: {
            isFormGlobal: 'Tillgängligt för alla?',
            availableToCompanies: 'Tillgängligt för företag',
        }
    },
};
