import React, { useState, useEffect, Fragment } from 'react';
import {
    useRefresh,
    useNotify,
    useTranslate,
    useDataProvider,
    useQuery,
    useCreate,
    useUpdate,
    Loading,
    Error,
    List,
    SimpleForm,
    TextInput,
    BooleanInput,
    FileInput,
    FileField,
    TextField,
    DateField,
    SelectInput,
    ImageField,
    SelectArrayInput,
} from 'react-admin';
import { useSelector } from 'react-redux';
import { Grid, Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import DocumentFolderList from '../shared/documents/DocumentFolderList';
import CreateFolderButton from '../shared/documents/CreateFolderButton';
import QuickDialogActions from '../shared/QuickDialogActions';
import DateTimeOptions from '../shared/DateTimeOptions';

const uploadAcceptFilter = "image/*,.pdf,.doc,.docx,.dwg,.xls,.xlsx";

const FolderQuickCreate = ({ initialValues, onSave, onCancel, isLoading }) => {
    return (
        <SimpleForm 
            name="folder-quick-create"
            resource="project/documents"
            initialValues={initialValues}
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="name" label="weavra.documents.name"/>
            <BooleanInput source="showThumbnails" label="weavra.documents.showThumbnails" />
            <BooleanInput source="showInApp" label="weavra.documents.showInApp" />
        </SimpleForm>
    );
}

const FolderQuickEdit = ({ record, onSave, onCancel, isLoading, projectMembers }) => {
    return (
        <SimpleForm 
            name="folder-quick-edit"
            resource="project/documents"
            record={record}
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <TextInput source="name" label="weavra.documents.name"/>
            <BooleanInput source="showThumbnails" label="weavra.documents.showThumbnails" />
            <BooleanInput source="showInApp" label="weavra.documents.showInApp" />
            <SelectArrayInput source="accessList" label="Åtkomst" optionText="name" choices={projectMembers} />
        </SimpleForm>
    );
}

const DocumentQuickCreate = ({ folder, onSave, onCancel, isLoading, projectMembers }) => {
    const initialValues = { documentFolderId: folder.id };

    return (
        <SimpleForm
            name="document-quick-create"
            initialValues={initialValues}
            resource="project/documents"
            save={onSave}
            toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} />}
        >
            <SelectArrayInput source="notifyList" label="Notifiera följande företag" optionText="name" choices={projectMembers} />
            <FileInput source="fileData" label="weavra.companyUsers.fileData" multiple={false} accept={uploadAcceptFilter}>
                <FileField source="src" title="title" />
            </FileInput>
        </SimpleForm>
    );
}

const DocumentQuickUpdate = ({ record, folders, onSave, onCancel, onDelete, isLoading, readonly }) => {
    // TODO: Display more information (and file history?)
    const folderChoices = [];
    Object.keys(folders).forEach(key => folderChoices.push({ id: key, name: folders[key].name }));

    return (
        <SimpleForm
        name="document-quick-create"
        resource="project/documents"
        record={record}
        save={onSave}
        toolbar={<QuickDialogActions onCancel={onCancel} isLoading={isLoading} enableDelete={!readonly} record={record} onDelete={onDelete} disabled={readonly} />}
        >
            <TextField source="name" label="" />
            
            <DateField record={record} source="created" showTime options={DateTimeOptions} locales="sv-SE" label="Skapad" />
            <TextField source="createdByName" label="Skapad av" />

            {!readonly && <SelectInput source="documentFolderId" label="Mapp" choices={folderChoices} />}

            <ImageField source="thumbUrl" label="" />
            <FileField source="url" target="_blank" title="name" label="Öppna fil" />

            {!readonly && 
                <FileInput source="fileData" label="Ersätt med ny fil" multiple={false} accept={uploadAcceptFilter}>
                    <FileField source="src" title="title" />
                </FileInput>
            }

        </SimpleForm>
    );
}

const EmptyDocuments = () => {
    return (
        <div></div>
    )
}

const ProjectDocumentList = props => {

    const [shownDialog, setShownDialog] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [folderChoices, setFolderChoices] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [projectMembers, setProjectMembers] = useState(undefined);

    const refresh = useRefresh();
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [createFolder] = useCreate("project/documents");
    const [updateFolder] = useUpdate("project/documents");
    const [createDocument] = useCreate("project/documents"); // TODO: Återanvänd createFolder?
    const [updateDocument] = useUpdate("project/documents");

    // Ladda projekt; id från redux-state
    const projectId = useSelector(state => state.workspace.id);

    // Hämta rättigheter
    const { data:permissionsData, loaded:isPermissionsLoaded, error: permissionsError } = useQuery({
        type: 'getOne',
        resource: 'general/projects',
        payload: { id: projectId, getPermissions: true, getMembersPermissions: false }
    });

    // Hämta företag som är medlemmar i projektet
    useEffect(() => {
        if (projectId) {
            // TODO: Hitta en bättre lösning...
            setIsLoading(true);
            dataProvider.getMany('general/projects', { ids: [projectId], getMembers: true })
                .then(({ data }) => {
                    setProjectMembers(data);
                }).catch(error => {
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                });

            setIsLoading(false);
        }
    }, [dataProvider, notify, projectId]);

    const handleClose = () => {
        setShownDialog(null);
    }

    const handleNewFolderClicked = () => {
        setShownDialog('folderCreate');
    }

    const handleCreateFolderSubmit = async values => {
        setIsLoading(true);
        createFolder(
            { payload: { data: { ...values, createDocumentFolder: true } } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }

    const handleFolderEdit = (folder) => {
        
        setSelectedFolder(folder);
        setShownDialog('folderEdit');
    }

    const handleEditFolderSave = async values => {
        setIsLoading(true);
        updateFolder(
            { payload: { id: values.id, data: {...values, updateDocumentFolder: true} } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }

    const handleFolderDelete = (folder) => {
        const confirmMessage =  translate("ra.message.delete_content"); // TODO: Custom message
        if (window.confirm(confirmMessage)) {
            setIsLoading(true);
            dataProvider.delete('project/documents',
                { id: folder.id, data: { deleteDocumentFolder: true }}
            ).then(() => {
                handleClose();
                refresh();
            })
            .catch(error => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
            });

            setIsLoading(false);
        }
    }

    const handleAddDocumentToFolder = (folder) => {
        setSelectedFolder(folder);
        setShownDialog('documentAdd'); 
    }

    const handleCreateDocumentSubmit = async values => {
        setIsLoading(true);
        createDocument(
            { payload: { data: values }},
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }

    const handleDocumentEdit = (doc, folders) => {
        
        setSelectedDocument(doc);
        setFolderChoices(folders);
        setShownDialog('documentEdit');
    }

    const handleEditDocumentSave = async values => {
        setIsLoading(true);
        updateDocument(
            { payload: { id: values.id, data: values } },
            {
                onSuccess: () => {
                    handleClose();
                    refresh();
                },
                onFailure: (error) => notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error')
            }
        );

        setIsLoading(false);
    }

    const handleDocumentDelete = (doc) => {
        setIsLoading(true);
        dataProvider.delete('project/documents',
            { id: doc.id }).then(() => {
                handleClose();
                refresh();
            })
            .catch(error => {
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
            }
        );

        setIsLoading(false);
    }

    if (!isPermissionsLoaded)
        return <Loading />
    
    if (permissionsError)
        return <Error />

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {permissionsData.canAddDocuments && <CreateFolderButton onClick={handleNewFolderClicked} />}
                </Grid>
                <Grid item xs={12}>
                    <List
                        {...props}
                        title="weavra.documents.titleProject"
                        filter={{ projectId: projectId }}
                        bulkActionButtons={false} 
                        exporter={false}
                        pagination={false}
                        actions={false}
                        perPage={9999}
                        component="div"
                        empty={<EmptyDocuments />}
                    >
                        <DocumentFolderList
                            onFolderEdit={handleFolderEdit}
                            onFolderDelete={handleFolderDelete}
                            onDocumentEdit={handleDocumentEdit}
                            onAddDocument={handleAddDocumentToFolder}
                            permissions={permissionsData} />
                    </List>
                </Grid>
            </Grid>

            <Dialog open={shownDialog === 'folderCreate'} onClose={handleClose} fullWidth>
                <DialogTitle>{translate('weavra.documents.newFolder')}</DialogTitle>
                <DialogContent>
                    <FolderQuickCreate onSave={handleCreateFolderSubmit} onCancel={handleClose} isLoading={isLoading} initialValues={{ projectId: projectId, showInApp: true }} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'folderEdit'} onClose={handleClose} fullWidth>
                <DialogTitle>Redigera mapp</DialogTitle>
                <DialogContent>
                    <FolderQuickEdit record={selectedFolder} onSave={handleEditFolderSave} onCancel={handleClose} isLoading={isLoading} projectMembers={projectMembers} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'documentAdd'} onClose={handleClose} fullWidth>
                <DialogTitle>Lägg till dokument i mappen "{selectedFolder ? selectedFolder.name : '...'}"</DialogTitle>
                <DialogContent>
                    <DocumentQuickCreate folder={selectedFolder} onSave={handleCreateDocumentSubmit} onCancel={handleClose} isLoading={isLoading} projectMembers={projectMembers} />
                </DialogContent>
            </Dialog>

            <Dialog open={shownDialog === 'documentEdit'} onClose={handleClose} fullWidth>
                <DialogTitle>Redigera dokument</DialogTitle>
                <DialogContent>
                    <DocumentQuickUpdate
                        record={selectedDocument}
                        folders={folderChoices}
                        onSave={handleEditDocumentSave}
                        onCancel={handleClose}
                        isLoading={isLoading}
                        onDelete={handleDocumentDelete}
                        readonly={!permissionsData.canEditDocuments} />
                </DialogContent>
            </Dialog>

        </Fragment>
    );
}

export default ProjectDocumentList;