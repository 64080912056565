import React, { useState } from 'react';
import classnames from 'classnames';
import {
    useDataProvider,
    useQueryWithStore,
    useRefresh,
    useNotify,
    Loading,
    Error,
    Button,
    TextInput,
    BooleanInput,
} from 'react-admin';

import { makeStyles, Grid, Typography } from '@material-ui/core';


import { FormDialog } from '../shared/FormDialog';
import Editor from './Forms/Editor';

const useStyles = makeStyles((theme) => ({
    root: {
        marginLeft: theme.spacing(1),
    },
    formsListRoot: {

    },
    formsListItemRoot: {
        maxWidth: '60%',
        margin: theme.spacing(1, 3),
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.background.paper}`,
        borderRadius: '4px',
        cursor: 'pointer',

        "&:hover": {
            transition: 'background 0.5s',
            //background: 'rgba(255,255,255,0.08)',
            background: theme.palette.background.default,
        },
    },
    formsListItemSelected: {
        background: theme.palette.background.default,
        border: `3px solid ${theme.palette.background.paper}`,
    },
}));

const NewFormButton = props => {

    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);

    const dataProvider = useDataProvider();

    const refresh = useRefresh();
    const notify = useNotify();

    const handleOpen = () => {
        setOpen(true);
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const handleSubmit = async values => {
        dataProvider
            .create('superadmin/taskForms', { data: values })
            .then(response => {
                setSaving(false);
                setOpen(false);
                //onTaskSaved(true);
                notify('ra.notification.created', 'info');
                refresh();
            })
            .catch(error => {
                setSaving(false);
                notify('ra.notification.http_error', 'error');
                // notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                console.log('Fel fel fel', error, saving);
                //onTaskSaved(false, error.message);
            });
    }

    return (
        <>
            <Button
                label="+ Nytt formulär"
                variant="text"
                size="small"
                onClick={handleOpen}>
            </Button>

            <FormDialog
                open={open}
                loading={saving}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                title="Nytt formulär"
                submitLabel="Spara"
            >
                <TextInput source="name" label="Namn" fullWidth />
                <TextInput multiline source="description" label="Beskrivning" fullWidth />
                <BooleanInput source="isGlobal" label="weavra.superAdmin.isFormGlobal" fullWidth />
            </FormDialog>
        </>
    );
}

const FormsList = props => {
    const { forms, onFormSelected } = props;

    const classes = useStyles();

    const [selectedForm, setSelectedForm] = useState(null);

    // TODO: Search/Filter
    // TODO: Paging? 
    // TODO: Kanske använda en färdig/custom RA-lista?

    const handleSelectForm = form => {
        setSelectedForm(form);
        onFormSelected(form);
    }

    return (
        <div className={classes.formsListRoot}>
            <NewFormButton />
            {
                forms && forms.map(form =>
                    <FormsListItem key={form.id} form={form} onSelect={() => handleSelectForm(form)} isSelected={form === selectedForm} />
                )
            }
        </div>
    );
}

const FormsListItem = props => {
    const { form, onSelect, isSelected } = props;
    const classes = useStyles();

    // TODO: Form Note???

    return (
        <div className={classnames(classes.formsListItemRoot, isSelected ? classes.formsListItemSelected : '')} onClick={onSelect} >
            <Grid container spacing={1}>
                <Grid item xs={5}>
                    <Typography>{form.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>{form.active ? 'Aktiv' : 'Inaktiv'}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography>{form.description}</Typography>
                </Grid>
            </Grid>
        </div>
    )
}

const SuperAdminForms = props => {
    const classes = useStyles();

    const [selectedForm, setSelectedForm] = useState(null);

    // Get TaskForms from API
    const { data: forms, loading, error: formsLoadingError } = useQueryWithStore({
        type: 'getList',
        resource: 'superadmin/taskForms',
        payload: {
            pagination: { page: 1, perPage: 999 },
            sort: { field: 'name', order: 'ASC' }
        }
        // TODO: filter? Only active? "Sök formulär"
    });


    if (loading) { 
        return <Loading />
    }

    if (formsLoadingError) {
        return <Error />
    }

    const handleFormSelected = (form) => {
        setSelectedForm(form);
    }

    return (
        <div className={classes.root}>
            <FormsList forms={forms} onFormSelected={handleFormSelected} />
            <Editor currentForm={selectedForm} onFormSelected={handleFormSelected} />
        </div>
    );
}

export default SuperAdminForms;