import React, {useState} from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import { CssBaseline, Container, Card, TextField, Button } from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    logo: {
        maxWidth: '400px',
        marginTop: '-120px',
    },
    card: {
        textAlign: 'center',
        padding: '16px', // TODO: theme.spacing...
    },
    form: {
        display: 'flex',
        flexFlow: 'column nowrap',
    },
    input: {
        width: 300,
        marginBottom: '8px', // TODO: theme.spacing...
    },
}));

const ForgotPasswordForm = (props) => {
    const [userEmail, setUserEmail] = useState('');
    const [requestSubmitted, setRequestSubmitted] = useState('');
    const classes = useStyles();
    const translate = useTranslate();
    const dataProvider = useDataProvider();

    const submit = e => {
        e.preventDefault();

        dataProvider.requestPasswordReset(userEmail)
            .then(() => {
                console.log('Success!');
                setRequestSubmitted('SENT');
            })
            .catch(error => {
                console.log('Error!', error);
                setRequestSubmitted('ERROR');
            });
    }

    return (
        <>
            <img src={process.env.PUBLIC_URL + '/images/weavra-horizontal.png'} alt="" className={classes.logo} />
            <Card className={classes.card}>
                <h1 className={classes.title}>{translate('weavra.login.forgotPassword')}</h1>

                {!requestSubmitted &&
                    //TODO: Help text?
                    <form onSubmit={submit} className={classes.form}>
                        <TextField
                            placeholder={translate('weavra.login.email')}
                            name="email"
                            type="email"
                            variant="outlined"
                            value={userEmail}
                            className={classes.input}
                            onChange={e => setUserEmail(e.target.value)}
                        />
                        <Button type="submit" size="large" variant="contained">{translate('weavra.login.sendReset')}</Button>
                    </form>
                }

                {requestSubmitted && requestSubmitted === 'SENT' &&
                    // TODO: Localization    
                    <p className={classes.input}>
                        En länk har skickats till din epostadress. Följ länken för att återställa ditt lösenord.
                    </p>
                }

                {requestSubmitted && requestSubmitted === 'ERROR' &&
                    // TODO: Localization    
                    <p className={classes.input}>
                        Något gick fel. Försök igen eller kontakta administratör.
                    </p>
                }

            </Card> 
        </>
        
    );
}

const ForgotPasswordPage = (props) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={createMuiTheme(props.theme)}>
            <Container className={classes.root}>
                <ForgotPasswordForm {...props} />
            </Container>
            <CssBaseline/>
        </ThemeProvider>
    );
}

export default ForgotPasswordPage;