import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    useQuery,
    Loading,
    Error,
} from 'react-admin';
import { makeStyles, Grid, Dialog, DialogContent } from '@material-ui/core';
import TaskEditor from './TaskEditor';
import TaskColumn from './TaskColumn';

const useStyles = makeStyles(theme => ({
    wrapper: {
        maxWidth: '100vw',
        overflowX: 'auto',
    },
    unassigned: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3) + 12,
    },
    assigned: {
        maxWidth: '1px', // TODO: "non-magical" solution instead...
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(3),
    }
}));

const ChecklistTab = props => {
    const { project, tasks, allCompanies, onTaskSave, onTaskMove, onTaskDelete, isSaving } = props;

    const classes = useStyles();

    const [showEditor, setShowEditor] = useState(false);
    const [editTask, setEditTask] = useState(null);
    const [saving] = useState(false); // TODO: Remove? Use isSaving from props?

    // Hämta formulär
    // TODO: Förstå varför inte useQueryWithStore kunde användas här...
    const { data: taskForms, loading: formsLoading, error: formsError } = useQuery({
        type: 'getList',
        resource: 'general/taskforms',
        payload: {
            pagination: { page: 1, perPage: 999 },
            sort: { field: 'name', order: 'ASC' },
            filter: { active: 'true', simple: 'true' }
        },
    });

    // Hämta rättigheter
    const { data:permissionsData, loading: permissionsLoading, error: permissionsError } = useQuery({
        type: 'getOne',
        resource: 'general/projects',
        payload: { id: project.id, getPermissions: true, getMembersPermissions: false }
    });

    const isTaskEditable = useMemo(() => {
        if (!editTask)
            return false;
        
        if (!editTask.id)
            return true;
        
        const totalTasks = editTask.tasks.length;
        const completedTasks = editTask.tasks.filter(t => t.completed).length;
        const allDone = completedTasks === totalTasks && totalTasks !== 0;

        return !allDone
    }, [editTask]);

    const permittedToEdit = useMemo(() => {
        if (!permissionsData || !editTask)
            return false;
        
        if (permissionsData.canEditChecklistItems)
            return true;
        
        return editTask.ownerId === permissionsData.companyId

    }, [editTask, permissionsData]);

    const permittedToDelete = useMemo(() => {
        if (!permissionsData || !editTask)
            return false;
        
        if (!permissionsData.canDeleteChecklistItems)
            return false;
        
        if (permissionsData.canDeleteAllChecklistItems)
            return true;
        
        return editTask.ownerId === permissionsData.companyId || isTaskEditable;

    }, [editTask, isTaskEditable, permissionsData]);

    const handleTaskClicked = (task) => {
        setEditTask(task);
        setShowEditor(true);
    }

    const handleNewTaskClicked = (newSortIndex, companyId) => {
        //const defaultStartTime = project.startDate ?? new Date(new Date().getFullYear, 0, 1);

        const newTask = {
            name: 'Ny uppgift',
            id: null,
            ownerId: companyId,
            projectId: project.id,
            startTime: null,
            length: 1,
            createSimplifiedChildren: true,
            momentType: 0,
            sortIndex: newSortIndex,
        };

        setEditTask(newTask);
        setShowEditor(true);
    }

    const handleClose = () => {
        setShowEditor(false);
    }

    const handleSaveTask = useCallback(async task => {
        await onTaskSave(task);
    }, [onTaskSave]);

    const taskSaveContext = useMemo(() => ({
        save: handleSaveTask,
        saving
    }), [saving, handleSaveTask]);

    const handleTaskDrop = (task) => {
        onTaskMove(task);
    }

    useEffect(() => {
        setShowEditor(isSaving);
    }, [isSaving]);

    if (formsLoading || permissionsLoading)
        return <Loading />

    if (taskForms === undefined)
        return <div>Laddar formulär...</div>
    
    if (!tasks)
        return <div>Laddar...</div>

    if (formsError || permissionsError)
        return <Error />
    
    const memberCompanies = [];

    for (let i = 0; i < project.memberCompanies.length; i++) {
        const company = allCompanies.find(c => c.id === project.memberCompanies[i]);
        if (company)
            memberCompanies.push(company);
    }

    memberCompanies.forEach(mc => mc.tasks = tasks.filter(t => t.ownerId === mc.id));

    return <>
        <Grid container className={classes.wrapper} wrap="nowrap">
            <Grid item container direction="row" xs={12} wrap="nowrap" className={classes.assigned}>
                {memberCompanies && memberCompanies.map(company =>
                    <TaskColumn
                        key={company.id}
                        columnName={company.name}
                        company={company}
                        tasks={company.tasks}
                        taskForms={taskForms}
                        onTaskClicked={handleTaskClicked}
                        onTaskDrop={handleTaskDrop}
                        onAddTaskClicked={handleNewTaskClicked}
                        projectPermissions={permissionsData}
                    />
                )}
            </Grid>
        </Grid>
        <Dialog
            open={showEditor}
            onClose={handleClose}
            fullWidth>
            <DialogContent>
                {editTask &&
                    <TaskEditor
                        task={editTask}
                        disabled={!isTaskEditable || !permittedToEdit}
                        canDelete={permittedToDelete}
                        project={project}
                        taskForms={taskForms}
                        saveContext={taskSaveContext}
                        onSave={handleSaveTask}
                        onDelete={onTaskDelete}
                        onCancel={handleClose}
                        isSaving={saving}
                        canEditOwner={false}
                        canEditDate={false}
                        canEditDependencies={false}
                        companies={memberCompanies}
                        relatedTasksType={0}
                    />
                }
            </DialogContent>
        </Dialog>
    </>
}

export default ChecklistTab;