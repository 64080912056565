import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
    useRefresh,
    useNotify,
    useQueryWithStore,
    useDataProvider,
    Loading,
    Error,
} from 'react-admin';

import { Tabs, Tab, Grid, makeStyles } from '@material-ui/core';

import ChecklistTab from './ProjectPlanning/ChecklistTab';
import ReportsTab from './ProjectPlanning/ReportsTab';

const useStyles = makeStyles(() => ({
    wrapper: {
        flexGrow: 1,
    },
    panels: {
        flexGrow: 1,
    },
}));

const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabPanel = props => {
    const { children, value, index, ...rest } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...rest}
      >
        {value === index && (
          <>
            {children}
          </>
        )}
      </div>
    );
}

const ProjectTasksChecklist = props => {
    const classes = useStyles();

    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const [selectedTab, setSelectedTab] = useState(0);
    const [saving, setSaving] = useState(false);
    const [tasks, setTasks] = useState();

    // Ladda projekt; id från redux-state
    const projectId = useSelector(state => state.workspace.id); // TODO: Handle null?

    // Hämta projektdata från backend
    const { data:projectData, loaded:isProjectLoaded, error:projectError } = useQueryWithStore({
        type: 'getOne',
        resource: 'project/checklist',
        payload: { id: projectId }
    });

    // Hämta företag
    const { data:companiesData, loaded:isCompaniesLoaded, error:companiesError } = useQueryWithStore({
        type: 'getList',
        resource: 'general/companies',
        payload: {
            pagination: { page: 1, perPage: 999 },
            sort: { field: 'name', order: 'ASC' }
        }

        // TODO: Filter? Endast aktiva?
    });

    useEffect(() => {
        if (!projectData || !projectData.projectMoments) {
            setTasks(null);
            return;
        }

        const filteredTasks = projectData.projectMoments.filter(t => t.momentType === 0).sort((a, b) => a.sortIndex - b.sortIndex);
        setTasks(filteredTasks);
    }, [projectData]);

    const handleSaveTask = useCallback(task => {
        setSaving(true);
        
        if (task.id) {
            //console.debug(`Saving task with id ${task.id} :: sortIndex = ${task.sortIndex}, ownerId = ${task.ownerId}`);
            dataProvider
                .update('general/projectmoments', { id: task.id, data: task })
                .then(response => {
                    setSaving(false);
                    notify('weavra.notification.taskSaved', 'info');
                    refresh();
                })
                .catch(error => {
                    setSaving(false);
                    console.log('Fel fel fel', error, saving);
                    refresh();
                });
        } else {
            dataProvider
                .create('general/projectmoments', { data: task })
                .then(response => {
                    setSaving(false);
                    notify('weavra.notification.taskSaved', 'info');
                    refresh();
                })
                .catch(error => {
                    setSaving(false);
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                    console.log('Fel fel fel', error, saving);
                    refresh();
                });
        }
    }, [dataProvider, notify, refresh, saving]);

    const handleTaskMove = (task) => {
        setTasks(tasks.map(t => (t.id === task.id ? task : t)));

        handleSaveTask(task);
    }

    const handleTaskDelete = useCallback((task) => {
        setSaving(true);
        dataProvider
            .delete('general/projectmoments', { id: task.id })
            .then(() => {
                setSaving(false);
                refresh();
                // TODO: Uppdatera vyn utan refresh?
            })
            .catch(error => {
                setSaving(false);
                notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'error');
                console.log('Fel fel fel', error, saving);
                //refresh();
            })
    }, [dataProvider, notify, refresh, saving]);

    const projectOwnerName = useMemo(() => (
        companiesData?.find(c => c.id === projectData?.ownerId)?.name ?? ''
    ), [companiesData, projectData]); 

    const handleTabChange = (event, tabIndex) => {
        setSelectedTab(tabIndex);
    }

    if (!isProjectLoaded || !isCompaniesLoaded) { 
        return <Loading />
    }

    if (projectError || companiesError) {
        return <Error />
    }
    
    return (
        <Grid id="projectTasksChecklist" container direction="column" className={classes.wrapper}>
            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="checklist tabs" indicatorColor='primary'>
                <Tab label="Uppgifter" {...a11yProps(0)} />
                <Tab label="Rapporter" {...a11yProps(1)} />
            </Tabs>
            <Grid className={classes.panels}>
                <TabPanel value={selectedTab} index={0}>
                    <DndProvider backend={HTML5Backend}>
                        <ChecklistTab
                            project={projectData}
                            tasks={tasks}
                            allCompanies={companiesData}
                            onTaskSave={handleSaveTask}
                            onTaskMove={handleTaskMove}
                            onTaskDelete={handleTaskDelete}
                            isSaving={saving}
                        />
                    </DndProvider>
                </TabPanel>
                <TabPanel value={selectedTab} index={1}>
                    <ReportsTab 
                        projectId={projectId}
                        projectData={projectData}
                        projectOwnerName={projectOwnerName}
                        {...props} 
                    />
                </TabPanel>
            </Grid>
        </Grid>
    );
}

export default ProjectTasksChecklist;