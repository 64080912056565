import React from "react";
import {
    SaveContextProvider,
    SimpleForm,
    TextInput,
    NumberInput,
    SelectInput,
    FormDataConsumer,
    Button,
} from 'react-admin';

import { Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import EditorToolBar from "./EditorToolBar";

const AddNewApproveBehaviour = props => {
    const { onAddApproveBehaviour } = props;
    return (
        <div>
            <span>Saknar regler vid godkännande</span>
            <br/>
            <Button         
                label="Lägg till regler"
                variant="text"
                size="small"
                onClick={onAddApproveBehaviour}>
                <AddCircleIcon />
            </Button>
        </div>
    )
}

const SubTaskEditor = props => {
    const { record, saveContext, onDelete, onAddApproveBehaviour, onAddSubTaskOption } = props;

    return (
        <>
            <Typography>Redigera underuppgift - {record.name}</Typography>
            <SaveContextProvider value={saveContext}>
                <SimpleForm
                    name="subtask-edit"
                    resource="general/projectsubtasks"
                    record={record}
                    save={saveContext.save}
                    saving={saveContext.saving}
                    toolbar={<EditorToolBar onDeleteClick={onDelete} />}
                    redirect={false}>
                    <TextInput source="name" label="Namn" />
                    <TextInput multiline source="description" label="Beskrivning" />
                    <NumberInput source="sortIndex" label="Sortering" />
                    <SelectInput source="subTaskType" label="Typ" choices={[
                        { id: 0, name: 'Normal' },
                        // { id: 1, name: 'Godkännande' } //TODO: Bättre översättning/term? //TODO: Måste tänka om gällande rollbacks när det kommer till formulären...
                    ]} />
                    <FormDataConsumer>
                        {({ formData }) => (formData.subTaskType === 1) &&
                            <div>
                                {(formData.approveBehaviourId || formData.approveBehaviour) 
                                    ? <span>Har regler vid godkännande</span> 
                                    : <AddNewApproveBehaviour onAddApproveBehaviour={() => onAddApproveBehaviour(record)} />}
                            </div>
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData.subTaskType !== 1) &&
                            <SelectInput {...rest} source="subTaskVariant" label="Typ av godkännande" choices={[
                                { id: 0, name: 'Ok' },
                                { id: 1, name: 'Fritext' },
                                { id: 2, name: 'Val' },
                                { id: 3, name: 'Val med fritext' }
                        ]} />
                    }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData }) => (formData.subTaskType === 0 && (formData.subTaskVariant === 2 || formData.subTaskVariant === 3)) &&
                            <>
                                <label>Val</label>
                                <ul>
                                { record.options.map(option =>
                                    <li key={option.id}><Typography>{option.name}</Typography></li>
                                )}
                                </ul>
                                <Button         
                                    label="Lägg till val"
                                    variant="text"
                                    size="small"
                                    onClick={() => onAddSubTaskOption(record)}>
                                    <AddCircleIcon />
                                </Button>
                            </>
                        }
                    </FormDataConsumer>
                </SimpleForm>
            </SaveContextProvider>
        </>
    );
}

export default SubTaskEditor;