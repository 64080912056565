import React, { useCallback, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core';

import classnames from 'classnames';

import { useDrop } from 'react-dnd';
import update from 'immutability-helper';

import TaskCard from './TaskCard';
import AddTaskButton from './AddTaskButton';
import HelpButton from '../../shared/buttons/HelpButton';

const useStyles = makeStyles((theme) => ({
    taskColumn: {
        border: `${theme.spacing(0.25)}px solid ${theme.palette.background.paper}`,
        background: theme.palette.background.paper,
        borderRadius: '4px',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        minWidth: '300px',
    },
    unassignedColumn: {
        border: `${theme.spacing(0.25)}px solid ${theme.palette.background.paper}`,
        borderRadius: '4px',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        width: 'inherit',
    },
    columnHeader: {
        marginBottom: theme.spacing(2),
    },
    columnHeaderIsDropOver: {
        textDecoration: 'underline double',
        color: 'red', // TODO: bättre sätt att visa att vi drar ett kort över kolumnen... annan färg? svag ram runt kolumnen?
    },
}));

const TaskColumn = props => {
    // A column holding task cards, belonging to a company or unassigned
    const { columnName, company, tasks, isUnassignedTaskColumn, onTaskClicked, onAddTaskClicked, onTaskDrop, projectPermissions } = props;

    const classes = useStyles();

    const [cards, setCards] = useState(tasks);

    useEffect(() => {
        if (!tasks) {
            setCards(null);
            return;
        }

        setCards(tasks.sort((a, b) => a.sortIndex - b.sortIndex));
    }, [tasks]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'TASKCARD',
        drop: (item) => handleDropCard(item),
        collect: monitor => ({
            isOver: !!monitor.isOver(),
        }),
    }), []);

    const getNextSortIndex = () => {
        if (!cards || cards.length === 0) return 0;
        return Math.max(...cards.map(c => c.sortIndex)) + 1;
    }

    const handleDropCard = (item) => {
        const taskItem = item.sourceList.find(t => t.id === item.id);

        if (!taskItem) {
            return; //???
        }

        const originalTaskOwnerId = taskItem.ownerId;
        const dropTargetId = company ? company.id : null;

        if (!originalTaskOwnerId) {
            if (dropTargetId) {
                // Flytt från unassigned till företag/utförare
                taskItem.ownerId = dropTargetId;
            }
        } else {
            if (dropTargetId) {
                if (originalTaskOwnerId !== dropTargetId) {
                    // Flytt mellan två företag/utförare
                    taskItem.ownerId = dropTargetId;
                }
            } else {
                // Flytt till unassigned
                taskItem.ownerId = null;
            }
        }

        if (originalTaskOwnerId !== dropTargetId) {
            // Update task.sortIndex to new column's max + 1 (or 0 if column empty)
            taskItem.sortIndex = getNextSortIndex();
        }


        if (originalTaskOwnerId === dropTargetId || (!originalTaskOwnerId && !dropTargetId)) {
            // TODO: Sorting, update taskItem.sortIndex
        }

        onTaskDrop(taskItem);

        // TODO: returnera något (till drop source)?
    }

    const findCard = useCallback((id) => {
        const card = cards.filter((c) => c.id === id)[0];
        if (!card || !card.id) {
            return {
                card: null,
                index: cards.length,
            };
        }
        return {
            card,
            index: cards.indexOf(card)
        };
    }, [cards]);

    const moveCard = useCallback((id, atIndex) => {
        const { card, index } = findCard(id);

        if (card) {
            //console.debug(`moveCard : index = ${index}, atIndex = ${atIndex}`);

            card.sortIndex = atIndex;

            setCards(update(cards, {
                $splice: [
                    [index, 1],
                    [atIndex, 0, card],
                ],
            }));
        } else {
            // TODO: What? Remove from old list and add to the new one?            
        }
    }, [cards, findCard, setCards]);

    return (
        <div className={isUnassignedTaskColumn ? classes.unassignedColumn : classes.taskColumn} ref={drop}>
            <div className={classnames(classes.columnHeader, isOver ? classes.columnHeaderIsDropOver : '')}>
                {columnName}
                {isUnassignedTaskColumn && <HelpButton textKey={'weavra.help.unassignedTasks'} />}
            </div>
            <div className={classes.taskColumnTaskList}>
                    {cards && cards.map(task => 
                        <TaskCard
                            key={task.id}
                            task={task}
                            id={task.id}
                            sourceList={cards}
                            onTaskClicked={onTaskClicked}
                            findCard={findCard}
                            moveCard={moveCard}
                            permittedToDrag={projectPermissions.canEditChecklistItems}
                        />
                    )}
            </div>
            {(projectPermissions.canAddChecklistItems || (projectPermissions.companyId === company.id && !projectPermissions.isGuest)) &&
                <AddTaskButton onClick={() => onAddTaskClicked(getNextSortIndex(), company.id)} />
            }
        </div>
    );
}

export default TaskColumn;