import React from 'react';
import {
    useListContext,
} from 'react-admin';

import DocumentFolder from './DocumentFolder';


const DocumentFolderList = ({ onFolderEdit, onFolderDelete, onDocumentEdit, onAddDocument, permissions }) => {
    const { ids, data } = useListContext();
    return (
        <div>
            {ids.map(id => 
                <DocumentFolder
                    key={id}
                    record={data[id]}
                    folders={data}
                    canEdit={permissions.canEditDocuments}
                    canAdd={permissions.canAddDocuments}
                    canDelete={permissions.canEditDocuments}
                    onFolderEdit={onFolderEdit}
                    onFolderDelete={onFolderDelete}
                    onDocumentEdit={onDocumentEdit}
                    onAddDocument={onAddDocument}>
                </DocumentFolder>
            )}
        </div>
    );
}

export default DocumentFolderList;