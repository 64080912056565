import React from 'react';
import { useSelector } from "react-redux";
import {
    useQuery,
    useEditController, 
    useFormGroup,
    Title,
    EditContextProvider, 
    SimpleForm, 
    TextInput, 
    DateInput,
    BooleanInput,
    Loading,
    Error,
    required,
    FormGroupContextProvider,
    FormDataConsumer,
    Toolbar,
    SaveButton,
} from 'react-admin';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from 'react-final-form';
import { Card, CardContent, Grid, Typography, makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import GMapDialogButtonInput from '../shared/inputs/GMapDialogButtonInput';
import ProjectMembers from './ProjectDashboard/ProjectMembers';
import HelpButton from '../shared/buttons/HelpButton';

const useStyles = makeStyles(theme => ({
    groupedInput: {
        width: '256px',
    },
    accordionRoot: {
        marginBottom: '12px',
        "& .MuiAccordionSummary-root": {
            "& .MuiAccordionSummary-content": {
                alignItems: 'center',
            },
        },
    },
    accordion: {
        flexDirection: 'column',
        marginBotton: '12px',
    },
}));

const AccordionSectionTitle = props => {
    const { children, name } = props;
    const formGroupState = useFormGroup(name);

    return (
        <Typography color={ formGroupState.invalid && formGroupState.dirty ? 'error' : 'inherit'}>
            {children}
        </Typography>
    )
}

const InputWithHelpText = props => {
    // TODO: Move to shared?
    // TODO: variable grid?
    // TODO: allow one child only?

    const { children, helpTextKey } = props;

    return (
        <Grid container style={{width: '256px'}}>
            <Grid item xs={10}>
                {children}
            </Grid>
            <Grid item xs={2}>
                <HelpButton textKey={helpTextKey} /> 
            </Grid>
        </Grid>
    )
}

const LocationTextInput = props => {
    const { source, label, disabled } = props;
    const classes = useStyles();

    return (
        <div className={ classes.groupedInput }>
            <TextInput source={source} label={label} disabled={disabled} fullWidth />
        </div>
    )
}

const UseFormContainer = ({ children }) => {
    const form = useForm();
    if (typeof children === 'function')
        return children && children({ form });
}

const ProjectToolbar = ({ canEdit, ...props }) => {
    
    if (!canEdit) return null;

    return (
        <Toolbar {...props}>
            <SaveButton label="Spara ändringar" disabled={props.pristine} />
        </Toolbar>
    )
}

const ProjectDetailsEdit = props => {
    
    // TODO: ta emot record (projekt) i props
    const controllerProps = useEditController({ id: props.projectId, ...props }); // TODO: custom onSuccess here?
    const classes = useStyles();
    
    const {
        basePath, // deduced from the location, useful for action buttons
        //defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
        //loaded, // boolean that is false until the record is available
        loading, // boolean that is true on mount, and false once the record was fetched
        record, // record fetched via dataProvider.getOne() based on the id from the location
        //redirect, // the default redirection route. Defaults to 'list'
        resource, // the resource name, deduced from the location. e.g. 'posts'
        save, // the update callback, to be passed to the underlying form as submit handler
        saving, // boolean that becomes true when the dataProvider is called to update the record
        version, // integer used by the refresh feature
    } = controllerProps;

    if (loading) {
        return <Loading />
    }
        
    if (!props.projectId)
        return <Error />
    
    // TODO: Delete project?

    const handleManualLocationChange = (address, form) => {
        if (!address) return;
  
        form.batch(() => {
            if (address.city) {
                form.change('city', address.city);
                //form.resetFieldState('city');
            }
            if (address.street) {
                form.change('streetAddress', address.street);
                //form.resetFieldState('streetAddress');
            }
            if (address.zip) {
                form.change('zip', address.zip);
                //form.resetFieldState('zip');
            }
        });
    }

    const taxationIdValidation = (value) => {
        if (value && value.length > 15)
            return 'Max 15 tecken';
        
        return undefined;
    }

    const ledgerActiveValidation = (value, allValues) => {
        if (value === true) {
            if (!allValues.city || !allValues.streetAddress || !allValues.zip || !allValues.projectTaxationId)
                return 'SkatteId och komplett adress krävs för att aktivera personalliggaren';
        }

        return undefined;        
    }

    const validateTaxId = [taxationIdValidation];
    const validateLedgerActive = [ledgerActiveValidation];

    const ProjectStatusField = ({record}) => {
        if (!record) return null;

        const today = new Date().getTime();
        const start = new Date(record.startDate).getTime();
        const end = record.endDate ? new Date(record.endDate).getTime() : null;

        const active = end
            ? today >= start && today <= end
            : today >= start;

        return (
            <Typography>
                {active ? 'Aktiv' : 'Ej aktiv'}
                <HelpButton textKey={'weavra.help.activeStatus'} />
            </Typography>
        );
    }

    const canEdit = props.projectPermissions.canEditProject;

    return (
        <EditContextProvider value={controllerProps}>
            <SimpleForm 
                basePath={basePath} 
                record={record} 
                redirect={false} 
                resource={resource} 
                save={save} 
                saving={saving} 
                version={version} 
                mutationMode={'pessimistic'}
                toolbar={<ProjectToolbar canEdit={canEdit} />}
            >
                <TextInput source="name" label="Projektnamn" validate={[required()]} disabled={!canEdit} />
                <TextInput multiline source="description" label="Beskrivning" disabled={!canEdit} />
                <TextInput source="orderNumber" label="ProjektID" disabled={!canEdit} />
                <TextInput source="orderer" label="Byggherre" disabled={!canEdit} />

                <ProjectStatusField addLabel label='Projekstatus' disabled={!canEdit} />

                <DateInput source="startDate" label="Byggstart" validate={[required()]} disabled={!canEdit} />
                <DateInput source="endDate" label="Byggavslut" disabled={!canEdit} />
                
                <FormGroupContextProvider name="location">
                    <Accordion className={classes.accordionRoot}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="location-content"
                            id="location-header"
                            >
                            <AccordionSectionTitle name="location">Arbetsplats</AccordionSectionTitle>
                            <HelpButton textKey={'weavra.help.openLocationSettings'} />
                        </AccordionSummary>
                        <AccordionDetails id="location-content" aria-labelledby="location-header" className={classes.accordion}>
                            <LocationTextInput source="city" label="Ort" disabled={!canEdit} />
                            <LocationTextInput source="streetAddress" label="Adress" disabled={!canEdit} />
                            <LocationTextInput source="zip" label="Postnummer" disabled={!canEdit} />
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <UseFormContainer>
                                        {({ form }) => (
                                            <GMapDialogButtonInput
                                                source="coordinates"
                                                city={formData.city}
                                                street={formData.streetAddress}
                                                zip={formData.zip}
                                                onAddressChange={(address) => handleManualLocationChange(address, form)}
                                                canEdit={canEdit}
                                                {...rest} 
                                            />
                                        )}
                                    </UseFormContainer>
                                )}
                            </FormDataConsumer>
                        </AccordionDetails>
                    </Accordion>
                </FormGroupContextProvider>

                <TextInput source="projectTaxationId" label="weavra.project.taxationId" validate={validateTaxId} disabled={!canEdit} />
                <InputWithHelpText helpTextKey={'weavra.help.ledgerInfo'}>
                    <BooleanInput source="personnelLedgerActive" label="weavra.project.activeLedger" validate={validateLedgerActive} options={{ color: "secondary" }} disabled={!canEdit} />
                </InputWithHelpText>

            </SimpleForm>
        </EditContextProvider>
    )

    // TODO: Gör aktiv status grön?

    // TODO: Uppdatera redux state on save success (ifall namn ändras etc?)
    // TODO: Security? Auth? Is user allowed to view/change things?
    // TODO: Switch state: View | Edit ?

    // TODO: Custom toolbar
}


const FullHeightStyle = {
    height: '100%'
}

const ProjectDashboard = () => {

    const projectId = useSelector(state => state.workspace.id);
    const title = useSelector(state => state.workspace.name);
    
    const { loaded, error, data:permissionsData } = useQuery({
        type: 'getOne',
        resource: 'general/projects',
        payload: { id: projectId, getPermissions: true, getMembersPermissions: true }
    });

    if (!loaded) {
        return <Loading />;
    }

    if (error) {
        return <Error />;
    }

    return <Grid container spacing={3}>
        <Grid item container xs={6} direction='column' spacing={3}>
            <Grid item xs>
                <Card variant="outlined">
                    <Title title={title + " : Dashboard"} />
                    <CardContent>
                        <ProjectDetailsEdit projectId={projectId} resource="general/projects" basePath="project" projectPermissions={permissionsData} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid item container xs={6} direction='column'>
            <Grid item xs>
                <Card style={FullHeightStyle} variant="outlined">
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">Projektmedlemmar</Typography>
                        <ProjectMembers projectId={projectId} projectPermissions={permissionsData}></ProjectMembers>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Grid>
}


export default ProjectDashboard;