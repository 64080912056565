import decodeJwt from 'jwt-decode';

const apiHost = process.env.REACT_APP_API_HOST;

const AuthProvider = {

    //TODO: Convert to async functions?
    //TODO: In-memory-JWT + refresh tokens? https://marmelab.com/blog/2020/07/02/manage-your-jwt-react-admin-authentication-in-memory.html
    
    login: ({ username, password }) => {
        const authParams = {
            client_id: 'weavraadmin',
            client_secret: 'megaultrahemligt',
            grant_type: 'password',
            username,
            password,
            scope: 'WeAvra.ApiAPI'
        };

        const body = Object.keys(authParams).map((key) => {
            return encodeURIComponent(key) + "=" + encodeURIComponent(authParams[key])
        }).join('&');

        const request = new Request(`${apiHost}/connect/token`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body
            });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    console.error(response);
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(({ access_token }) => {
                const decodedToken = decodeJwt(access_token);
                localStorage.setItem('token', access_token);
                localStorage.setItem('user.account', username);
                localStorage.setItem('permissions', JSON.stringify(decodedToken.role)); //TODO: handle as array...
            });
    },

    logout: () => {
        localStorage.clear(); //TODO: Handle differently if we actually want to keep something...
        //TODO: Clear redux store too???
        return Promise.resolve();
    },

    checkError: ({ status }) => {
        //TODO: Utilise refresh tokens on session expire? Try get new token, then retry last operation?
        //http://docs.identityserver.io/en/latest/topics/refresh_tokens.html
        if (status === 401 || status === 403) {
            localStorage.clear();
            return Promise.reject({ message: false });
        }
        return Promise.resolve();
    },

    checkAuth: () => {
        return localStorage.getItem('user.account')
            ? Promise.resolve()
            : Promise.reject();
    },

    getPermissions: () => {
        const roles = JSON.parse(localStorage.getItem('permissions'));
        return roles ? Promise.resolve(roles) : Promise.reject();
    }

};

export default AuthProvider;