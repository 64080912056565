import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Popover, IconButton, Typography, makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
    textContainer: {
        whiteSpace: 'pre-line',
    },
    helpText: {
        padding: theme.spacing(2),
    },
}));

const HelpButton = props => {
    const { textKey } = props;
    const translate = useTranslate();                      
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = e => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    }

    const handleClose = e => {
        e.stopPropagation();
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    const text = (
        <div className={classes.textContainer}>
            <Typography className={classes.helpText}>{translate(textKey)}</Typography>
        </div>
    );

    return (
        <>
            <IconButton aria-label="help" onClick={handleClick} color="primary">
                <InfoOutlinedIcon />
            </IconButton>

            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                {text}
            </Popover>
        </>
    )
}

export default HelpButton;
